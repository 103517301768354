import styled, { css } from "styled-components";
import { DropdownList } from "react-widgets";
import { COLORS, SPACING, TYPOGRAPHY } from '../ImportContentStyles';

// Shared column configuration for both tables
export const COLUMN_CONFIG = {
  client: [
    { name: 'Client Name', width: '15%', key: 'clientName' },
    { name: 'Address', width: '25%', key: 'address' },
    { name: 'Location Type', width: '10%', key: 'locationType' },
    { name: 'Location Name', width: '15%', key: 'locationName' },
    { name: 'Contact Name', width: '10%', key: 'contactName' },
    { name: 'Contact Phone', width: '10%', key: 'contactPhone' },
    { name: 'Contact Email', width: '15%', key: 'contactEmail' },
    { name: 'Actions', width: '100px', key: 'actions', hideInFinal: true }
  ],
  location: [
    { name: 'Location Name', width: '20%', key: 'locationName' },
    { name: 'Address', width: '50%', key: 'address' },
    { name: 'Location Type', width: '20%', key: 'locationType' },
    { name: 'Contact Phone', width: '15%', key: 'contactPhone' },
    { name: 'Client', width: '25%', key: 'client', hideInRejected: true }
  ]
};

const borderStyle = `1px solid ${COLORS.border}`;

// Fixed-layout table style for consistent column widths
const tableLayoutStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: auto; /* Remove fixed minimum width to prevent excess space */
`;

const rowLayoutStyle = css`
  display: flex;
  width: 100%;
  align-items: center; /* Align items vertically */
`;

const cellLayoutStyle = css`
  padding: ${SPACING.sm} ${SPACING.sm};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center; /* Center content vertically */
  height: 100%; /* Fill the row height */
  box-sizing: border-box;
`;

export const ListHeaderRow = styled.div`
  ${tableLayoutStyle}
`;

export const ItemRow = styled.div`
  ${rowLayoutStyle}
  border-bottom: ${borderStyle};
  background-color: ${props => props.isAlternate ? COLORS.secondaryLight : COLORS.white};
  transition: background-color 0.15s ease;

  &:hover {
    background-color: ${COLORS.hover};
  }
`;

export const HeaderRow = styled.div`
  ${rowLayoutStyle}
  border-bottom: 2px solid ${COLORS.primary};
  background-color: ${COLORS.secondaryLight};
  font-weight: 600;
  min-height: 48px; /* Set a specific height for the header row */
`;

export const ListBox = styled.div`
  ${tableLayoutStyle}
  /* Use conditional scrolling based on whether it's used inside ListsContainer */
  overflow-y: ${props => props.allowScroll ? 'auto' : 'visible'};
  max-height: ${props => props.allowScroll ? 'calc(60vh - 200px)' : 'none'};
  overflow-x: hidden;
  border: ${borderStyle};
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  margin-bottom: ${SPACING.lg};
  width: 100%;
`;

export const ItemValueContainer = styled.div`
  ${cellLayoutStyle}
  width: ${({width}) => width ? width : 'auto'};
  min-width: ${({width}) => width ? (width.includes('%') ? '0' : width) : 'auto'};
  max-width: ${({width}) => width ? width : 'auto'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  font-size: ${TYPOGRAPHY.body.fontSize};
  line-height: 1.5;
  min-height: 40px; /* Ensure minimum height for all cells */
  text-align: left; /* Ensure left alignment for all content */
  justify-content: flex-start; /* Align flexbox content to the left */
  flex: ${({width}) => width && width.includes('%') ? width.replace('%', '') : 'none'};
`;

export const StyledDropdown = styled(DropdownList)`
  border-radius: 5px;
  width: 100%;
  position: relative;

  .rw-widget-input {
    border-color: ${COLORS.borderDark};
    background-color: ${COLORS.white};
    height: 38px;
    padding: ${SPACING.xs} ${SPACING.sm};
  }

  &.rw-widget-container.rw-state-focus {
    border-color: ${COLORS.primary};
    box-shadow: 0 0 0 3px rgba(68, 92, 233, 0.1);
  }

  .rw-popup-container {
    position: absolute;
    z-index: 9999;
    width: 100%;
    
    .rw-popup {
      border-color: ${COLORS.borderDark};
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
      position: fixed;
      min-width: 200px;
      max-height: 250px;
      overflow-y: auto;
      transform-origin: top;
      animation: dropdownOpen 0.1s ease-out;
    }
  }

  .rw-list {
    will-change: transform;
  }

  .rw-list-option {
    padding: ${SPACING.sm} ${SPACING.md};
    height: 36px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: ${COLORS.hover};
    }
    &.rw-state-selected {
      background-color: ${COLORS.primary};
      color: ${COLORS.white};
    }
  }

  @keyframes dropdownOpen {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const ErrorMessageText = styled.p`
  font-size: ${TYPOGRAPHY.hint.fontSize};
  font-weight: ${({fw}) => fw ? fw : '400'};
  color: ${COLORS.danger};
  margin: ${SPACING.xs} 0 0 0;
`;

export const FieldLabel = styled.label`
  font-size: ${TYPOGRAPHY.small.fontSize};
  font-weight: 500;
  color: ${COLORS.secondaryDark};
  margin-bottom: ${SPACING.xs};
  display: block;
`;