import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'
import {
  Container,
  UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem,
  Tooltip
} from 'reactstrap'
import styled from 'styled-components'

// API & Utils
import {
  getProposals,
  archiveProposal,
  updateProposal,
  getServiceTemplates
} from 'api'
import { fullAddress } from 'utils'
import networkErrorToast from 'utils/networkErrorToast'
import stopEventBubbling from 'utils/stopEventBubbling'
import { trackEvent } from 'api/clientHub'
import { BID_PROPOSAL_MARKED_WON } from 'store/analyticsActions/types/proposalTypes'
import { connectConfirmation } from 'components/confirm'

// Components
import ActionBar from '../../components/ActionBar'
import LabelColumn from '../../components/LabelColumn'
import FilterForm from '../../components/forms/FilterForm'
import Icon from 'components/icons'
import DateWithTooltip from 'components/DateWithTooltip'
import { ActionButtons } from 'components/lib/Button'
import DataScreen from 'components/DataScreen'

// Styled Components
const ProposalsTableContainer = styled.div`
  /* Center specific table headers */
  th:nth-child(1), /* Labels */
  th:nth-child(5), /* Sent */
  th:nth-child(6), /* Signed */
  th:nth-child(9)  /* Options */
  {
    text-align: center !important;
    position: relative;
  }
  
  /* Make all header content display inline */
  th > span,
  th > div {
    display: inline-block;
  }
`;

/**
 * Centers content within a table cell
 */
const CenteredCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

/**
 * UserSignIcon - Shows a user with a pen when client has signed but merchant hasn't
 * 
 * @param {Object} props - Component props
 * @param {string} props.color - Icon color (defaults to white)
 * @returns {JSX.Element} SVG icon representing user signature
 */
const UserSignIcon = ({ color = '#ffffff' }) => (
  <span style={{ display: 'inline-block', textAlign: 'center' }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="24" height="24">
      <path fill={color} d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l293.1 0c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3 8.6-21.5 16.8-29.7l40.3-40.3c-32.1-31-75.7-50.1-123.9-50.1l-91.4 0zm435.5-68.3c-15.6-15.6-40.9-15.6-56.6 0l-29.4 29.4 71 71 29.4-29.4c15.6-15.6 15.6-40.9 0-56.6l-14.4-14.4zM375.9 417c-4.1 4.1-7 9.2-8.4 14.9l-15 60.1c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l60.1-15c5.6-1.4 10.8-4.3 14.9-8.4L576.1 358.7l-71-71L375.9 417z"/>
    </svg>
  </span>
);

/**
 * Renders signature status icon based on proposal signature state
 * 
 * @param {Object} props - Component props
 * @param {Object} props.proposal - Proposal data containing signature information
 * @returns {JSX.Element|null} Signature status indicator with tooltip
 */
const SignatureStatus = ({ proposal }) => {
  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  
  const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1);
  const toggleTooltip2 = () => setTooltipOpen2(!tooltipOpen2);
  const toggleTooltip3 = () => setTooltipOpen3(!tooltipOpen3);
  
  const clientSigned = proposal.signatureData?.clientSignature?.dateSigned;
  const merchantSigned = proposal.signatureData?.merchantSignature?.dateSigned;
  
  const tooltipId = `signature-status-${proposal._id}`;
  
  if (clientSigned && merchantSigned) {
    return (
      <CenteredCell>
        <span id={`${tooltipId}-both`}>
          <Icon icon="IoIosCheckmark" color="#09f415" size={40} />
        </span>
        <Tooltip placement="top" isOpen={tooltipOpen1} target={`${tooltipId}-both`} toggle={toggleTooltip1}>
          Fully signed
        </Tooltip>
      </CenteredCell>
    );
  } 
  
  if (clientSigned) {
    return (
      <CenteredCell>
        <span id={`${tooltipId}-client`}>
          <UserSignIcon color="#ffffff" />
        </span>
        <Tooltip placement="top" isOpen={tooltipOpen2} target={`${tooltipId}-client`} toggle={toggleTooltip2}>
          Client has signed - your signature required
        </Tooltip>
      </CenteredCell>
    );
  } 
  
  if (proposal.sent) {
    return (
      <CenteredCell>
        <span id={`${tooltipId}-none`}>
          <Icon icon="IoIosClose" color="#ff4242" size={40} />
        </span>
        <Tooltip placement="top" isOpen={tooltipOpen3} target={`${tooltipId}-none`} toggle={toggleTooltip3}>
          Awaiting client signature
        </Tooltip>
      </CenteredCell>
    );
  }
  
  return null;
};

/**
 * Options dropdown menu for proposal actions
 * 
 * @param {Object} props - Component props
 * @param {Object} props.proposal - Proposal data
 * @param {Function} props.onMarkAsWon - Function to mark proposal as won
 * @param {Function} props.onEdit - Function to edit proposal
 * @param {Function} props.onArchive - Function to archive proposal
 * @returns {JSX.Element} Dropdown menu with proposal actions
 */
const ProposalOptionsMenu = ({ proposal, onMarkAsWon, onEdit, onArchive }) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" onClick={stopEventBubbling}>
        <Icon icon="IoIosMore" color="white" />
      </DropdownToggle>
      <DropdownMenu right>
        {!proposal.won && (
          <DropdownItem
            onClick={e => {
              stopEventBubbling(e);
              onMarkAsWon(proposal._id);
            }}
          >
            Mark as won
          </DropdownItem>
        )}
        <DropdownItem
          onClick={e => {
            stopEventBubbling(e);
            onEdit(proposal);
          }}
        >
          Edit
        </DropdownItem>
        <DropdownItem
          className="text-danger"
          onClick={e => {
            stopEventBubbling(e);
            onArchive(proposal._id);
          }}
        >
          Archive
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

/**
 * ProposalsList Component
 * 
 * Displays a list of proposals with filtering, sorting and various actions.
 * Provides capabilities to:
 * - View proposal details
 * - Edit, archive, or mark proposals as won
 * - Filter proposals by various criteria
 * - Sort proposals by different fields
 * 
 * @param {Object} props - Component props from Redux and parent components
 * @returns {JSX.Element} Complete proposals list interface
 */
const ProposalsList = (props) => {
  const { services, history, confirm, getServiceTemplates } = props;
  const [tabSection, setTabSection] = useState(0);
  const [filterData, setFilterData] = useState(null);
  const dataScreen = useRef(null);

  /**
   * Load service templates on component mount
   */
  useEffect(() => {
    const loadServices = async () => {
      try {
        await getServiceTemplates();
      } catch (error) {
        console.error('Error loading service templates:', error);
        toast.error('Failed to load service templates');
      }
    };

    loadServices();
  }, [getServiceTemplates]);

  /**
   * Extracts service information from a proposal's charge sections
   * 
   * @param {Object} proposal - Proposal data containing elements with charge sections
   * @returns {Array} Array of services found in the proposal
   */
  const extractProposalServices = useCallback((proposal) => {
    if (!proposal?.data?.elements) return [];
    
    // Extract charge sections from proposal elements
    const charges = _.flatten(
      proposal.data.elements.map(el => {
        if (el.type === 'charges') {
          return el.data && el.data.chargeSections;
        }
        return false;
      })
    ).filter(charge => !!charge);

    // Find matching services from the service templates
    return charges?.map(charge => 
      _.find(services, { name: charge.name })
    ).filter(Boolean);
  }, [services]);

  /**
   * Navigation handler to create a new proposal
   */
  const createProposal = useCallback(() => {
    history.push('/dashboard/proposal-setup');
  }, [history]);

  /**
   * Navigation handler to edit an existing proposal
   * 
   * @param {Object} proposal - Proposal to edit
   */
  const editProposal = useCallback((proposal) => {
    history.push(`/dashboard/edit-proposal/${proposal._id}`);
  }, [history]);

  /**
   * Action handler to mark a proposal as won
   * 
   * @param {string} id - Proposal ID to mark as won
   */
  const markProposalAsWon = useCallback(async (id) => {
    try {
      const result = await props.updateProposal({
        _id: id,
        won: true
      });

      if (result.error) {
        return networkErrorToast(result);
      }
      
      toast.success('Proposal marked as won.');
      trackEvent({ typeEvent: BID_PROPOSAL_MARKED_WON, params: { ID: id } });
      
      if (dataScreen.current) {
        dataScreen.current.forceFetch();
      }
    } catch (error) {
      console.error('Error marking proposal as won:', error);
      toast.error('Failed to mark proposal as won');
    }
  }, [props, dataScreen]);

  /**
   * Displays confirmation dialog before archiving a proposal
   * 
   * @param {string} id - Proposal ID to archive
   */
  const handleArchiveProposal = useCallback((id) => {
    confirm(
      'Confirm Archive',
      'Are you sure you want to archive this proposal?',
      () => archiveProposalAction(id)
    );
  }, [confirm]);

  /**
   * Performs the actual archive operation after confirmation
   * 
   * @param {string} id - Proposal ID to archive
   */
  const archiveProposalAction = useCallback(async (id) => {
    try {
      const result = await props.archiveProposal(id);

      if (result.error) {
        return networkErrorToast(result);
      }
      
      toast.success('Proposal was archived.');
      
      if (dataScreen.current) {
        dataScreen.current.forceFetch();
      }
    } catch (error) {
      console.error('Error archiving proposal:', error);
      toast.error('Failed to archive proposal');
    }
  }, [props, dataScreen]);

  /**
   * Navigation handler to view archived proposals
   */
  const showArchivedProposals = useCallback(() => {
    history.push('/dashboard/proposals/archived/');
  }, [history]);

  /**
   * Applies filters received from the FilterForm component
   * 
   * @param {Object} newFilterData - Filter criteria to apply
   */
  const applyFilters = useCallback((newFilterData) => {
    setFilterData(newFilterData);
  }, []);

  /**
   * Table column configuration for the proposals list
   * Defines the structure, display and behavior of each column
   */
  const tableColumns = useMemo(() => {
    // Column for sent status
    const sentColumn = {
      header: 'Sent',
      width: '11%',
      render: item => {
        return item.sent ? (
          <CenteredCell>
            <Icon icon="IoIosCheckmark" color="#09f415" size={40} />
          </CenteredCell>
        ) : null;
      },
      sortField: 'sent',
      sortable: true
    };

    // Column for signature status
    const signedColumn = {
      header: 'Signed',
      width: '15%',
      render: item => <SignatureStatus proposal={item} />,
      sortable: false
    };

    // Column for viewed date or signed date, depending on tab
    const viewedOrSignedDateColumn = tabSection === 0
      ? {
          // Show when the proposal was viewed by client
          header: 'Viewed',
          width: '14%',
          render: item => item.viewed && (
            <DateWithTooltip id={`${item?._id}-viewed`} date={item?.viewed} />
          ),
          sortField: 'viewed',
          sortable: true
        }
      : {
          // Show when the proposal was signed
          header: 'Date Signed',
          width: '14%',
          render: item => {
            // Prioritize client signature date, fall back to merchant signature date
            const signatureDate = 
              item.signatureData?.clientSignature?.dateSigned || 
              item.signatureData?.merchantSignature?.dateSigned;
              
            return signatureDate && (
              <DateWithTooltip id={`${item?._id}-signed`} date={signatureDate} />
            );
          },
          sortField: 'signatureData.clientSignature.dateSigned',
          sortable: true
        };

    // Return the full set of columns
    return [
      {
        header: 'Labels',
        width: '5%',
        render: item => (
          <CenteredCell>
            <LabelColumn
              id={item._id}
              services={extractProposalServices(item)}
              labels={item.labels}
            />
          </CenteredCell>
        )
      },
      {
        header: 'Name',
        width: '8%',
        render: item => `${item.code}${item.name ? ' - ' + item.name : ''}`,
        sortable: true,
        sortField: 'code'
      },
      {
        header: 'Client',
        width: '15%',
        accessor: 'client.name',
        sortable: true
      },
      {
        header: 'Location',
        width: '20%',
        render: item => 
          item.location?.address && fullAddress(item.location.address),
        sortField: 'location.address.formattedAddress',
        sortable: true
      },
      sentColumn,
      signedColumn,
      viewedOrSignedDateColumn,
      {
        header: 'Created',
        width: '14%',
        render: item => (
          <DateWithTooltip id={`${item._id}-created`} date={item.createdAt} />
        ),
        sortField: 'createdAt',
        sortable: true
      },
      {
        header: 'Options',
        width: '8%',
        render: item => (
          <CenteredCell>
            <ProposalOptionsMenu 
              proposal={item} 
              onMarkAsWon={markProposalAsWon}
              onEdit={editProposal}
              onArchive={handleArchiveProposal}
            />
          </CenteredCell>
        )
      }
    ];
  }, [
    tabSection, 
    extractProposalServices, 
    markProposalAsWon, 
    editProposal, 
    handleArchiveProposal
  ]);

  return (
    <Container fluid style={{ marginTop: 20 }}>
      <ActionBar>
        <div></div>
        <div>
          <FilterForm
            applyFilters={applyFilters}
            serviceFilters
            locationFilters
          />
          <ActionButtons
            buttons={[
              {
                title: 'Create Proposal',
                onClick: createProposal
              }
            ]}
            options={[
              {
                title: 'See archived proposals',
                onClick: showArchivedProposals
              }
            ]}
          />
        </div>
      </ActionBar>
      <ProposalsTableContainer>
        <DataScreen
          ref={dataScreen}
          entity="Proposal"
          url="/api/proposals/find"
          onRowClick={editProposal}
          columns={tableColumns}
          filterData={filterData}
        />
      </ProposalsTableContainer>
    </Container>
  );
};

const mapStateToProps = state => ({
  proposals: state.proposals.proposals,
  loading: state.proposals.loading,
  profile: state.user.profile,
  services: state.library.services
});

const mapDispatchToProps = {
  getProposals,
  archiveProposal,
  updateProposal,
  getServiceTemplates
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(connectConfirmation(ProposalsList));
