import React, { forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'

import Field from '../field'
import { PrimaryButton, Question } from '../ui'
import { InputState } from '../../../../../../hooks'

export const Part = styled.div`
  width: 100%;

  input,
  label,
  select {
    display: inline-flex;
    font-size: 1.25rem;
    max-width: 50%;
    text-align: left;
  }

  input,
  select {
    margin-left: 1rem;
  }

  input {
    width: 64px;
  }

  @media screen and (min-width: 32rem) {
    input {
      width: 128px;
    }
  }
`

export const CommercialFrequencyStage = forwardRef(({ current, fields, id, onClick }, ref) => {
  let disabled = false
  const frequency = current || {}

  const onNext = event => onClick(event, frequency)

  // Expose getValue method to parent through ref
  useImperativeHandle(ref, () => ({
    getValue: () => frequency
  }))

  const inputs = fields.map(
    ({ label, name, options, pattern, required, type }, index) => {
      let defaultValue = 'week'
      if (name === 'rate') {
        defaultValue = ''
      }

      const [value, setValue] = InputState(frequency[name] || defaultValue)
      frequency[name] = value

      const isValid = value.trim().length > 0
      if (!isValid) {
        disabled = true
      }

      return (
        <Part key={name}>
          <Field
            id={name}
            label={label}
            labelStyle={{ maxWidth: '100%' }}
            name={`frequency-${name}`}
            onChange={setValue}
            options={options}
            pattern={pattern}
            placeholder={name === 'unit' ? `Select time` : ``}
            required={required}
            style={{}}
            type={type}
            value={value}
          />
        </Part>
      )
    }
  )

  return (
    <Question>
      {inputs}

      <PrimaryButton disabled={disabled} onClick={onNext} type={'button'}>
        Next
      </PrimaryButton>
    </Question>
  )
})

export default CommercialFrequencyStage
