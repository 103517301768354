import { ItemRow, ItemValueContainer, COLUMN_CONFIG } from './ListStyles';
import { COLORS } from '../ImportContentStyles';

export const ClientImportItem = ({ item, isAlternate }) => {
  return (
    <ItemRow isAlternate={isAlternate}>
      <ItemValueContainer width={COLUMN_CONFIG.client[0].width} style={{ textAlign: 'left' }}>
        {item.clientName}
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.client[1].width} style={{ textAlign: 'left' }}>
        {item.address?.formattedAddress}
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.client[2].width} style={{ textAlign: 'left' }}>
        {item.locationType}
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.client[3].width} style={{ textAlign: 'left' }}>
        {item.locationName || item.clientName}
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.client[4].width} style={{ textAlign: 'left' }}>
        {item.contact?.name || '-'}
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.client[5].width} style={{ textAlign: 'left' }}>
        {item.contact?.phone || '-'}
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.client[6].width} style={{ textAlign: 'left' }}>
        {item.contact?.email || '-'}
      </ItemValueContainer>

      {/* Skip the Actions column in the final list if it has hideInFinal */}
      {!COLUMN_CONFIG.client[7].hideInFinal && (
        <ItemValueContainer width={COLUMN_CONFIG.client[7].width} style={{ textAlign: 'left' }} />
      )}
    </ItemRow>
  );
}