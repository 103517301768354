import React from 'react'
import { ProgressBar, ProgressItem, ProgressItemContainer, ProgressLabel } from './ui'

// Helper function to get human-readable labels for each stage
const getStageLabel = (stage) => {
  const labels = {
    place: 'Location',
    service: 'Service Type',
    'commercial-location': 'Business Location',
    'commercial-employees': 'Employees',
    'commercial-floor': 'Floor Type',
    'commercial-frequency': 'Frequency',
    'commercial-expectations': 'Expectations',
    'commercial-recurring': 'Recurring',
    'commercial-review': 'Review',
    'residential-location': 'Home Location',
    'residential-rooms': 'Rooms',
    'residential-addons': 'Add-ons',
    'residential-recurring': 'Recurring',
    'residential-review': 'Review'
  }
  return labels[stage] || stage.charAt(0).toUpperCase() + stage.slice(1)
}

export const Progress = ({
  completed,
  currentIndex,
  onStageChange,
  questions = [],
  bid,
  currentStageRef,
  handleSaveAndNavigate
}) => {
  // If questions array is empty but we have a service, create a default set of stages
  const effectiveQuestions = questions && questions.length > 0 ? questions : 
    (bid?.service === 'commercial' ? 
      ['commercial-location', 'commercial-floor', 'commercial-recurring', 'commercial-frequency', 'commercial-expectations', 'commercial-employees', 'commercial-review'] : 
      (bid?.service === 'residential' ? 
        ['residential-location', 'residential-rooms', 'residential-addons', 'residential-recurring', 'residential-review'] : 
        []));
  
  // Filter out stages that shouldn't be shown
  const filteredQuestions = effectiveQuestions.filter(q => {
    if (q === 'contact') return false
    if (q === 'frequency' && bid?.recurring === 'no') return false
    return true
  })

  // Calculate the adjusted index based on the filtered questions
  const getCurrentFilteredIndex = () => {
    // If no questions or currentIndex is invalid, use the last question
    if (filteredQuestions.length === 0 || currentIndex < 0 || currentIndex >= effectiveQuestions.length) {
      return filteredQuestions.length - 1;
    }
    
    const currentStage = effectiveQuestions[currentIndex]
    
    // Handle the case where we're in the review stage
    if ((currentStage === 'review' || currentStage === 'commercial-review' || currentStage === 'residential-review') || 
        (!currentStage && (bid?.service === 'commercial' || bid?.service === 'residential'))) {
      // If we have a service, try to find the service-specific review stage
      if (bid?.service) {
        const serviceSpecificReview = `${bid.service}-review`
        
        // First try to find the service-specific review stage
        const serviceReviewIndex = filteredQuestions.findIndex(q => q === serviceSpecificReview)
        if (serviceReviewIndex !== -1) {
          return serviceReviewIndex
        }
      }
      
      // If service-specific review not found or no service, use the last stage in the flow
      return filteredQuestions.length - 1
    }
    
    // For normal stages, find the index in filtered questions
    const filteredIndex = filteredQuestions.findIndex(q => q === currentStage)
    return filteredIndex !== -1 ? filteredIndex : filteredQuestions.length - 1
  }

  const adjustedCurrentIndex = getCurrentFilteredIndex()

  // Get the previous stage in the current service flow
  const getPreviousStage = (currentStage) => {
    // If we're in a service-specific flow (commercial or residential)
    if (currentStage.includes('-')) {
      const servicePrefix = currentStage.split('-')[0]
      // Get all stages for the current service type
      const serviceStages = filteredQuestions.filter(q => q.startsWith(servicePrefix))
      const currentServiceIndex = serviceStages.indexOf(currentStage)
      
      // Always stay within the current service flow
      if (currentServiceIndex > 0) {
        return serviceStages[currentServiceIndex - 1]
      } else {
        // If at the first stage of a service flow, go to service selection
        return 'service'
      }
    }
    
    // If we're in the main flow (place or service selection)
    const currentIndex = filteredQuestions.indexOf(currentStage)
    if (currentStage === 'service') {
      return 'place'
    }
    return currentIndex > 0 ? filteredQuestions[currentIndex - 1] : currentStage
  }

  // Handle progress bar navigation
  const handleProgressNavigation = (targetQuestion) => {
    const currentStage = effectiveQuestions[currentIndex]
    
    // If clicking on the current stage, do nothing
    if (targetQuestion === currentStage) {
      return
    }
    
    // If there's a current stage with unsaved changes, save them first
    if (currentStageRef?.current?.getValue) {
      const currentValue = currentStageRef.current.getValue()
      handleSaveAndNavigate(currentStage, currentValue, targetQuestion)
    } else {
      onStageChange(targetQuestion)
    }
  }

  return (
    <ProgressBar>
      {filteredQuestions.map((question, index) => {
        // Calculate if this stage should be clickable
        const isClickable = completed || index <= adjustedCurrentIndex
        // Don't allow clicking frequency if recurring is no
        const canClick = isClickable && !(question === 'frequency' && bid?.recurring === 'no')

        return (
          <ProgressItemContainer 
            key={question} 
            complete={index < adjustedCurrentIndex}
            current={index === adjustedCurrentIndex}
            onClick={() => canClick && handleProgressNavigation(question)}
            style={{ cursor: canClick ? 'pointer' : 'default' }}
          >
            <ProgressItem
              complete={index <= adjustedCurrentIndex}
              clickable={canClick}
              onClick={(e) => {
                e.stopPropagation(); // Prevent double navigation
                canClick && handleProgressNavigation(question);
              }}
            />
            <ProgressLabel 
              complete={index <= adjustedCurrentIndex}
              style={{ cursor: canClick ? 'pointer' : 'default' }}
            >
              {getStageLabel(question)}
            </ProgressLabel>
          </ProgressItemContainer>
        )
      })}
    </ProgressBar>
  )
}

export default Progress
