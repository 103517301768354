// Updated mapping: use same tokens for storage and display for customer and company.
const mapping = [
  { storage: '{{customer.name}}', display: '{{customer.name}}' },
  { storage: '{{customer.address}}', display: '{{customer.address}}' },
  { storage: '{{location.name}}', display: '{{location.name}}' },
  { storage: '{{location.address}}', display: '{{location.address}}' },
  { storage: '{{company.name}}', display: '{{company.name}}' }
];

/**
 * Returns content unchanged.
 */
export function transformForDisplay(content) {
  return content;
}

/**
 * Returns content unchanged.
 */
export function transformForStorage(content) {
  return content;
}

/**
 * Replaces tokens with proposal values.
 */
export function applyPlaceholders(content, proposal) {
  const merchantName = proposal.merchant?.name || proposal.signatureData?.merchantName || 'YOUR COMPANY NAME';
  const clientName = proposal.client?.name || proposal.signatureData?.clientName || 'CLIENT NAME';
  const clientAddress = proposal.client?.address?.fullAddress || proposal.signatureData?.clientAddress || 'CLIENT ADDRESS';
  const locationName = proposal.location?.name || proposal.signatureData?.locationName || 'SERVICE LOCATION';
  const locationAddress = proposal.location?.address?.fullAddress || proposal.signatureData?.locationAddress || 'SERVICE LOCATION ADDRESS';

  const values = {
    '{{client.name}}': clientName,
    '{{client.address}}': clientAddress,
    '{{location.name}}': locationName,
    '{{location.address}}': locationAddress,
    '{{company.name}}': merchantName,
  };

  Object.entries(values).forEach(([placeholder, actualValue]) => {
    content = content.replace(new RegExp(placeholder, 'g'), actualValue.trim());
  });
  return content;
}
