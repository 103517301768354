import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ListBox, ItemValueContainer, HeaderRow, COLUMN_CONFIG } from './ListStyles';
import { UploadText, SectionTitle, COLORS, SPACING, ImportSubmitButton } from '../ImportContentStyles';
import { ClientRejectedItem } from './ClientRejectedItem';
import { LocationRejectedItem } from './LocationRejectedItem';
import styled from 'styled-components';

const RejectedListContainer = styled.div`
  overflow-x: visible;
  margin-bottom: ${SPACING.lg};
  width: 100%;
  border: 1px solid ${COLORS.danger}20;
  border-radius: 8px;
  padding: ${SPACING.sm};
  background-color: ${COLORS.danger}05;
`;

const RejectedHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${SPACING.sm};
  padding: 0 ${SPACING.sm};
`;

const RejectedTitle = styled.h3`
  color: ${COLORS.danger};
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  
  svg {
    margin-right: ${SPACING.xs};
  }
`;

const RejectedDescription = styled.p`
  color: ${COLORS.secondary};
  font-size: 14px;
  margin: 0 0 ${SPACING.sm} 0;
  padding: 0 ${SPACING.sm};
`;

const ActionColumnHeader = styled(ItemValueContainer)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 15% !important;
`;

const SaveAllButton = styled(Button)`
  background-color: ${props => props.saving ? COLORS.secondary : COLORS.primary};
  border-color: ${props => props.saving ? COLORS.secondary : COLORS.primary};
  transition: all 0.2s;
  width: 100%;
  
  &:hover:not(:disabled) {
    background-color: ${props => props.saving ? COLORS.secondary : COLORS.primaryDark};
    border-color: ${props => props.saving ? COLORS.secondary : COLORS.primaryDark};
  }
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
    background-color: ${COLORS.secondary}40;
    border-color: ${COLORS.secondary}40;
  }
`;

// Add this styled component for consistent text padding across the table
const TextContent = styled.div`
  padding: 0 10px;
  box-sizing: border-box;
`;

const StatusText = styled.span`
  color: ${props => props.error ? COLORS.danger : COLORS.secondary};
  font-size: 10px;
  text-align: center;
  margin-top: 4px;
`;

export const RejectedItemList = ({ importType, data, updateItem, recordPendingEdit, saveAllPendingEdits, pendingEdits, onCompletenessChange }) => {
  const [busy, setBusy] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasCompletePendingEdits, setHasCompletePendingEdits] = useState(false);
  const columns = COLUMN_CONFIG[importType];

  // Check if all items have the required fields filled
  useEffect(() => {
    const checkCompleteness = () => {
      if (!recordPendingEdit || !pendingEdits) {
        setHasCompletePendingEdits(false);
        return;
      }
      
      let allItemsComplete = true;
      
      // For each rejected item, check if it's either already valid or has been fixed with pending edits
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const pendingEdit = pendingEdits[i] || {};
        
        // Check for location import
        if (importType === 'location') {
          const hasValidName = item.locationName && !item.missingLocationName ? true : !!pendingEdit.locationName;
          const hasValidAddress = item.address && !item.invalidAddress && !item.missingAddress ? true : !!pendingEdit.address;
          const hasValidType = item.locationType && !item.invalidLocationType && !item.missingLocationType ? true : !!pendingEdit.locationType;
          
          if (!hasValidName || !hasValidAddress || !hasValidType) {
            allItemsComplete = false;
            break;
          }
        } 
        // Check for client import
        else {
          const hasValidName = item.clientName && !item.missingClientName ? true : !!pendingEdit.clientName;
          const hasValidAddress = item.address && !item.invalidAddress && !item.missingAddress ? true : !!pendingEdit.address;
          const hasValidType = item.locationType && !item.invalidLocationType && !item.missingLocationType ? true : !!pendingEdit.locationType;
          
          if (!hasValidName || !hasValidAddress || !hasValidType) {
            allItemsComplete = false;
            break;
          }
        }
      }
      
      // Update state based on our check
      setHasCompletePendingEdits(allItemsComplete);
      
      // Notify parent component about completeness status
      if (typeof onCompletenessChange === 'function') {
        onCompletenessChange(allItemsComplete);
      }
    };
    
    // Check completeness whenever data or pendingEdits changes
    checkCompleteness();
  }, [data, importType, recordPendingEdit, pendingEdits, onCompletenessChange]);

  useEffect(() => {
    setBusy(true);
    setTimeout(() => setBusy(false), 100); // Short delay to ensure UI updates properly
  }, [data]);

  const handleSaveAll = async () => {
    if (!saveAllPendingEdits || isSaving || !hasCompletePendingEdits) return;
    
    setIsSaving(true);
    try {
      await saveAllPendingEdits();
    } catch (error) {
      console.error('Error saving all items:', error);
    } finally {
      setIsSaving(false);
    }
  };

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <RejectedListContainer>
      <RejectedHeader>
        <RejectedTitle>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
          </svg>
          Items Requiring Attention ({data.length})
        </RejectedTitle>
      </RejectedHeader>
      
      <RejectedDescription>
        The following items need corrections before they can be imported. Fields highlighted in red need to be fixed.
      </RejectedDescription>

      <HeaderRow>
        {columns.map((col, index) => (
          !col.hideInFinal && !col.hideInRejected && (
            <ItemValueContainer key={index} width={col.width} style={{ textAlign: 'left' }}>
              <TextContent>{col.name}</TextContent>
            </ItemValueContainer>
          )
        ))}
        <ActionColumnHeader width="15%" style={{ textAlign: 'left' }}>
          {saveAllPendingEdits && (
            <SaveAllButton 
              onClick={handleSaveAll}
              disabled={isSaving || !hasCompletePendingEdits}
              saving={isSaving}
              size="sm"
            >
              {isSaving ? 'Saving...' : 'Save All'}
            </SaveAllButton>
          )}
        </ActionColumnHeader>
      </HeaderRow>
      
      <ListBox allowScroll={false} style={{ width: '100%', overflowX: 'hidden' }}>
        {!busy && data.map((item, index) => (
          importType === 'location' ? (
            <LocationRejectedItem 
              key={`loc-${index}`} 
              item={item} 
              updateItem={updateItem} 
              index={index}
              isAlternate={index % 2 !== 0}
              recordPendingEdit={recordPendingEdit}
            />
          ) : (
            <ClientRejectedItem 
              key={`client-${index}`} 
              item={item} 
              updateItem={updateItem} 
              index={index}
              isAlternate={index % 2 !== 0}
              recordPendingEdit={recordPendingEdit}
            />
          )
        ))}
      </ListBox>
    </RejectedListContainer>
  );
}