import React, { Component } from 'react'
import { Button } from 'reactstrap'

import AreasInspector from './inspectors/Areas'
import ChargesInspector from './inspectors/Charges'
import DividerInspector from './inspectors/Divider'
import LetterheadInspector from './inspectors/Letterhead'
import HeadingInspector from './inspectors/Heading'
import ImageInspector from './inspectors/Image'
import SpacerInspector from './inspectors/Spacer'
import SignaturesInspector from './inspectors/Signatures'
import ScopeOfWorkInspector from './inspectors/ScopeOfWork'
import ServiceFrequencyInspector from './inspectors/ServiceFrequency'
import RichTextInspector from './inspectors/RichText'
import DateRangeInspector from './inspectors/DateRange'
import PageBreakInspector from './inspectors/PageBreak'

import Elements from '../Elements'

class ElementInspector extends Component {
  constructor(props) {
    super()
  }

  reportChange = (data) => {
    const { element, onChange } = this.props

    let newElement = {
      ...element,
      data: data
    }
    onChange(newElement)
  }

  render() {
    const { element, proposal, onCreateCustomElement, onClose } = this.props
    if (!element) {
      return null
    }

    const origin = Elements.find(o => element.type === o.type)

    let inspector
    switch (element.type) {
      case 'charges':
        inspector = (
          <ChargesInspector
            proposal={proposal}
            element={element}
            onChange={this.reportChange}
          />
        )
        break
      case 'letterhead':
        inspector = (
          <LetterheadInspector element={element} onChange={this.reportChange} />
        )
        break
      case 'heading':
        inspector = (
          <HeadingInspector element={element} onChange={this.reportChange} />
        )
        break
      case 'signatures':
        inspector = (
          <SignaturesInspector element={element} onChange={this.reportChange} />
        )
        break
      case 'scope_of_work':
        inspector = (
          <ScopeOfWorkInspector
            ref={e => (this.scopeInspector = e)}
            element={element}
            proposal={proposal}
            onChange={this.reportChange}
          />
        )
        break
      case 'rich_text':
        inspector = (
          <RichTextInspector
            element={element}
            onChange={this.reportChange}
            walkthroughNotes={proposal && proposal.job && proposal.job.notes}
          />
        )
        break
      case 'areas':
        inspector = (
          <AreasInspector element={element} onChange={this.reportChange} />
        )
        break
      case 'divider':
        inspector = (
          <DividerInspector element={element} onChange={this.reportChange} />
        )
        break
      case 'spacer':
        inspector = (
          <SpacerInspector element={element} onChange={this.reportChange} />
        )
        break
      case 'image':
        inspector = (
          <ImageInspector element={element} onChange={this.reportChange} />
        )
        break
      case 'service_frequency':
        inspector = (
          <ServiceFrequencyInspector
            element={element}
            onChange={this.reportChange}
          />
        )
        break
      case 'service_dates':
        inspector = (
          <DateRangeInspector
            element={element}
            onChange={this.reportChange}
          />
        )
        break
      case 'page_break':
        inspector = (
          <PageBreakInspector
            element={element}
            onChange={this.reportChange}
          />
        )
        break
      default:
        inspector = null
        break
    }

    return (
      <div>
        <h5 style={{ marginTop: 0, marginBottom: 16 }}>
          {origin && origin.label}
        </h5>
        {inspector}

        <div style={{ display: 'flex', paddingTop: 64, marginBottom: 16 }}>
          <Button
            size="sm"
            color="primary"
            style={{ marginRight: 8 }}
            onClick={onClose}
          >
            Close
          </Button>
          {origin && origin.allowCustomElements && (
            <Button
              size="sm"
              onClick={async () => {
                if (this.scopeInspector) {
                  onCreateCustomElement(this.scopeInspector.fetchCustomElements)
                } else {
                  onCreateCustomElement()
                }
              }}
            >
              Save Custom Element
            </Button>
          )}
        </div>
      </div>
    )
  }
}

export default ElementInspector
