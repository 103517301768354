import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  UploadTitle, 
  UploadText, 
  ImportSubmitButton, 
  SectionTitle,
  ModalFooter,
  COLORS,
  SPACING
} from './ImportContentStyles';
import { ImportFinalList, RejectedItemList } from './FinalLists';
import { getClients, createClient, createLocation } from 'api'
import { axiosClient } from 'store';
import { toast } from 'react-toastify';
import { Alert } from 'reactstrap';
import styled from 'styled-components';

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${COLORS.secondaryLight};
  border-radius: 4px;
  margin-top: ${SPACING.xs};
  overflow: hidden;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
`;

const ProgressFill = styled.div`
  height: 100%;
  background-color: ${COLORS.primary};
  width: ${props => props.percentage}%;
  transition: width 0.3s ease-in-out;
`;

const ContentWrapper = styled.div`
  padding-bottom: ${props => props.hideFooter ? '0' : '60px'}; /* Space for fixed footer */
  position: relative;
  min-height: 400px;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden; /* Prevent wrapper from scrolling */
`;

const ListsContainer = styled.div`
  max-height: calc(80vh - 150px); /* Increased the height to utilize more modal space */
  overflow-y: auto;
  padding-right: ${SPACING.sm};
  width: 100%;
  min-width: 1050px; /* Ensure minimum width for table content */
  overflow-x: auto;
  
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: ${COLORS.secondaryLight};
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${COLORS.borderDark};
    border-radius: 4px;
  }
`;

const ModalFooterFixed = styled(ModalFooter)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 12px 20px;
  margin-top: 0;
  background-color: ${COLORS.secondaryLight};
  border-top: 1px solid ${COLORS.border};
  box-shadow: 0 -1px 3px rgba(0,0,0,0.05);
`;

export const FinalizeImport = ({data, importType, completeImport, hideFooter, updateClientAssignment, setImportFunction}) => {
  const [approvedData, setApprovedData] = useState([])
  const [rejectedData, setRejectedData] = useState([])
  const [pendingEdits, setPendingEdits] = useState({}) // Store unsaved edits
  const [submitting, setSubmitting] = useState(false)
  const [progress, setProgress] = useState({ current: 0, total: 0 })
  const [error, setError] = useState(null)
  const [importResults, setImportResults] = useState({ success: 0, failed: 0 })
  const [isLoading, setIsLoading] = useState(false)
  const [allRejectedItemsComplete, setAllRejectedItemsComplete] = useState(false)
  const dispatch = useDispatch()
  const { clients } = useSelector(state => state.clients)

  // Define _onSubmit with useCallback to properly handle dependencies
  const _onSubmit = useCallback(async () => {
    if (submitting) return; // Prevent multiple submissions
    
    setSubmitting(true)
    setProgress({ current: 0, total: approvedData.length })
    setError(null)
    setImportResults({ success: 0, failed: 0 })
    
    try {
      const successItems = [];
      const failedItems = [];
      
      for (let i = 0; i < approvedData.length; i++) {
        const item = approvedData[i]
        setProgress({ current: i + 1, total: approvedData.length })
        
        try {
          if (importType === 'client') {
            // Create client
            const clientRes = await dispatch(createClient({
              name: item.clientName,
              address: item.address,
              contact: {
                name: item.contact?.name,
                phone: item.contact?.phone,
                email: item.contact?.email
              }
            }))
            
            if (!clientRes?.payload?.data?._id) {
              throw new Error(`Failed to create client ${item.clientName}`)
            }
            
            // Create their first location
            const locationRes = await dispatch(createLocation({
              client: clientRes.payload.data._id,
              name: item.locationName || item.clientName,
              address: item.address,
              type: item.locationType,
              contact: {
                name: item.contact?.name,
                phone: item.contact?.phone,
                email: item.contact?.email
              }
            }))
            
            if (!locationRes?.payload?.data?._id) {
              throw new Error(`Failed to create location for client ${item.clientName}`)
            }
            
            successItems.push(item)
          } else if (importType === 'location') {
            // Create location
            if (!item.client) {
              throw new Error('No client selected for location')
            }
            
            const locationRes = await dispatch(createLocation({
              client: item.client,
              name: item.locationName,
              address: item.address,
              type: item.locationType,
              contact: {
                phone: item.contact?.phone
              }
            }))
            
            if (!locationRes?.payload?.data?._id) {
              throw new Error(`Failed to create location ${item.locationName}`)
            }
            
            successItems.push(item)
          }
        } catch (e) {
          console.error('Import error:', e)
          failedItems.push({...item, error: e.message || 'Unknown error'})
        }
      }
      
      setImportResults({ 
        success: successItems.length, 
        failed: failedItems.length 
      })
      
      if (failedItems.length > 0) {
        setError(`Failed to import ${failedItems.length} items.`)
      } else if (successItems.length > 0) {
        // All items imported successfully, complete the import
        completeImport(true)
      }
      
    } catch (e) {
      console.error('Import process error:', e)
      setError(e.message || 'An unexpected error occurred during import')
    } finally {
      setSubmitting(false)
    }
  }, [approvedData, completeImport, dispatch, importType, submitting]);
  
  useEffect(() => {
    const _filterData = () => {
      let approved = []
      let rejected = []
      data.forEach((item) => {
        // Check if this item has been fixed by looking at multiple indicators
        // An item is considered fixed if:
        // 1. It has a client assigned, and all required fields are present, OR
        // 2. It has explicitly false validation flags and no error, OR
        // 3. It has been specifically marked as fixed

        // Check for required fields
        const hasRequiredFields = 
          item.locationName && 
          item.address && 
          item.locationType;
        
        // Check if validation flags are explicitly set to false
        const hasCleanValidationFlags = 
          item.invalidAddress === false || 
          item.missingAddress === false || 
          item.missingLocationName === false || 
          item.missingLocationType === false || 
          item.invalidLocationType === false;
        
        // If the item has a client AND all required fields OR has been explicitly marked as valid,
        // consider it fixed and approved
        const isFixed = 
          (item.client && hasRequiredFields) || 
          (hasCleanValidationFlags && !item.error) ||
          item.fixed === true;
        
        if (isFixed) {
          // Clone the item, explicitly set validation flags to false, and mark as fixed
          const fixedItem = {...item, 
            invalidAddress: false,
            missingAddress: false,
            missingLocationName: false,
            missingLocationType: false,
            invalidLocationType: false,
            error: null,
            fixed: true  // Mark explicitly as fixed
          };
          approved.push(fixedItem);
        }
        // Check for any explicit errors from the validation process
        else if (item.error) {
          // Copy the item and ensure the error is preserved
          const rejectedItem = {...item, error: item.error};
          rejected.push(rejectedItem);
        }
        // Check for autocorrected addresses that need review
        else if (item.autocorrectedAddress) {
          // Add to rejected items so the user can review the autocorrected address
          const errorMessage = 'Address was autocorrected and needs review';
          rejected.push({...item, error: errorMessage});
        }
        // Check for missing or invalid data
        else if (
          !item.address || 
          item.invalidAddress || 
          item.missingAddress || 
          !item.locationType || 
          item.invalidLocationType || 
          item.missingLocationType || 
          (importType === 'location' && (item.missingLocationName || !item.locationName))
        ) {
          // Build a descriptive error message based on what's wrong
          let errorMessages = [];
          
          if (!item.locationName || item.missingLocationName) {
            errorMessages.push('Missing location name');
          }
          
          if (!item.address || item.missingAddress) {
            errorMessages.push('Missing address');
          } else if (item.invalidAddress) {
            errorMessages.push('Invalid or unrecognized address');
            // Restore the original address for display
            if (item.originalAddress) {
              item.displayAddress = item.originalAddress;
            }
          }
          
          if (!item.locationType || item.missingLocationType) {
            errorMessages.push('Missing location type');
          } else if (item.invalidLocationType) {
            errorMessages.push('Invalid location type');
          }
          
          // Create a descriptive error message
          const errorMessage = errorMessages.join(', ');
          rejected.push({...item, error: errorMessage});
        } else {
          approved.push({...item});
        }
      });
      
      setApprovedData(approved);
      setRejectedData(rejected);
    };
    
    if (data.length > 0) {
      _filterData();
    }
  }, [data, importType]);

  useEffect(() => {
    if (approvedData.length > 0 && rejectedData.length === 0 && importType === 'location') {
      dispatch(getClients())
    }
  }, [approvedData, rejectedData, importType, dispatch])

  // Register the _onSubmit function with the parent component
  useEffect(() => {
    if (typeof setImportFunction === 'function') {
      setImportFunction(_onSubmit);
    }
  }, [setImportFunction, _onSubmit]);

  // Add a function to record pending edits
  const recordPendingEdit = (index, field, value) => {
    setPendingEdits(prevState => ({
      ...prevState,
      [index]: {
        ...(prevState[index] || {}),
        [field]: value
      }
    }));
  };

  // Function to save all pending edits
  const saveAllPendingEdits = async () => {
    // Create a copy of indices to process (since we'll be modifying the list)
    const indicesToProcess = Object.keys(pendingEdits).map(Number);
    
    for (const index of indicesToProcess) {
      if (index < rejectedData.length) {
        const item = rejectedData[index];
        const edits = pendingEdits[index];
        
        // Skip if no actual edits
        if (!edits) continue;
        
        // Create updated item with pending edits
        const updatedItem = {
          ...item,
          locationName: edits.locationName || item.locationName,
          address: edits.address || item.address,
          locationType: edits.locationType || item.locationType,
          error: null,
          invalidAddress: false,
          missingAddress: false,
          missingLocationName: false,
          missingLocationType: false,
          invalidLocationType: false,
          fixed: true
        };
        
        // Validate the item has all required fields
        if (!updatedItem.locationName || !updatedItem.address || !updatedItem.locationType) {
          // Skip invalid items
          continue;
        }
        
        // Process this item
        await _updateRejectedItem(updatedItem, index, true);
      }
    }
    
    // Clear pending edits after all items are processed
    setPendingEdits({});
  };

  const _updateRejectedItem = (item, index, isBulkSave = false) => {
    // If we're doing a bulk save, we need to adjust the index for each item
    // since the array shrinks after each item is processed
    const adjustedIndex = isBulkSave ? rejectedData.findIndex(i => 
      i.originalAddress === item.originalAddress || 
      (i.locationName === item.locationName && i.address?.formattedAddress === item.address?.formattedAddress)
    ) : index;
    
    if (adjustedIndex === -1) return; // Item not found
    
    const newRejectedData = [...rejectedData];
    newRejectedData.splice(adjustedIndex, 1);
    
    // For bulk save, remove this item from pending edits
    if (isBulkSave) {
      setPendingEdits(prev => {
        const newPending = {...prev};
        delete newPending[adjustedIndex];
        // Adjust indices for remaining items
        const adjustedPending = {};
        Object.entries(newPending).forEach(([key, value]) => {
          const numKey = Number(key);
          if (numKey > adjustedIndex) {
            adjustedPending[numKey - 1] = value;
          } else {
            adjustedPending[numKey] = value;
          }
        });
        return adjustedPending;
      });
    }
    
    setRejectedData(newRejectedData);
    
    // Mark the item as fixed and ensure all validation flags are explicitly set to false
    const fixedItem = {
      ...item,
      invalidAddress: false,
      missingAddress: false,
      missingLocationName: false,
      missingLocationType: false,
      invalidLocationType: false,
      error: null,
      fixed: true // Add a permanent marker that this item has been fixed
    };
    
    // Also update the item in the main data array via the parent component
    if (typeof updateClientAssignment === 'function') {
      // Find the item in the original data array by looking for similar properties
      const originalIndex = data.findIndex(dataItem => {
        // Try to match by properties that should uniquely identify the item
        if (dataItem.originalAddress && item.originalAddress && 
            dataItem.originalAddress === item.originalAddress) {
          return true;
        }
        
        // If address objects exist and can be compared by formattedAddress
        if (dataItem.address?.formattedAddress && item.address?.formattedAddress && 
            dataItem.address.formattedAddress === item.address.formattedAddress) {
          return true;
        }
        
        // If we can match by name
        if (dataItem.locationName && item.locationName && 
            dataItem.locationName === item.locationName) {
          return true;
        }
        
        // Last resort index-based matching (less reliable)
        return rejectedData.indexOf(dataItem) === adjustedIndex;
      });
      
      if (originalIndex !== -1) {
        // We use a dummy client ID just to trigger the update with the fixed flag
        // The actual client assignment or other fixed data will be passed in the fixedItem
        setTimeout(() => {
          updateClientAssignment(originalIndex, item.client || null, false, fixedItem);
        }, 0);
      }
    }
    
    setApprovedData([...approvedData, fixedItem]);
  }

  const _updateApprovedItem = (item, index, isBulkUpdate = false) => {
    // Create a new reference to force state update
    let newApprovedData = [];
    
    if (isBulkUpdate) {
      // Get the client ID from the item
      const clientId = item.client;
      
      // Apply the client ID to all items
      newApprovedData = approvedData.map(existingItem => {
        // Create a new object with all properties from existingItem
        const updatedItem = JSON.parse(JSON.stringify(existingItem));
        
        // Add the client ID
        updatedItem.client = clientId;
        
        // Explicitly preserve validation state
        updatedItem.invalidAddress = false;
        updatedItem.missingAddress = false;
        updatedItem.missingLocationName = false;
        updatedItem.missingLocationType = false;
        updatedItem.invalidLocationType = false;
        updatedItem.error = null;
        updatedItem.fixed = true; // Always mark as fixed
        
        return updatedItem;
      });
      
      // Also update in the parent component if the function exists
      if (typeof updateClientAssignment === 'function') {
        setTimeout(() => {
          // Pass extra flag to ensure fixed state is preserved in parent
          updateClientAssignment(0, clientId, true, { fixed: true });
        }, 0);
      }
    } else {
      // Individual item update
      
      // Use map to create a new array with the updated item
      newApprovedData = approvedData.map((existingItem, i) => {
        if (i === index) {
          // Create a deep clone with all properties
          const updatedItem = JSON.parse(JSON.stringify(item));
          
          // Explicitly preserve validation state
          updatedItem.invalidAddress = false;
          updatedItem.missingAddress = false;
          updatedItem.missingLocationName = false;
          updatedItem.missingLocationType = false;
          updatedItem.invalidLocationType = false;
          updatedItem.error = null;
          updatedItem.fixed = true; // Always mark as fixed
          
          return updatedItem;
        }
        // Return a deep clone of the existing item
        return JSON.parse(JSON.stringify(existingItem));
      });
      
      // Also update in the parent component if the function exists
      if (typeof updateClientAssignment === 'function' && item.client) {
        // Find the item in the original data array by matching key properties
        const originalIndex = data.findIndex(dataItem => 
          dataItem.locationName === item.locationName && 
          dataItem.address?.formattedAddress === item.address?.formattedAddress
        );
        
        if (originalIndex !== -1) {
          setTimeout(() => {
            // Create fixed version of the item
            const fixedItem = {
              ...item,
              fixed: true,
              invalidAddress: false,
              missingAddress: false,
              missingLocationName: false,
              missingLocationType: false,
              invalidLocationType: false,
              error: null
            };
            updateClientAssignment(originalIndex, item.client, false, fixedItem);
          }, 0);
        }
      }
    }
    
    // Force update with setState
    setApprovedData([...newApprovedData]);
  }

  // Add a new method to handle the display of rejected items
  const renderRejectedItems = () => {
    if (rejectedData.length === 0) {
      return null;
    }

    return (
      <>
        <RejectedItemList 
          importType={importType} 
          data={rejectedData} 
          updateItem={_updateRejectedItem}
          recordPendingEdit={recordPendingEdit}
          saveAllPendingEdits={saveAllPendingEdits}
          pendingEdits={pendingEdits}
          onCompletenessChange={setAllRejectedItemsComplete}
        />
      </>
    )
  }

  // Add a method to render approved items
  const renderApprovedItems = () => {
    // Only show Ready to Import after all items have been properly saved
    // Check for any rejectedData items that still need saving or aren't complete
    if (rejectedData.length > 0 && (Object.keys(pendingEdits).length > 0 || !allRejectedItemsComplete)) {
      return null;
    }
    
    if (approvedData.length === 0) {
      return (
        <Alert color="warning" style={{ marginBottom: SPACING.md }}>
          No valid {importType}s found to import. Please correct the items requiring attention below.
        </Alert>
      );
    }

    return (
      <>
        <ImportFinalList
          importType={importType}
          data={approvedData}
          updateItem={_updateApprovedItem}
          clients={clients}
        />
      </>
    );
  };

  // Extract common display logic
  const renderImportContent = () => {
    if (submitting) {
      return (
        <div style={{ textAlign: 'center', padding: SPACING.lg }}>
          <UploadTitle>Importing your data...</UploadTitle>
          <ProgressBar>
            <ProgressFill percentage={(progress.current / progress.total) * 100} />
          </ProgressBar>
          <UploadText>
            Processed {progress.current} of {progress.total} items
          </UploadText>
        </div>
      );
    }

    if (importResults.success > 0 || importResults.failed > 0) {
      return (
        <div style={{ padding: SPACING.md }}>
          <Alert color={error ? 'warning' : 'success'}>
            {error ? (
              <>
                <h5>Import partially completed</h5>
                <p>{error}</p>
                <p>Successfully imported {importResults.success} items, {importResults.failed} failed.</p>
              </>
            ) : (
              <>
                <h5>Import completed successfully!</h5>
                <p>All {importResults.success} items were imported successfully.</p>
              </>
            )}
          </Alert>
        </div>
      );
    }

    // Show Items Requiring Attention first, followed by Ready to Import
    return (
      <>
        {renderRejectedItems()}
        {renderApprovedItems()}
      </>
    );
  };

  return (
    <ContentWrapper hideFooter={hideFooter}>
      <ListsContainer>
        {renderImportContent()}
      </ListsContainer>
      
      {!hideFooter && (
        <ModalFooterFixed>
          {!isLoading && !submitting && data.length > 0 && (
            <>
              {approvedData.length === 0 ? (
                <UploadText>
                  No valid {importType}s found to import.
                </UploadText>
              ) : rejectedData.length > 0 ? (
                <UploadText color={COLORS.danger}>
                  {allRejectedItemsComplete && Object.keys(pendingEdits).length === 0
                    ? "All fields are complete and saved! Review the 'Ready to Import' section above."
                    : Object.keys(pendingEdits).length > 0
                      ? "Please save all completed fields to view the 'Ready to Import' section."
                      : "Complete all fields in the 'Items Requiring Attention' section to proceed."}
                </UploadText>
              ) : (
                <>
                  <ImportSubmitButton 
                    onClick={_onSubmit}
                    disabled={importType === 'location' && approvedData.some(item => !item.client)}
                  >
                    {importType === 'client' 
                      ? `Import ${approvedData.length} Client${approvedData.length !== 1 ? 's' : ''}` 
                      : `Import ${approvedData.length} Location${approvedData.length !== 1 ? 's' : ''}`}
                  </ImportSubmitButton>
                </>
              )}
            </>
          )}
        </ModalFooterFixed>
      )}
    </ContentWrapper>
  );
}

export default FinalizeImport;