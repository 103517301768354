import React, { useEffect, useState } from 'react'
import MarkdownEditor from 'components/MarkdownEditor'
import Text from './knobs/Text'
import { Button, Card, CardBody, UncontrolledTooltip } from 'reactstrap'
import styled from 'styled-components'
import { FaInfoCircle } from 'react-icons/fa'
import { transformForDisplay, transformForStorage } from 'utils/autofill'

// Flag to temporarily disable autofill UI
const AUTOFILL_UI_ENABLED = false;

const AlertMessage = styled.span`
  font-weight: 400;
  color: red;
`

const PlaceholderInfo = styled(Card)`
  margin-bottom: 1rem;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
`

const PlaceholderList = styled.div`
  font-size: 0.9rem;
  color: #495057;
  margin-bottom: 1rem;
  
  .placeholder-group {
    margin-bottom: 1rem;
    
    h6 {
      font-size: 0.95rem;
      margin-bottom: 0.5rem;
      font-weight: bold;
    }
    
    ul {
      list-style-type: disc;
      padding-left: 1.5rem;
      margin: 0;
    }
    
    li {
      margin-bottom: 0.25rem;
    }
  }
`

const ButtonContent = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`

const ButtonContainer = styled.div`
  margin: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  padding: 4px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
  }
`

const InfoIcon = styled(FaInfoCircle)`
  color: #6c757d;
  font-size: 14px;
`

const StyledTooltip = styled(UncontrolledTooltip)`
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  &.show {
    opacity: 1;
  }
  .tooltip-inner {
    max-width: 360px;
    background-color: #343a40;
    padding: 12px;
    font-size: 13px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    text-align: left;
  }
  &.fade {
    transition: opacity 0.15s linear;
  }
  ol {
    padding-left: 1.5rem;
    margin: 0.5rem 0 0;
    text-align: left;
  }
`

const placeholders = {
  client: [
    { label: 'Client Name', value: '{{client.name}}' },
    { label: 'Client Address', value: '{{client.address}}' }
  ],
  location: [
    { label: 'Location Name', value: '{{location.name}}' },
    { label: 'Location Address', value: '{{location.address}}' }
  ],
  company: [
    { label: 'Your Company Name', value: '{{company.name}}' }
  ]
}

export default ({ element, onChange, walkthroughNotes }) => {
  if (!element || !element.data) return null

  const [imagePaste, setImagePaste] = useState(false)
  const [showPlaceholders, setShowPlaceholders] = useState(false)
  const [editorInstance, setEditorInstance] = useState(null)

  useEffect(() => {
    if (imagePaste) {
      const timer = setTimeout(() => setImagePaste(false), 3000)
      return () => clearTimeout(timer)
    }
  }, [imagePaste])

  const insertPlaceholder = (placeholder, event) => {
    event.preventDefault()
    if (editorInstance) editorInstance.insertText(placeholder)
  }

  const handleEditorCreated = (editor) => setEditorInstance(editor)

  const initialContent = transformForDisplay(element.data.markdown)

  return (
    <div>
      <Text placeholder="Element Name" field="name" data={element.data} onChange={onChange} />

      {AUTOFILL_UI_ENABLED && (
        <ButtonContainer>
          <Button size="sm" color="secondary" onClick={() => setShowPlaceholders(!showPlaceholders)}>
            <ButtonContent>
              {showPlaceholders ? 'Hide Auto-Fill Fields' : 'Add Auto-Fill Fields'}
            </ButtonContent>
          </Button>
          <IconWrapper>
            <InfoIcon id="autoFillInfo" role="img" aria-label="Information" />
          </IconWrapper>
          <StyledTooltip target="autoFillInfo" placement="right" delay={{ show: 50, hide: 150 }} trigger="hover" boundariesElement="window" fade={true}>
            <div>
              How to use Auto-Fill Fields:
              <ol>
                <li>Click "Add Auto-Fill Fields" to show available fields</li>
                <li>Place your cursor where you want to add a field</li>
                <li>Click any field to insert it</li>
                <li>Style your fields using bold, italics, or other formatting</li>
              </ol>
            </div>
          </StyledTooltip>
        </ButtonContainer>
      )}

      {AUTOFILL_UI_ENABLED && showPlaceholders && (
        <PlaceholderInfo>
          <CardBody>
            <PlaceholderList>
              {Object.entries(placeholders).map(([group, items]) => (
                <div key={group} className="placeholder-group">
                  <h6>
                    {group === 'client'
                      ? 'Client Information'
                      : group === 'location'
                      ? 'Location Information'
                      : 'Company Information'}
                  </h6>
                  <ul>
                    {items.map((p, i) => (
                      <li key={i}>
                        <Button type="button" size="sm" color="link" onClick={(e) => insertPlaceholder(p.value, e)}>
                          {p.label} - <code>{p.value}</code>
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </PlaceholderList>
          </CardBody>
        </PlaceholderInfo>
      )}

      {walkthroughNotes && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 4 }}>
          <Button size="sm" onClick={() => onChange({ ...element.data, markdown: walkthroughNotes })}>
            Use Walkthrough Notes
          </Button>
        </div>
      )}

      <MarkdownEditor
        editorId="proposal-element-markdown-editor"
        height="auto"
        value={initialContent}
        onChange={data => onChange({ ...element.data, markdown: transformForStorage(data) })}
        handlerPaste={setImagePaste}
        onEditorCreated={handleEditorCreated}
      />

      <div style={{ minHeight: '50px' }}>
        {imagePaste && <AlertMessage>Please use the Image element if you want to add it.</AlertMessage>}
      </div>
    </div>
  )
}
