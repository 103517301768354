import React, { useEffect } from 'react'
import {
  Card,
  Badge,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Alert
} from 'reactstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { axiosClient } from 'store'
import { FiTag } from 'react-icons/fi'
import { storage } from 'utils'

import { ActionButtons, SpanLink } from 'components/lib/Button'
import Label from 'components/Label'
import LabelEditor from 'components/LabelEditor'
import { connectConfirmation } from 'components/confirm'

import {
  ActionBar,
  ButtonGroup,
  BackButton,
  RestartButton,
  Form,
  Progress,
  Questions,
  PlaceStage,
  ServiceStage,
  CommercialEmployeesStage,
  CommercialExpectationsStage,
  CommercialFloorStage,
  CommercialFrequencyStage,
  CommercialLocationStage,
  CommercialRecurringStage,
  CommercialReviewStage,
  ResidentialAddonsStage,
  ResidentialLocationStage,
  ResidentialRecurringStage,
  ResidentialRoomsStage,
  ResidentialReviewStage
} from './components'
import {
  defaultBid,
  defaultLocation,
  defaultCommercialReview,
  defaultResidentialReview,
  questionnaire
} from './data'
import { calculateCommercialBid, calculateResidentialBid } from './utils'
import { StorageState, ToggleState } from '../../../../hooks'
import { browserWindow } from '../../../../utils'
import SelectClientLocationModal from 'screens/Dashboard/components/modals/SelectClientLocationModal'

const DisclaimerAlert = styled(Alert)`
  margin: 20px 200px 0;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  background-color: #fff3cd;
  border: 2px solid #ffeeba;
  color: #856404;
  
  strong {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  ul {
    text-align: left;
    margin: 10px 0 0;
    padding-left: 20px;
  }
`

const Calculator = props => {
  // Add ref for current stage
  const currentStageRef = React.useRef(null)

  // state
  const [bid, onBidChange, resetBid] = StorageState('bid', defaultBid)
  const [location, onLocationChange, resetLocation] = StorageState(
    'location',
    defaultLocation
  )
  const [place, onPlaceChange, resetPlace] = StorageState('place', '')
  const [review, onReviewChange] = StorageState('review', {})
  const [service, onServiceChange, resetService] = StorageState('service', '')
  const [stage, onStageChange, resetStage] = StorageState('stage', 'place')
  const [completed, setCompleted] = ToggleState(false)
  const [submitted, setSubmitted] = ToggleState(false)
  const [showLocationModal, setShowLocationModal] = ToggleState(false)
  const [existingBid, setExistingBid] = ToggleState('')

  // other variables
  const estimateId = existingBid && existingBid._id
  const client = existingBid && existingBid.client
  const clientName =
    existingBid && existingBid.client && existingBid.client.name
  const labels = existingBid && existingBid.labels
  const existingLocation = existingBid && existingBid.location
  const existingLocationAddress =
    existingLocation &&
    existingLocation.address &&
    existingLocation.address.fullAddress

  useEffect(() => {
    if (
      props &&
      props.match &&
      props.match.params &&
      props.match.params.estimateId
    ) {
      const fetchQB = async () => {
        const res = await axiosClient.get(
          `/api/estimates/${props.match.params.estimateId}`
        )

        if (res.data && res.data.bid) {
          setExistingBid(res.data)
          let bid = res.data.bid
          bid.commercial
            ? onServiceChange('commercial')
            : onServiceChange('residential')
          onBidChange(bid)
          onReviewChange({
            billingFrequncy: bid.billingFrequency,
            margin: bid.margin
          })
          setCompleted(true)
          onStageChange('review')
        }
      }
      fetchQB()
    }

    if (storage && storage.getItem('prevQuickBid')) {
      const previousBid = JSON.parse(storage.getItem('prevQuickBid'))
      storage.removeItem('prevQuickBid')

      onServiceChange(previousBid.service || 'commercial')
      onBidChange(previousBid)
      onReviewChange({
        billingFrequncy: previousBid.billingFrequency,
        margin: previousBid.margin
      })
      setCompleted(true)
      onStageChange('review')
    }

    return () => {
      storage.removeItem('bid')
      storage.removeItem('location')
      storage.removeItem('place')
      storage.removeItem('review')
      storage.removeItem('stage')
    }
  }, [])

  let questions = []
  let currentIndex = 0

  if (questionnaire[service] && (questionnaire[service].hasOwnProperty(stage) || (stage === 'review' && service) || (stage && stage.endsWith('-review')))) {
    questions = Object.keys(questionnaire[service])
    
    // Handle the review stage specially
    if (stage === 'review' || (stage && (stage === 'commercial-review' || stage === 'residential-review'))) {
      const serviceSpecificReview = `${service}-review`
      currentIndex = questions.findIndex(question => question === serviceSpecificReview)
      // If service-specific review not found, use the last question
      if (currentIndex === -1) {
        currentIndex = questions.length - 1
      }
    } else {
      currentIndex = questions.findIndex(question => question === stage)
    }
  }

  const onBack = event => {
    
    if (stage === 'place') {
      onStageChange('start')
    } else if (stage === 'service') {
      onStageChange('place')
    } else if (stage === 'commercial-review' || (stage === 'review' && service === 'commercial')) {
      // For commercial review, find the last non-review stage in the commercial flow
      const commercialStages = questions.filter(q => q.startsWith('commercial-') && q !== 'commercial-review');
      if (commercialStages.length > 0) {
        const lastStage = commercialStages[commercialStages.length - 1];
        onStageChange(lastStage);
      } else {
        // Fallback to employees if no commercial stages found
        onStageChange('employees');
      }
    } else if (stage === 'residential-review' || (stage === 'review' && service === 'residential')) {
      // For residential review, find the last non-review stage in the residential flow
      const residentialStages = questions.filter(q => q.startsWith('residential-') && q !== 'residential-review');
      if (residentialStages.length > 0) {
        const lastStage = residentialStages[residentialStages.length - 1];
        onStageChange(lastStage);
      } else {
        // Fallback to addons if no residential stages found
        onStageChange('addons');
      }
    } else {
      // Handle back navigation when frequency should be skipped
      if (questions.length === 0 || currentIndex <= 0 || currentIndex >= questions.length) {
        onStageChange('place');
      } else {
        const prevStage = questions[currentIndex - 1]
        if (prevStage === 'frequency' && bid.recurring === 'no') {
          onStageChange(questions[currentIndex - 2]) // Go back two steps to skip frequency
        } else {
          onStageChange(questions[currentIndex - 1])
        }
      }
    }
  }

  const onPlace = (event, place) => {
    onPlaceChange(place)
    onBidChange({ ...bid, place })

    if (completed) {
      // Set the correct review stage based on service
      if (service === 'commercial') {
        onStageChange('commercial-review')
      } else if (service === 'residential') {
        onStageChange('residential-review')
      } else {
        onStageChange('review')
      }
    } else {
      onStageChange('service')
    }
  }

  const onReviewUpdate = review => {
    onReviewChange(review)
    onBidChange({ ...bid, ...review })
  }

  const onService = (event, service) => {
    onServiceChange(service)

    if (service === 'commercial') {
      onReviewChange(defaultCommercialReview)
      onBidChange({ ...bid, ...defaultCommercialReview, service })
    }

    if (service === 'residential') {
      onReviewChange(defaultResidentialReview)
      onBidChange({ ...bid, ...defaultResidentialReview, service })
    }

    // Get the questions for the selected service
    const serviceQuestions = Object.keys(questionnaire[service])
    
    // Set the first question in the service flow
    if (serviceQuestions.length > 0) {
      // Find the first service-specific question
      const firstServiceQuestion = serviceQuestions.find(q => q.startsWith(`${service}-`))
      onStageChange(firstServiceQuestion || serviceQuestions[0])
    }
  }

  // Shared function to handle saving and navigation
  const handleSaveAndNavigate = (stage, value, targetStage = null) => {
    // Update the bid state first
    const updatedBid = { ...bid }
    if (stage === 'location' && service === 'commercial') {
      updatedBid[stage] = value
      updatedBid.location = location
    } else {
      updatedBid[stage] = value
    }

    // Special handling for recurring stage
    if (stage === 'recurring') {
      if (value === 'no') {
        updatedBid.frequency = {} // Clear frequency data
        onBidChange(updatedBid)
        // Find next stage after frequency if no target stage specified
        if (!targetStage) {
          const nextIndex = questions.findIndex(q => q === 'frequency') + 1
          onStageChange(questions[nextIndex])
        } else {
          onStageChange(targetStage)
        }
      } else {
        onBidChange(updatedBid)
        onStageChange(targetStage || questions[currentIndex + 1])
      }
    } else if (
      // Only go to review if we're in the final stage
      (service === 'commercial' && (stage === 'commercial-employees' || stage === 'employees')) || 
      (service === 'residential' && (stage === 'residential-addons' || stage === 'addons'))
    ) {
      // We're at the final stage, so go to review
      onBidChange(updatedBid)
      setCompleted(true)
      // Set the correct review stage based on service
      if (service === 'commercial') {
        onStageChange('commercial-review')
      } else if (service === 'residential') {
        onStageChange('residential-review')
      } else {
        onStageChange('review')
      }
    } else {
      // We're not at the final stage, proceed to the next stage
      onBidChange(updatedBid)
      if (targetStage) {
        onStageChange(targetStage)
      } else {
        const nextStage = questions[currentIndex + 1]
        if (nextStage === 'frequency' && bid.recurring === 'no') {
          onStageChange(questions[currentIndex + 2]) // Skip frequency
        } else {
          onStageChange(questions[currentIndex + 1])
        }
      }
    }

    // Handle analytics
    if (submitted) {
      setSubmitted(true)
      if (browserWindow.ga) {
        browserWindow.ga('send', {
          hitType: 'event',
          eventAction: 'Submit',
          eventCategory: 'Lead',
          eventLabel: 'QuickBid'
        })
      }
    }
  }

  const onClick = (event, value) => {
    handleSaveAndNavigate(stage, value)
  }

  const createBidData = () => {
    let submittedTotal
    let data = { ...bid, ...review }
    const margin = 1 + review.margin / 100

    if (service === 'commercial') {
      const isHourlyBid =
        bid.employees.employer === 'yes' && !bid.employees.useStateAverage

      const isRecurring = bid.recurring === 'yes'

      const billingFrequency =
        typeof review.billingFrequency !== 'undefined'
          ? review.billingFrequency
          : defaultCommercialReview.billingFrequency

      const {
        total,
        hourlyRate
        // hoursPerJob,
        // hourlyCostPerJob,
        // adjustedProductionRate
      } = calculateCommercialBid(
        bid,
        billingFrequency,
        margin,
        isHourlyBid,
        isRecurring
      )
      submittedTotal = total
      data.employees.rate = hourlyRate
      data = { ...data, total: Number(submittedTotal), commercial: true }
    }

    if (service === 'residential') {
      const total = calculateResidentialBid(bid, margin)
      const formattedTotal = Object.keys(total).reduce(
        (all, next) => ({ ...all, [next]: total[next].amount }),
        {}
      )
      data = { ...data, ...formattedTotal, residential: true }
    }

    return data
  }

  const onSubmit = async values => {
    const data = createBidData()

    let quickbid = {
      type: 'quickbid',
      client: values && values.client,
      location: values && values.location,
      bid: data
    }

    const res = await axiosClient.post('/api/estimates/', quickbid)

    if (res.data) {
      setExistingBid(res.data)
      let bid = res.data.bid
      bid.commercial
        ? onServiceChange('commercial')
        : onServiceChange('residential')
      onBidChange(bid)
      onReviewChange({
        billingFrequncy: bid.billingFrequency,
        margin: bid.margin
      })
      setShowLocationModal(false)
      toast('Bid created successfully.')
      props.history.push(`/dashboard/quickbid/${res.data._id}`)
    }
  }

  const updateQuickBid = async callback => {
    if (!existingBid) {
      return
    }

    const data = createBidData()

    const quickbid = {
      _id: existingBid._id,
      bid: data
    }

    const res = await axiosClient.patch(
      `/api/estimates/${existingBid._id}`,
      quickbid
    )

    if (res.data) {
      setExistingBid(res.data)
      let bid = res.data.bid
      bid.commercial
        ? onServiceChange('commercial')
        : onServiceChange('residential')
      onBidChange(bid)
      onReviewChange({
        billingFrequncy: bid.billingFrequency,
        margin: bid.margin
      })
      toast('Bid updated successfully.')
      if (callback) {
        callback()
      }
    }
  }

  const _createProposal = async () => {
    if (!client || !existingBid) {
      return
    }

    const proposal = {
      estimate: estimateId,
      client: client,
      location: existingLocation,
      merchant: existingBid.merchant,
      version: 2
    }

    const result = await axiosClient.post('/api/proposals/', proposal)

    if (result && result.data) {
      props.history.push(`/dashboard/edit-proposal/${result.data._id}`, {
        proposal: result.data
      })
      toast('Proposal Created')
    } else {
      toast('Unable to create proposal')
    }
  }

  const onTrash = async () => {
    if (!existingBid) {
      return null
    }
    await axiosClient.patch(`/api/estimates/${existingBid._id}/delete`)
    toast('Bid archived successfully.')
    props.history.push('/dashboard/estimates')
  }

  const onFinish = () => {
    if (existingBid) {
      updateQuickBid()
    } else {
      setShowLocationModal(true)
    }
  }

  const onResetService = () => {
    setSubmitted(false)
    setCompleted(false)
    resetService()
    resetLocation()
    onStageChange('service')
  }

  const onStartOver = event => {
    setSubmitted(false)
    setCompleted(false)
    resetBid()
    resetPlace()
    resetService()
    resetLocation()
    resetStage()
  }

  let question = null

  switch (stage) {
    case 'place': {
      question = <PlaceStage current={bid.place} onPlace={onPlace} />
      break
    }
    case 'service': {
      question = (
        <ServiceStage
          current={bid.service}
          onService={onService}
          service={service}
        />
      )
      break
    }
    case 'employees': {
      question = (
        <CommercialEmployeesStage
          ref={currentStageRef}
          current={bid.employees}
          id={questions[currentIndex]}
          onClick={onClick}
          place={place}
          {...questionnaire[service][stage]}
        />
      )
      break
    }
    case 'expectations': {
      question = (
        <CommercialExpectationsStage
          ref={currentStageRef}
          current={bid.expectations}
          id={questions[currentIndex]}
          onClick={onClick}
          {...questionnaire[service][stage]}
        />
      )
      break
    }
    case 'floors': {
      question = (
        <CommercialFloorStage
          ref={currentStageRef}
          current={bid.floors}
          id={questions[currentIndex]}
          onClick={onClick}
          {...questionnaire[service][stage]}
        />
      )
      break
    }
    case 'recurring': {
      if (service === 'commercial') {
        question = (
          <CommercialRecurringStage
            ref={currentStageRef}
            current={bid.recurring}
            id={questions[currentIndex]}
            onClick={onClick}
            {...questionnaire[service][stage]}
          />
        )
      }
      if (service === 'residential') {
        question = (
          <ResidentialRecurringStage
            ref={currentStageRef}
            current={bid.recurring}
            id={questions[currentIndex]}
            onClick={onClick}
            {...questionnaire[service][stage]}
          />
        )
      }
      break
    }
    case 'frequency': {
      question = (
        <CommercialFrequencyStage
          ref={currentStageRef}
          current={bid.frequency}
          id={questions[currentIndex]}
          onClick={onClick}
          {...questionnaire[service][stage]}
        />
      )
      break
    }
    case 'location': {
      if (service === 'commercial') {
        question = (
          <CommercialLocationStage
            ref={currentStageRef}
            current={bid.location}
            id={questions[currentIndex]}
            location={location}
            onClick={onClick}
            onLocationChange={onLocationChange}
            {...questionnaire[service][stage]}
          />
        )
      }
      if (service === 'residential') {
        question = (
          <ResidentialLocationStage
            ref={currentStageRef}
            current={bid.location}
            id={questions[currentIndex]}
            onClick={onClick}
            {...questionnaire[service][stage]}
          />
        )
      }
      break
    }
    case 'rooms': {
      question = (
        <ResidentialRoomsStage
          ref={currentStageRef}
          current={bid.rooms}
          id={questions[currentIndex]}
          onClick={onClick}
          {...questionnaire[service][stage]}
        />
      )
      break
    }
    case 'addons': {
      question = (
        <ResidentialAddonsStage
          ref={currentStageRef}
          current={bid.addons}
          id={questions[currentIndex]}
          onClick={onClick}
          {...questionnaire[service][stage]}
        />
      )
      break
    }
    case 'commercial-review': {
      question = (
        <CommercialReviewStage
          bid={bid}
          fields={questionnaire.commercial.review.fields}
          id={questions[currentIndex]}
          onResetService={onResetService}
          onReviewUpdate={onReviewUpdate}
          onStageChange={onStageChange}
          review={review}
          questions={questions}
          currentIndex={currentIndex}
        />
      )
      break
    }
    case 'residential-review': {
      question = (
        <ResidentialReviewStage
          bid={bid}
          fields={questionnaire.residential.review.fields}
          id={questions[currentIndex]}
          onResetService={onResetService}
          onReviewUpdate={onReviewUpdate}
          onStageChange={onStageChange}
          review={review}
          questions={questions}
          currentIndex={currentIndex}
        />
      )
      break
    }
    default: {
      question = <PlaceStage current={bid.place} onPlace={onPlace} />
      break
    }
  }

  const showProgress =
    stage !== 'place' && 
    stage !== 'service' && 
    stage !== undefined &&
    stage !== null &&
    (stage === 'review' || 
     stage === 'commercial-review' || 
     stage === 'residential-review' || 
     (stage && !stage.startsWith('place') && !stage.startsWith('service')))

  let quickbid = (
    <>
      {stage !== 'place' && (
        <ButtonGroup>
          <BackButton onClick={onBack} type={'button'}>
            Back
          </BackButton>
          <RestartButton onClick={onStartOver} type={'button'}>
            Start Over
          </RestartButton>
        </ButtonGroup>
      )}
      <Form
        id={`quickbid`}
        method={'post'}
        name={`quickbid`}
        onSubmit={onSubmit}
      >
        <Questions>{question}</Questions>
        {showProgress && (
          <Progress
            currentIndex={currentIndex}
            completed={completed || (stage && stage.includes('review'))}
            onStageChange={onStageChange}
            questions={questions}
            bid={bid}
            currentStageRef={currentStageRef}
            handleSaveAndNavigate={handleSaveAndNavigate}
          />
        )}
      </Form>
    </>
  )

  // For service-specific review stages, always render the progress bar directly
  if (stage === 'commercial-review' || stage === 'residential-review') {
    quickbid = (
      <>
        {stage !== 'place' && (
          <ButtonGroup>
            <BackButton onClick={onBack} type={'button'}>
              Back
            </BackButton>
            <RestartButton onClick={onStartOver} type={'button'}>
              Start Over
            </RestartButton>
          </ButtonGroup>
        )}
        <Form
          id={`quickbid`}
          method={'post'}
          name={`quickbid`}
          onSubmit={onSubmit}
        >
          <Questions>{question}</Questions>
          {showProgress && (
            <Progress
              currentIndex={currentIndex}
              completed={true}
              onStageChange={onStageChange}
              questions={questions}
              bid={bid}
              currentStageRef={currentStageRef}
              handleSaveAndNavigate={handleSaveAndNavigate}
            />
          )}
        </Form>
      </>
    )
  }

  // Handle the generic 'review' stage
  if (stage === 'review') {
    if (service === 'commercial') {
      // Use the commercial review component
      quickbid = (
        <>
          <ButtonGroup>
            <BackButton onClick={onBack} type={'button'}>
              Back
            </BackButton>
            <RestartButton onClick={onStartOver} type={'button'}>
              Start Over
            </RestartButton>
          </ButtonGroup>
          <Form
            id={`quickbid`}
            method={'post'}
            name={`quickbid`}
            onSubmit={onSubmit}
          >
            <Questions>
              <CommercialReviewStage
                bid={bid}
                current={bid.review}
                id="commercial-review"
                onFinish={onFinish}
                onResetService={onResetService}
                onReviewUpdate={onReviewUpdate}
                onStageChange={onStageChange}
                review={review}
                questions={questions}
                currentIndex={questions.length - 1}
                {...questionnaire[service].review}
              />
            </Questions>
            {showProgress && (
              <Progress
                currentIndex={questions.length - 1}
                completed={true}
                onStageChange={onStageChange}
                questions={questions}
                bid={bid}
                currentStageRef={currentStageRef}
                handleSaveAndNavigate={handleSaveAndNavigate}
              />
            )}
          </Form>
        </>
      )
    } else if (service === 'residential') {
      // Use the residential review component
      quickbid = (
        <>
          <ButtonGroup>
            <BackButton onClick={onBack} type={'button'}>
              Back
            </BackButton>
            <RestartButton onClick={onStartOver} type={'button'}>
              Start Over
            </RestartButton>
          </ButtonGroup>
          <Form
            id={`quickbid`}
            method={'post'}
            name={`quickbid`}
            onSubmit={onSubmit}
          >
            <Questions>
              <ResidentialReviewStage
                bid={bid}
                fields={questionnaire.residential.review.fields}
                id="residential-review"
                onResetService={onResetService}
                onReviewUpdate={onReviewUpdate}
                onStageChange={onStageChange}
                review={review}
                questions={questions}
                currentIndex={questions.length - 1}
              />
            </Questions>
            {showProgress && (
              <Progress
                currentIndex={questions.length - 1}
                completed={true}
                onStageChange={onStageChange}
                questions={questions}
                bid={bid}
                currentStageRef={currentStageRef}
                handleSaveAndNavigate={handleSaveAndNavigate}
              />
            )}
          </Form>
        </>
      )
    }
  }

  const title = existingBid ? `QuickBid: ${clientName}` : 'New QuickBid'

  const actionBar = (
    <ActionBar className="sticky-top">
      <div>
        <div style={{ color: 'white' }}>
          <div style={{ display: 'inline-block', marginRight: 10 }}>
            {existingBid ? (
              <Badge pill color="primary">
                {'Estimate ' + existingBid.code}
              </Badge>
            ) : (
              <Badge pill>QuickBid Draft</Badge>
            )}
          </div>
          {clientName && (
            <SpanLink
              onClick={() =>
                props.history.push(`/dashboard/client-details/${client._id}`)
              }
            >
              {clientName}
            </SpanLink>
          )}
          {existingLocationAddress && ' at '}
          {existingLocationAddress && (
            <SpanLink
              onClick={() =>
                props.history.push(
                  `/dashboard/location-details/${existingLocation._id}`
                )
              }
            >
              {existingLocationAddress}
            </SpanLink>
          )}
        </div>
      </div>

      <ActionButtons
        buttons={[
          ...(estimateId
            ? [
                {
                  component: (
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        block
                        style={{ textAlign: 'left' }}
                        color="secondary"
                      >
                        <FiTag /> Labels
                      </DropdownToggle>
                      <DropdownMenu right={true}>
                        <LabelEditor
                          data={labels}
                          onChange={async data => {
                            await updateQuickBid({
                              _id: estimateId,
                              labels: data
                            })
                          }}
                        />
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  )
                }
              ]
            : []),
          {
            title: 'Save QuickBid',
            onClick: () => onFinish(),
            disable: !completed && !(existingBid && !completed)
          }
        ]}
        options={[
          ...(existingBid && client
            ? [
                {
                  title: 'New Proposal',
                  onClick: () => {
                    updateQuickBid(_createProposal)
                  }
                }
              ]
            : []),
          ...(estimateId
            ? [
                {
                  title: 'Archive Estimate',
                  divide: true,
                  color: 'danger',
                  onClick: () =>
                    props.confirm(
                      'Confirm Archive',
                      'Are you sure you want to archive this estimate?',
                      () => onTrash()
                    )
                }
              ]
            : [])
        ]}
      />
    </ActionBar>
  )

  return (
    <>
      {actionBar}
      <DisclaimerAlert color="warning">
        <strong>⚠️ Important Disclaimer</strong>
        All calculations provided by this tool are preliminary estimates only and should not be considered final or binding.
        <ul>
          <li>Actual costs may vary significantly based on specific circumstances, market conditions, and requirements.</li>
          <li>All rates, prices, and calculations should be independently verified before making any business decisions.</li>
          <li>This tool provides general guidance only - you are responsible for ensuring all costs and profit margins are appropriate for your business.</li>
          <li>Please review all numbers carefully and adjust as needed for your specific situation.</li>
        </ul>
      </DisclaimerAlert>
      <div
        style={{ display: 'flex', flexDirection: 'row', padding: '0px 16px' }}
      >
        {labels &&
          labels.length > 0 &&
          labels.map(label => {
            return (
              <Label
                key={label._id}
                color={label.color}
                style={{ marginRight: 8 }}
              >
                {label.name}
              </Label>
            )
          })}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 200px'
        }}
      >
        <SelectClientLocationModal
          showModal={showLocationModal}
          toggle={() => setShowLocationModal(false)}
          onSubmit={onSubmit}
          notRequired={true}
          headerTitle="Select Location (optional)"
          submitText="Submit"
          backText="Cancel"
        />
        <Card body>{quickbid}</Card>
      </div>
    </>
  )
}

export default Calculator