import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Field,
  FieldArray,
  reduxForm,
  change,
  formValueSelector
} from 'redux-form'
import { required, image } from 'utils/validators'
import { capitalize } from 'utils'
import styled from 'styled-components'
import legal from 'data/legal.js'
import { Modal, ModalHeader, ModalBody, Button, Card } from 'reactstrap'
import { connectConfirmation } from 'components/confirm'
import FullScreenGallery from 'components/FullScreenGallery'
import LocationTypes from 'data/LocationTypes.json'
import { getServiceTemplates } from 'api'
import { IoMdRefreshCircle } from 'react-icons/io'

import _ from 'lodash'

import {
  ArrayCheckbox,
  CheckboxField,
  ColorPickerField,
  CropAndUploadField,
  DropdownField,
  ListInputField,
  InputField,
  MarkdownField,
  TabularField
} from 'components/fields'
import { CloseButton } from 'components/lib/Button'
import MediaImage from 'components/MediaImage'
import { daysOfWeek, ProposalFrequencies, TaskFrequencies } from 'data'
import Icon from 'components/icons'
import { isImage } from 'utils/imageHelpers'
import LazyImage from 'components/LazyImage'

const StyledRefreshButton = styled(Button)`
  &:hover svg {
    color: blue !important;
  }
`

const FormCard = styled.div`
  border: solid #ede7f6;
  padding: 10px 10px 0;
  margin-bottom: 10px;
  background-color: ${props => props.theme.colors.light1};
`

const LogoEditRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
`

const FormSection = styled(FormCard)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
`

const DaysCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 10px;
`

const DayCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

const Dimension = styled.span`
  padding: 0 6px;
`

const AreaItem = styled.div`
  margin-left: 36px;
  font-size: 0.8em;
  color: #666;
`

const ItemTitle = styled.span`
  color: #aaa;
`

const TaskInputContainer = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 16px;
`

const InputAndFrequency = styled.div`
  display: flex;
  flex-direction: row;
`

const NoteView = styled.div`
  background: rgba(0, 0, 0, 0.4);
  bottom: 110px;
  color: #fff;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
`

const RefreshButton = props => (
  <StyledRefreshButton close aria-label="Refresh" {...props}>
    <span aria-hidden>
      <IoMdRefreshCircle
        id="refresh-circle"
        size={22}
        style={{ color: '#999' }}
      />
    </span>
  </StyledRefreshButton>
)

const TasksField = ({ fields, frequencies, onChange }) => {
  const _handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      fields.push({})
    }
  }

  const _handleDeleteTask = index => {
    fields.remove(index)
    onChange()
  }

  const _handleAddTask = () => {
    fields.push({})
    onChange()
  }

  return (
    <TaskInputContainer>
      {fields.map((field, index) => (
        <InputAndFrequency key={index} id="taskContainer">
          <Field
            name={`${field}.name`}
            component={ListInputField}
            handleKeyDown={_handleKeyDown}
            onDeleteTask={() => _handleDeleteTask(index)}
            onAddTask={() => _handleAddTask()}
            type="text"
            placeholder="Press Enter to save"
            fieldsLength={fields.length}
            fieldIndex={index}
            isInputAndFrequency={true}
            onChange={onChange}
          />
          {!!frequencies && (
            <div style={{ width: 150 }}>
              <Field
                name={`${field}.frequency`}
                component={DropdownField}
                data={frequencies}
                filter={false}
                type="select"
                isInsideTaskInput={true}
                onChange={onChange}
              />
            </div>
          )}
        </InputAndFrequency>
      ))}
      {!fields.length && <Button onClick={() => fields.push({})}>Add</Button>}
    </TaskInputContainer>
  )
}

const TasksSubForm = ({ fields, onChange }) => {
  return (
    <div>
      {fields.map((field, index) => (
        <Card key={index} body>
          <div style={{ marginTop: -20, marginRight: -14 }}>
            <CloseButton
              onClick={() => {
                fields.remove(index)
                onChange()
              }}
            />
          </div>
          <Field
            name={`${field}.name`}
            placeholder="Task Section Name"
            component={InputField}
            onChange={onChange}
          />
          <FieldArray
            name={`${field}.tasks`}
            component={TasksField}
            frequencies={TaskFrequencies}
            onChange={onChange}
          />
        </Card>
      ))}
      <Button
        color="primary"
        onClick={() =>
          fields.push({
            tasks: [{}]
          })
        }
        style={{ marginBottom: 40 }}
      >
        Add Tasks Section
      </Button>
    </div>
  )
}

const EstimateChargesFields = ({
  fields,
  data,
  serviceList,
  originalCharges,
  change,
  onChange,
  formDisabled
}) => {
  return (
    <div>
      {fields.map((field, index) => {
        return (
          <Card key={index} body>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  maxWidth: '80%'
                }}
              >
                <Field
                  name={`${field}.showService`}
                  component={CheckboxField}
                  onChange={onChange}
                />
                <Field
                  name={`${field}.name`}
                  label="Service"
                  component={DropdownField}
                  data={serviceList}
                  style={{ minWidth: 200, marginRight: 24 }}
                  onChange={onChange}
                />
                <Field
                  name={`${field}.interval`}
                  label="Charge interval"
                  component={DropdownField}
                  data={['month', 'year', 'once']}
                  onChange={onChange}
                  fieldStyles={{ maxWidth: '40%' }}
                />
              </div>
              <div>
                <CloseButton
                  onClick={() => {
                    fields.remove(index)
                    onChange()
                  }}
                  style={{ marginBottom: 16 }}
                />
                {data && data[index] && data[index].id && (
                  <RefreshButton
                    onClick={() => {
                      change(
                        'Proposal',
                        field,
                        _.find(
                          originalCharges,
                          charge => charge.id === data[index].id
                        )
                      )
                      onChange()
                    }}
                  />
                )}
              </div>
            </div>
            {data && data[index] && data[index].showService && (
              <>
                <FieldArray
                  name={`${field}.charges`}
                  component={TabularField}
                  onChange={onChange}
                  columns={[
                    { name: 'name', placeholder: 'Line Item' },
                    {
                      name: 'price',
                      type: 'number',
                      placeholder: 'Price',
                      props: {
                        prepend: '$'
                      }
                    }
                  ]}
                />
              </>
            )}
          </Card>
        )
      })}
      <Button
        color="primary"
        onClick={() => {
          fields.push({
            showService: true,
            name: serviceList[0],
            interval: 'month',
            charges: [
              {
                name: 'Base Rate',
                price: 0
              },
              {
                name: 'Supplies and Materials',
                price: 0
              }
            ]
          })
          onChange()
        }}
        style={{ marginBottom: 40 }}
      >
        Add Service Charges
      </Button>
    </div>
  )
}

class ProposalForm extends Component {
  state = {
    showGallery: false,
    galleryImages: [],
    galleryStartIndex: 0
  }
  componentWillUnmount() {
    if (this.autosaveDelay) {
      clearTimeout(this.autosaveDelay)
    }
  }

  async componentDidMount() {
    await this.props.getServiceTemplates()
    let serviceNames = []
    this.props.services.forEach(service => {
      serviceNames.push(capitalize(service.name))
    })
    this.setState({
      serviceNames
    })
  }

  changeLegal = () => {
    let { change, client, clientLocation, currentCompany } = this.props

    let legalAgreement =
      `The Undersigned customer, **${client?.name || 'YOUR CUSTOMER NAME HERE'}** (hereafter referred to as "CUSTOMER") hereby accepts the proposal of **${currentCompany?.name || 'YOUR COMPANY NAME HERE'}** (hereafter referred to as "CONTRACTOR") for CUSTOMER's premises located at ***${clientLocation?.address?.fullAddress || 'YOUR CUSTOMER ADDRESS HERE'}***.\n\n` +
      'Upon the following terms:\n\n' +
      legal +
      '\n\n'

    change('Proposal', 'legal', legalAgreement)
    this._onChange()
  }

  _onClientLogoChange = value => {
    //First call regular change function
    this._onChange()
    const { client } = this.props
    //Confirm whether to update logo in backend or not
    this.props.confirm(
      'Confirm Update',
      `Would you like to also update client - "${client.name}" logo?`,
      () => this._updateClientInfo(value),
      'btn-success'
    )
  }
  _onMerchantLogoChange = value => {
    //First call regular change function
    this._onChange()
    const { currentCompany: company } = this.props

    //Confirm whether to update logo in backend or not
    this.props.confirm(
      'Confirm Update',
      `Would you like to also update merchant - "${company.name}" logo?`,
      () => this._updateMerchantInfo(value),
      'btn-success'
    )
  }

  _updateClientInfo = value => {
    //Save client information in database
    const { client } = this.props
    this._submitClient({ ...client, logo: value })
  }

  _submitClient = async client => await this.props.submitClient(client)

  _updateMerchantInfo = value => {
    //Save merchant information in database
    const { currentCompany: company } = this.props
    this._updateMerchant({ ...company, logo: value })
  }

  _updateMerchant = async merchant => {
    await this.props.updateCompany(merchant)
    await this.props.updateProfile()
  }

  _onChange = (newValue, oldValue, isMdField) => {
    if (!isMdField || (newValue !== oldValue && oldValue !== undefined)) {
      this.props.onProposalFormChange()
      if (this.autosaveDelay) {
        clearTimeout(this.autosaveDelay)
      }
      this.autosaveDelay = setTimeout(() => {
        this.props.onAutoSave()
      }, 3000)
    }
  }

  _onApplyJanitorialTemplate = () => {
    this.props.applyJanitorialTemplate()
    this._onChange()
  }

  _onApplyServiceTemplate = (service, fieldName) => {
    this.props.applyServiceTemplate(service, fieldName)
    this._onChange()
  }

  _openGallery = url => {
    const { job, clientLocation } = this.props
    let areas = (job && job.areas) || (clientLocation && clientLocation.areas)

    let galleryImages = []
    areas.forEach(area => {
      area.attachments
        .filter(file => isImage(file.type))
        .forEach((image, index) => {
          galleryImages.push({
            original: image.url,
            thumbnail: image.url,
            originalTitle: area.name,
            description: area.name + ' Image ' + (index + 1),
            note: image.notes
          })
        })
    })

    const startIndex = _.findIndex(galleryImages, { original: url })

    this.setState({
      showGallery: true,
      galleryImages,
      galleryStartIndex: startIndex > 0 ? startIndex : 0
    })
  }

  _customImageRenderer(item) {
    return (
      <div className="image-gallery-image">
        <LazyImage
          position="initial"
          src={item.original}
          alt={item.originalAlt}
          srcSet={item.srcSet}
          sizes={item.sizes}
        />
        {item.description && (
          <span className="image-gallery-description">
            {item.description}
            {item.link && item.linkLabel && (
              <a className="my-own-class" href={item.link}>
                {item.linkLabel}
              </a>
            )}
          </span>
        )}

        {item.note && (
          <NoteView>
            <strong>Note: </strong>
            {item.note}
          </NoteView>
        )}
      </div>
    )
  }

  _renderFile = (url, index) => {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" key={index}>
        <Icon
          size={64}
          icon="FaFilePdf"
          color="black"
          style={{ marginLeft: -5 }}
        />
      </a>
    )
  }

  _renderImage = (url, index) => {
    return (
      <MediaImage
        onClick={() => this._openGallery(url)}
        size={64}
        src={url}
        key={index}
      />
    )
  }

  additionalAreaOptions = () => {
    return (
      <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
        <Field
          name="includeAreaSqFt"
          component={CheckboxField}
          label="Include Square Footage"
          onChange={this._onChange}
        />
        <Field
          name="includeAreaImages"
          component={CheckboxField}
          label="Include Pictures"
          onChange={this._onChange}
        />
        <Field
          name="includeAreaNotes"
          component={CheckboxField}
          label="Include Notes"
          onChange={this._onChange}
        />
        <Field
          name="includeAreaFixtures"
          component={CheckboxField}
          label="Include Fixtures"
          onChange={this._onChange}
        />
      </div>
    )
  }

  _selectAllAreasToggled = (newValue, areas) => {
    const { change } = this.props
    let newAreasState = {}
    areas.map(area => {
      if (newValue) {
        return (newAreasState[`area${area._id}`] = true)
      } else {
        return (newAreasState[`area${area._id}`] = false)
      }
    })

    change('Proposal', 'areas', newAreasState)
  }

  render() {
    const {
      data,
      handleSubmit,
      job,
      client,
      clientLocation,
      showAreas,
      services,
      serviceTaskSelector,
      formDisabled
    } = this.props
    const { serviceNames } = this.state
    console.log('estimate data: ', data)
    let areas = (job && job.areas) || (clientLocation && clientLocation.areas)
    let fixtures = []
    let servicesWithTasks = _.filter(
      services,
      service => service.tasks && service.tasks.length
    )

    if (
      job ||
      (!job &&
        client &&
        clientLocation &&
        _.find(LocationTypes, type => type.name === clientLocation.type))
    ) {
      servicesWithTasks.unshift({
        name: 'Janitorial area template',
        addFunc: () => this._onApplyJanitorialTemplate()
      })
    }

    let fixturesGrouped = _.groupBy(
      _.flatten(_.map(areas, 'fixtures')),
      fixture => fixture.type.toLowerCase().trim()
    )

    let fixturesGroupedKeys = Object.keys(fixturesGrouped)
    fixturesGroupedKeys.forEach(key => {
      const quantity = fixturesGrouped[key].reduce(
        (accumulator, currentValue) =>
          accumulator + parseInt(currentValue.quantity, 10),
        0
      )
      fixtures.push({ key: _.capitalize(key), quantity })
    })

    fixtures = _.orderBy(fixtures, 'key', 'asc')

    let noAreasSelected
    let allAreasSelected
    let checkboxIndeterminate
    if (data && data.areas) {
      let selectedAreas = _.filter(data.areas, areaValue => areaValue === true)
      noAreasSelected = selectedAreas && selectedAreas.length === 0
      allAreasSelected = selectedAreas && selectedAreas.length === areas.length
      checkboxIndeterminate =
        selectedAreas &&
        selectedAreas.length > 0 &&
        selectedAreas.length < areas.length
    }

    const renderAreas = areas ? (
      <div style={{ padding: '0 12px 12px' }}>
        <Field
          name={`selectAllAreas`}
          component={CheckboxField}
          onChange={event => {
            this._selectAllAreasToggled(event.target.checked, areas)
            this._onChange()
          }}
          label="Select all areas"
          defaultChecked={
            allAreasSelected ? true : noAreasSelected ? false : null
          }
          indeterminate={checkboxIndeterminate}
        />
        <div style={{ padding: '0 12px 12px' }}>
          {areas.map((area, id) => {
            const areaDimensions =
              area.dimensions &&
              area.dimensions.set &&
              area.dimensions.set.map((dim, index) => (
                <Dimension key={index}>
                  {parseFloat(dim.width).toFixed(2)} ft. x{' '}
                  {parseFloat(dim.depth).toFixed(2)} ft.
                </Dimension>
              ))

            const imageAttachments = area.attachments.filter(d =>
              isImage(d.type)
            )

            const renderPhotos =
              imageAttachments.length < 6
                ? (imageAttachments || []).map((image, index) => {
                    return this._renderImage(image.url, index)
                  })
                : imageAttachments.slice(0, 5).map((image, index) => {
                    return this._renderImage(image.url, index)
                  })

            return (
              <div key={id}>
                <Field
                  name={`areas.area${area._id}`}
                  component={CheckboxField}
                  onChange={this._onChange}
                  label={
                    <>
                      {area.name}{' '}
                      <span style={{ color: '#999' }}>
                        (<i style={{ paddingRight: 2 }}>{area.type}</i>)
                      </span>
                    </>
                  }
                />
                {data &&
                  data.includeAreaSqFt &&
                  area.dimensions &&
                  area.dimensions.set &&
                  !!area.dimensions.set.length && (
                    <AreaItem>
                      <ItemTitle>FLOOR AREA: </ItemTitle>
                      {areaDimensions}
                      <strong>
                        {parseFloat(area.squareFootage).toFixed(2)} sq. ft.
                      </strong>
                    </AreaItem>
                  )}
                {area.floorType && (
                  <AreaItem>
                    <ItemTitle>FLOOR TYPE: </ItemTitle>
                    <span>{area.floorType}</span>
                  </AreaItem>
                )}
                {area.notes && (
                  <AreaItem>
                    <ItemTitle>NOTES: </ItemTitle>
                    <span>{area.notes}</span>
                  </AreaItem>
                )}
                {area.attachments && (
                  <AreaItem>
                    {renderPhotos}
                    {imageAttachments.length > 5 && (
                      <span>+ {area.attachments.length - 5} more</span>
                    )}
                  </AreaItem>
                )}
              </div>
            )
          })}
        </div>
      </div>
    ) : null

    const renderFixtures = fixtures ? (
      <div style={{ padding: '0 12px 12px' }}>
        {fixtures.map((fixture, id) => {
          return (
            <li key={id}>
              {fixture.key}{' '}
              <span style={{ color: '#999' }}>
                (<i style={{ paddingRight: 2 }}>{fixture.quantity}</i>)
              </span>
            </li>
          )
        })}
      </div>
    ) : null

    return (
      <form
        onSubmit={handleSubmit}
        style={formDisabled ? { pointerEvents: 'none', opacity: '0.7' } : {}}
      >
        <FormSection>
          <Field
            name="showHeaderSection"
            component={CheckboxField}
            label="Header"
            onChange={this._onChange}
          />
          <Field
            name="title"
            component={InputField}
            label="Title"
            onChange={this._onChange}
            autoComplete="off" // Doesn't really work but doesn't break anything either.
          />
          <Field
            name="headerColor"
            component={ColorPickerField}
            label="Header background color"
            props={{
              onChange: this._onChange
            }}
          />
          <Field
            name="headerTextColor"
            component={ColorPickerField}
            label="Header text color"
            props={{
              onChange: this._onChange
            }}
          />
          <LogoEditRow>
            <div style={{ flex: 1 }}>
              <Field
                name="showProviderLogo"
                component={CheckboxField}
                label="Your Logo"
                onChange={this._onChange}
              />

              <Field
                name="providerLogo"
                component={CropAndUploadField}
                type="file"
                multiple={false}
                validate={image}
                props={{
                  size: 105,
                  border: '2px dotted #2e3151'
                }}
                onChange={value => this._onMerchantLogoChange(value)}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Field
                name="showClientLogo"
                component={CheckboxField}
                label="Client Logo"
                onChange={this._onChange}
              />
              <Field
                name="clientLogo"
                component={CropAndUploadField}
                type="file"
                multiple={false}
                validate={image}
                props={{
                  size: 105,
                  border: '2px dotted #2e3151'
                }}
                onChange={value => this._onClientLogoChange(value)}
              />
            </div>
          </LogoEditRow>
          <Field
            name="clientAddress"
            component={InputField}
            label="Client Address"
            onChange={this._onChange}
          />
        </FormSection>
        <FormSection>
          <p style={{ marginBottom: 8 }}>Footer</p>
          <Field
            name="showPageNumbers"
            component={CheckboxField}
            label="Show page numbers"
            onChange={this._onChange}
            style={{ marginBottom: 8 }}
          />
        </FormSection>

        <FormSection>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 8
            }}
          >
            <div>
              <Field
                name="showEstimateCharges"
                component={CheckboxField}
                label="Charges"
                onChange={this._onChange}
              />
              <Field
                name="showChargesTotals"
                component={CheckboxField}
                label="Show Totals"
                onChange={this._onChange}
              />
            </div>
            <div style={{ display: 'flex', paddingTop: 8, paddingBottom: 8 }}>
              <Button
                size="sm"
                style={{ marginRight: 4 }}
                onClick={() => {
                  this.props.change('Proposal', 'estimateCharges', [])
                  this._onChange()
                }}
              >
                Clear
              </Button>
              <RefreshButton
                onClick={() => {
                  this.props.change(
                    'Proposal',
                    'estimateCharges',
                    data.estimateResults
                  )
                  this._onChange()
                }}
              />
            </div>
          </div>

          <FieldArray
            name="estimateCharges"
            data={(data && data.estimateCharges) || []}
            originalCharges={data && data.estimateResults}
            change={this.props.change}
            component={EstimateChargesFields}
            serviceList={serviceNames}
            onChange={this._onChange}
          />
        </FormSection>

        <FormSection>
          <Field
            name="showCharges"
            component={CheckboxField}
            label="Other Charges"
            onChange={this._onChange}
          />
          <Field
            name="charges"
            component={MarkdownField}
            type="textarea"
            height={'auto'}
            editorId="edit-charges"
            onChange={v => this._onChange(v, data.charges, true)}
          />
        </FormSection>
        <FormSection>
          <Field
            name="showFrequency"
            component={CheckboxField}
            label="Service Frequency"
            onChange={this._onChange}
          />

          <div>
            <Field
              name="serviceFrequency"
              component={DropdownField}
              data={ProposalFrequencies}
              filter={false}
              type="select"
              validate={required}
              warn={required}
              onChange={this._onChange}
            />
          </div>

          {data && data.serviceFrequency && data.serviceFrequency === 'Daily' && (
            <DaysCheckBox>
              {daysOfWeek.map((day, index) => {
                return (
                  <DayCheckBox key={index}>
                    <Field
                      name="selectedDays"
                      component={ArrayCheckbox}
                      itemValue={day}
                      abbreviate={true}
                      size="big"
                      onChange={this._onChange}
                    />
                  </DayCheckBox>
                )
              })}
            </DaysCheckBox>
          )}
          {data &&
            data.serviceFrequency &&
            data.serviceFrequency === 'Other' && (
              <Field
                name="frequencyDescription"
                component={InputField}
                type="textarea"
                validate={required}
                warn={required}
                placeholder="Type Description of Service Frequecy Here"
                onChange={this._onChange}
              />
            )}
        </FormSection>

        <FormSection>
          <Field
            name="showTaskSchedule"
            component={CheckboxField}
            label="Task Schedule"
            className="pull-left"
            onChange={this._onChange}
          />
          {servicesWithTasks && servicesWithTasks.length ? (
            <>
              <p style={{ margin: 0 }}>Apply task templates:</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 10
                }}
              >
                <Field
                  name={'serviceTaskSelector'}
                  data={servicesWithTasks}
                  component={DropdownField}
                  textField="name"
                  style={{ minWidth: 300 }}
                  isInsideTaskInput={true}
                />
                <Button
                  size="sm"
                  disabled={!serviceTaskSelector}
                  onClick={() =>
                    serviceTaskSelector && serviceTaskSelector.addFunc
                      ? serviceTaskSelector.addFunc()
                      : this._onApplyServiceTemplate(serviceTaskSelector)
                  }
                  style={{ width: 96, marginLeft: 8 }}
                >
                  Apply
                </Button>
              </div>
            </>
          ) : null}
          <FieldArray
            name={`taskSections`}
            component={TasksSubForm}
            onChange={this._onChange}
          />
        </FormSection>
        <FormSection>
          <Field
            name="showNotes"
            component={CheckboxField}
            label="Notes"
            onChange={this._onChange}
          />
          <Field
            name="notes"
            component={MarkdownField}
            type="textarea"
            height={'auto'}
            editorId="edit-notes"
            onChange={v => this._onChange(v, data.notes, true)}
          />
        </FormSection>
        {(job || (areas && areas.length > 0)) && (
          <FormSection>
            <Field
              name="showAreas"
              component={CheckboxField}
              label="Areas"
              onChange={this._onChange}
            />
            {showAreas ? this.additionalAreaOptions() : null}
            {renderAreas}
          </FormSection>
        )}

        {fixtures && fixtures.length ? (
          <FormSection>
            <Field
              name="showFixtureAggregate"
              component={CheckboxField}
              label="Fixtures"
              onChange={this._onChange}
            />
            {renderFixtures}
          </FormSection>
        ) : null}

        <FormSection>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 4
            }}
          >
            <Field
              name="showLegal"
              component={CheckboxField}
              label="Legal"
              onChange={this._onChange}
            />
            <Button color="primary" size="sm" onClick={this.changeLegal}>
              Use Default Legal
            </Button>
          </div>
          <Field
            name="legal"
            component={MarkdownField}
            type="textarea"
            height={'auto'}
            editorId="edit-legal"
            onChange={v => this._onChange(v, data.legal, true)}
          />
        </FormSection>

        <FormSection>
          <Field
            name="showAdditionalTerms"
            component={CheckboxField}
            label="Additional Terms"
            onChange={this._onChange}
          />
          <Field
            name="additionalTerms"
            component={MarkdownField}
            type="textarea"
            height={'auto'}
            editorId="edit-additional-terms"
            onChange={v => this._onChange(v, data.additionalTerms, true)}
          />
        </FormSection>
        <FormSection>
          <Field
            name="showSignature"
            component={CheckboxField}
            label="Signature"
            onChange={this._onChange}
          />
        </FormSection>

        <FullScreenGallery
          isOpen={this.state.showGallery}
          renderItem={this._customImageRenderer.bind(this)}
          items={this.state.galleryImages}
          startIndex={this.state.galleryStartIndex}
          onClose={() =>
            this.setState({
              showGallery: false
            })
          }
        />
      </form>
    )
  }
}

const selector = formValueSelector('Proposal')

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ change, getServiceTemplates }, dispatch)
})

const mapStateToProps = state => ({
  showAreas: selector(state, 'showAreas'),
  selectAllAreas: selector(state, 'selectAllAreas'),
  areaValues: selector(state, 'areas'),
  services: state.library.services,
  serviceTaskSelector: selector(state, 'serviceTaskSelector')
})

export default reduxForm({
  form: 'Proposal'
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(connectConfirmation(ProposalForm))
)
