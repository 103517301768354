/**
 * Utility function to safely download a file from a data blob
 * @param {Blob|ArrayBuffer} data - The file data as a Blob or ArrayBuffer
 * @param {string} filename - The name to give the downloaded file
 * @param {string} mime - The MIME type of the file (defaults to 'application/octet-stream')
 * @returns {boolean} - True if the download was initiated successfully, false otherwise
 */
const FileDownload = function(data, filename, mime) {
  // Track download state for success/failure reporting
  let downloadInitiated = false;
  
  try {
    // Input validation
    if (!data) {
      console.error('FileDownload: No data provided');
      return false;
    }

    if (!filename) {
      console.warn('FileDownload: No filename provided, using "download" as default');
      filename = 'download';
    }

    // Sanitize filename to prevent security issues and file system errors
    filename = filename.replace(/[\/\\:*?"<>|]/g, '_');
    
    // Ensure reasonable filename length for filesystem compatibility
    if (filename.length > 255) {
      const extension = filename.split('.').pop() || '';
      const baseName = filename.substring(0, 250 - extension.length - 1);
      filename = extension ? `${baseName}.${extension}` : baseName;
    }
    
    // Create a blob with the proper MIME type
    let blob;
    try {
      // Handle the case where data is already a Blob
      if (data instanceof Blob) {
        blob = mime ? new Blob([data], { type: mime }) : data;
      } else {
        // For other data types (ArrayBuffer, etc.), create a proper Blob
        // Validate MIME type for security
        const safeMime = mime && /^(application|text|image|audio|video)\/(pdf|octet-stream|plain|xml|json|jpeg|png|gif|svg\+xml|mp4|mpeg|mp3|webm|ogg)$/.test(mime.toLowerCase()) 
          ? mime 
          : 'application/octet-stream';
        
        blob = new Blob([data], { type: safeMime });
      }
      
      if (blob.size === 0) {
        console.error('FileDownload: Created blob has zero size');
        return false;
      }
    } catch (blobError) {
      console.error('FileDownload: Failed to create blob:', blobError);
      return false;
    }
    
    // Method 1: Internet Explorer msSaveBlob method
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      try {
        downloadInitiated = true;
        window.navigator.msSaveBlob(blob, filename);
        return true;
      } catch (ieSaveError) {
        console.error('FileDownload: IE msSaveBlob failed:', ieSaveError);
        // Fall through to try the standard method
        downloadInitiated = false;
      }
    } 
    
    // Method 2: Standard download with createObjectURL
    let blobURL;
    try {
      blobURL = window.URL.createObjectURL(blob);
    } catch (urlError) {
      console.error('FileDownload: Failed to create object URL:', urlError);
      return false;
    }
    
    // Create the anchor element for download
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    tempLink.setAttribute('rel', 'noopener noreferrer'); // Security best practice
    
    // Add data attributes for better security and debugging
    tempLink.setAttribute('data-downloadurl', `${blob.type}:${filename}:${blobURL}`);

    // Safari workaround for browsers that don't support the download attribute
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    // Add to DOM, trigger click, then remove
    try {
      document.body.appendChild(tempLink);
      
      // Use a timeout to ensure the browser has time to process
      setTimeout(() => {
        try {
          tempLink.click();
          downloadInitiated = true;
        } catch (clickError) {
          console.error('FileDownload: Click event failed:', clickError);
          
          // Firefox fallback for synthetic click blocking
          try {
            if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
              const event = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
              });
              tempLink.dispatchEvent(event);
              downloadInitiated = true;
            }
          } catch (firefoxError) {
            console.error('FileDownload: Firefox fallback failed:', firefoxError);
          }
        }
      }, 0);
      
      // Cleanup with delay to ensure browser processes the download
      setTimeout(() => {
        try {
          if (document.body.contains(tempLink)) {
            document.body.removeChild(tempLink);
          }
          
          if (blobURL) {
            window.URL.revokeObjectURL(blobURL);
          }
        } catch (cleanupError) {
          console.warn('FileDownload: Cleanup error (non-fatal):', cleanupError);
          // Non-fatal error - download may still work
        }
      }, 1000);
      
      return downloadInitiated || true; // Assume success if we got this far
    } catch (domError) {
      console.error('FileDownload: DOM operation failed:', domError);
      
      // Cleanup on error
      try {
        if (document.body.contains(tempLink)) {
          document.body.removeChild(tempLink);
        }
        
        if (blobURL) {
          window.URL.revokeObjectURL(blobURL);
        }
      } catch (cleanupError) { 
        console.warn('FileDownload: Cleanup after error failed:', cleanupError);
      }
      
      // Last resort: Try opening in a new window
      try {
        window.open(blobURL, '_blank');
        downloadInitiated = true;
        return true;
      } catch (windowError) {
        console.error('FileDownload: New window fallback failed:', windowError);
        return false;
      }
    }
  } catch (error) {
    console.error('FileDownload: Unhandled error:', error);
    return false;
  }
};

export default FileDownload;
