import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap'
import * as actions from 'api'
import DataScreen from 'components/DataScreen'
import LevelContainer from 'components/LevelContainer'
import { connectConfirmation } from 'components/confirm'
import DateWithTooltip from 'components/DateWithTooltip'
import LabelColumn from '../../components/LabelColumn'
import LogoDropZone from '../../components/LogoDropZone'
import ClientModal from 'screens/Dashboard/components/modals/ClientModal'
import { ActionButtons } from 'components/lib/Button'
import * as acl from 'utils/acl.service'
import FilterForm from '../../components/forms/FilterForm'
import Icon from 'components/icons'
import stopEventBubbling from 'utils/stopEventBubbling'
import { trackEvent } from 'api/clientHub'
import { BID_CLIENT_CREATE_CLIENT } from 'store/analyticsActions/types/clientTypes'
import BulkImportModal from '../../components/modals/BulkImport/BulkImportModal'

const ActionContainer = styled(LevelContainer)`
  margin-bottom: 20px;
`

class ClientList extends Component {
  state = {
    showClientModal: false,
    sortBy: 'name',
    showImportModal: false,
    isSubmitting: false,
    filterData: null,
    error: null
  }

  constructor(props) {
    super(props)
    this.dataScreen = React.createRef()
  }

  componentDidCatch(error, info) {
    this.setState({ error })
  }

  _onClientLogoDrop = (client, logo) => {
    if (!client || !logo) return

    const updatedClient = { ...client, logo }
    this.submitClient(updatedClient)
  }

  submitClient = async client => {
    if (this.state.isSubmitting) return

    this.setState({ isSubmitting: true, error: null })
    
    try {
      const resp = await this.props.submitClient(client)

      if (this.dataScreen.current) {
        this.dataScreen.current.forceFetch()
      }
      
      return resp
    } catch (error) {
      this.setState({ error: error.message || 'Failed to submit client' })
    } finally {
      this.setState({ isSubmitting: false })
    }
  }

  _selectClient = client => {
    if (!client || !client._id) return
    this.props.history.push(`/dashboard/client-details/${client._id}`)
  }

  _toggleClientModal = () => {
    this.setState(prevState => ({
      showClientModal: !prevState.showClientModal
    }))
  }

  _toggleImportModal = () => {
    this.setState(prevState => ({
      showImportModal: !prevState.showImportModal
    }))
  }

  _saveClient = async values => {
    if (!values) return
    
    // Set active to true for new clients (those without an _id)
    const clientData = values._id ? values : { ...values, active: true }
    
    const resp = await this.submitClient(clientData)
    
    if (resp) {
      this._toggleClientModal()
    }
  }

  _showArchivedClients = () => {
    this.props.history.push('/dashboard/clients/archived/')
  }

  _toggleActive = client => {
    if (!client) return
    
    const newClient = {
      ...client,
      active: !client.active
    }

    this.submitClient(newClient)
  }

  render() {
    const { error } = this.state

    if (error) {
      return (
        <Container fluid>
          <div className="alert alert-danger">
            An error occurred: {error}
            <button 
              className="btn btn-link" 
              onClick={() => this.setState({ error: null })}>
              Dismiss
            </button>
          </div>
        </Container>
      )
    }

    const columns = [
      {
        header: 'Labels',
        width: '5%',
        render: item => {
          return item && item._id ? <LabelColumn id={item._id} labels={item.labels} /> : null
        }
      },
      {
        header: '',
        width: '5%',
        render: client => (
          <LogoDropZone
            value={client?.logo}
            onDrop={logo => this._onClientLogoDrop(client, logo)}
          />
        )
      },
      { header: 'Name', accessor: 'name', sortable: true, width: '60%' },
      {
        header: 'Created',
        width: '20%',
        render: item => {
          if (!item) return null
          const { _id, createdAt } = item
          return <DateWithTooltip id={_id} date={createdAt} />
        },
        sortField: 'createdAt',
        sortable: true
      },
      {
        header: 'Options',
        width: '10%',
        render: item => (
          <UncontrolledDropdown>
            <DropdownToggle
              color="link"
              onClick={stopEventBubbling}
            >
              <Icon icon="IoIosMore" color="white" />
            </DropdownToggle>
            <DropdownMenu right>
              {item.active ? (
                <DropdownItem
                  onClick={e => {
                    stopEventBubbling(e)
                    this._toggleActive(item)
                  }}
                >
                  Archive client
                </DropdownItem>
              ) : (
                <DropdownItem
                  onClick={e => {
                    stopEventBubbling(e)
                    this._toggleActive(item)
                  }}
                >
                  Activate client
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      }
    ]

    return (
      <Container fluid style={{ marginTop: 20 }}>
        <ClientModal
          isOpen={this.state.showClientModal}
          toggle={this._toggleClientModal}
          submitClient={this._saveClient}
          isSubmitting={this.state.isSubmitting}
        />
        <BulkImportModal 
          importType="client" 
          isOpen={this.state.showImportModal} 
          toggle={this._toggleImportModal} 
        />
        <ActionContainer>
          <div />
          <div>
            <FilterForm
              applyFilters={data =>
                this.setState({
                  filterData: data
                })
              }
              clientFilters
            />
            <ActionButtons
              buttons={[
                {
                  title: 'Add Client',
                  onClick: this._toggleClientModal,
                  disabled: this.state.isSubmitting
                }
              ]}
              options={[
                ...(acl.isAllowed('clients', 'archivedList')
                  ? [
                    {
                      title: 'See archived clients',
                      onClick: this._showArchivedClients
                    }
                  ]
                  : [])
              ]}
            />
          </div>
        </ActionContainer>
        <DataScreen
          ref={this.dataScreen}
          entity="Client"
          url={`/api/clients/find?active=true`}
          onRowClick={this._selectClient}
          columns={columns}
          filterData={this.state.filterData}
        />
      </Container>
    )
  }
}

ClientList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  submitClient: PropTypes.func.isRequired
}

export default connect(null, actions)(connectConfirmation(ClientList))
