import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Container } from 'reactstrap'
import * as actions from 'api'
import LevelContainer from 'components/LevelContainer'
import { connectConfirmation } from 'components/confirm'
import DateWithTooltip from 'components/DateWithTooltip'
import { ActionButtons } from 'components/lib/Button'
import DataScreen from 'components/DataScreen'
import LocationModal from 'screens/Dashboard/components/modals/LocationModal'
import LabelColumn from '../../components/LabelColumn'
import { fullAddress } from 'utils'
import FilterForm from '../../components/forms/FilterForm'
import BulkImportModal from '../../components/modals/BulkImport/BulkImportModal'
import _ from 'lodash'

const ActionContainer = styled(LevelContainer)`
  margin-bottom: 20px;
`

class LocationList extends Component {
  state = {
    showLocationModal: false,
    showImportModal: false,
  }

  constructor(props) {
    super(props)
    this.dataScreen = React.createRef()
  }

  _openLocation = id => {
    this.props.history.push(`/dashboard/location-details/${id}`)
  }

  _toggleLocationModal = () => {
    this.setState({
      showLocationModal: !this.state.showLocationModal
    })
  }

  _toggleImportModal = () => {
    const { showImportModal } = this.state
    this.setState({
      showImportModal: !showImportModal
    })
  }

  _refreshLocations = () => {
    if (this.dataScreen.current) {
      this.dataScreen.current.forceFetch()
    }
  }

  _saveLocation = async values => {
    await this.props.submitLocation(values)
    this._toggleLocationModal()
    if (this.dataScreen.current) {
      this.dataScreen.current.forceFetch()
    }
  }

  __showArchivedLocations = () => {

    console.log(this.props)
    this.props.history.push("/dashboard/ShowLocationsArchived/")
    // this.props.history.push('/dashboard/proposals/archived/')

  }

  toggleSideBar = () => this.setState({
    showSideBar: !this.state.showSideBar
  })

  render() {
    const columns = [
      {
        header: 'Labels',
        width: '5%',
        render: item => {
          return (
            <LabelColumn id={item._id} labels={item.labels} />
          )
        }
      },
      {
        header: 'Address',
        render: item => item.address && fullAddress(item.address),
        sortField: 'address.formattedAddress',
        sortable: true
      },
      {
        header: 'Type',
        accessor: 'type',
        sortable: true
      },
      { header: 'Client', accessor: 'client.name', sortable: true },
      {
        header: 'Created',
        render: item => {
          const { _id, createdAt } = item
          if (!createdAt) {
            return ''
          }
          return <DateWithTooltip id={_id} date={createdAt} />
        },
        sortable: true,
        sortField: 'createdAt'
      }
    ]

    return (
      <Container fluid style={{ marginTop: 20 }}>
        <LocationModal
          submitLocation={this._saveLocation}
          isOpen={this.state.showLocationModal}
          toggle={this._toggleLocationModal}
        />
        <BulkImportModal 
          importType="location" 
          isOpen={this.state.showImportModal} 
          toggle={this._toggleImportModal} 
          onImportComplete={this._refreshLocations}
        />
        <ActionContainer>
          <div />
          <div>
            <FilterForm
              applyFilters={data =>
                this.setState({
                  filterData: data
                })
              }
              locationFilters
            />
            <ActionButtons
              buttons={[
                {
                  title: 'Add Location',
                  onClick: this._toggleLocationModal
                }
              ]}
              options={[
                {
                  title: 'Import Locations',
                  onClick: this._toggleImportModal
                },
                {
                  title: 'See archived locations',
                  onClick: this.__showArchivedLocations
                }
              ]}
            />
          </div>
        </ActionContainer>
        <DataScreen
          ref={this.dataScreen}
          filterData={this.state.filterData}
          url="/api/locations/find"
          entity="Location"
          columns={columns}
          onRowClick={location => this._openLocation(location._id)}
        />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  company: state.user.currentCompany,
  currentEmployment: state.user.currentEmployment
})

export default connect(
  mapStateToProps,
  actions
)(connectConfirmation(LocationList))
