import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactDOMServer from 'react-dom/server'
import FileDownload from '../../Dashboard/Proposals/components/FileDownload'

import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Button, ButtonGroup, Spinner } from 'reactstrap'
import { isMobile } from 'react-device-detect'

import { handlerErrors } from 'utils'
import { axiosClient } from 'store'
import { applyPlaceholders } from 'utils/autofill'
import { cleanPdfContent } from 'utils/pdfUtils'
import { downloadProposalPdf, handleProposalDownload } from 'utils/proposalPdfUtils'

import Elements from 'screens/Dashboard/Proposals/Proposal2/Elements'
import SignatureForm from '../../Dashboard/components/forms/SignatureForm'

const initialState = {
  submitError: null
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${!isMobile ? 'padding-bottom: 20px;' : null}
`

const ScrollableContainer = styled.div`
  max-height: calc(100vh - ${isMobile ? '50px' : '60px'});
  overflow-y: auto;
  overflow-x: hidden;
`

const ProposalContainer = styled.div`
  background-color: #fff;
  margin: 0;
  padding: 20px;
  max-width: 768px;
`

const ElementPreview = styled.div`
  position: relative;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${isMobile ? '5px' : '20px'} 0;
  width: 90%;
  max-width: 768px;
`

const ProposalReview = ({ match, history }) => {
  const [proposal, setProposal] = useState(null)
  const [signatureFormOpen, setSignatureFormOpen] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const signatureToken = match.params?.signatureToken
  let clientType = (proposal != undefined) ? proposal.client.type : ''
  let clientAccessToken = (proposal != undefined) ? proposal.signatureData.clientAccessToken : ''


  if (!match.params.proposalId || !signatureToken) {
    return <Redirect to="/" />
  }

  useEffect(() => {
    const fetchProposal = async () => {
      const result = await axiosClient.get(`/api/proposals/${match.params.proposalId}/proposalPortal`)
      if (result.error) {
        let message = handlerErrors(result.error, 'Proposal could not be found.')
        toast(message)
        return
      }
      const proposalRes = result?.data
      const clientAccessToken = proposalRes?.signatureData?.clientAccessToken

      if (signatureToken === clientAccessToken) {
        setProposal(proposalRes)
        if (!proposalRes?.viewed) {
          const viewedRes = await axiosClient.patch(`/api/proposals/${proposalRes._id}/proposalViewed/${clientAccessToken}`)
          setProposal(viewedRes?.data)
        }
        return
      }

      toast('Invalid access token.')
      history.push('/')
      return
    }
    fetchProposal()
  }, [])

  const _submitSignature = async (signature) => {
    const data = {
      accessToken: signatureToken,
      signature
    }

    const result = await axiosClient.patch(`/api/proposals/${proposal._id}/clientSigned`, data)

    if (result.error) {
      let message = handlerErrors(result.error, 'There was an error submitting signature.')
      toast(message)
      return
    }

    toast('Thank you! Your signed proposal has been received and the company has been notified.')
    setProposal(result?.data)
    setSignatureFormOpen(false)
    // Update the UI to show the signed proposal instead of redirecting
  }

  /**
   * Handles downloading the proposal as a PDF for client review
   * Shows appropriate toast notifications for success or failure
   */
  const _downloadFile = async () => {
    // Prevent multiple clicks/downloads
    if (isDownloading) return;
    
    // Validate we have required data
    if (!proposal || !proposal?.data?.elements) {
      toast.error('Invalid proposal data. Cannot generate PDF.');
      return;
    }
    
    try {
      // Call the utility function with the client-specific parameters
      await handleProposalDownload(
        proposal,
        {
          setIsLoading: setIsDownloading,
          onError: (error) => {
            console.error('Download error:', error);
          }
        },
        {
          // Client-specific download options
          type: clientType,
          clientAccessToken: clientAccessToken
        }
      );
    } catch (error) {
      // Final fallback error handling
      console.error('Unhandled PDF download error:', error);
      setIsDownloading(false);
      toast.error('An unexpected error occurred. Please try again.');
    }
  };

  const _renderElementContent = (element, index) => {
    const master = Elements.find(e => e.type === element.type)
    if (master && master.render) {
      const renderProps = { element, proposal, renderForPdf: true }
      return master.render(renderProps)
    }
    return <div />
  }

  const processedElements = proposal?.data?.elements
    ? proposal.data.elements.map(e => {
        if (e.type === 'rich_text' && e.data?.markdown) {
          return {
            ...e,
            data: { ...e.data, markdown: applyPlaceholders(e.data.markdown, proposal) }
          };
        }
        return e;
      })
    : [];

  const _renderElement = (element, index) => {
    const master = Elements.find(e => e.type === element.type);
    if (master && master.render) {
      let renderElement = element;
      const renderProps = { element: renderElement, proposal, renderForPdf: false };
      if (element.type === 'page_break' || element.type === 'areas') {
        renderProps.renderForPdf = true;
      } else if (element.type === 'scope_of_work') {
        renderProps.renderForPdf = true;
        // Remove spacer from task sections so that spacer is not displayed in client view
        renderElement = { ...element };
        if (renderElement.data && Array.isArray(renderElement.data.taskSections)) {
          renderElement.data = {
            ...renderElement.data,
            taskSections: renderElement.data.taskSections.map(section => {
              const { spacer, ...rest } = section;
              return rest;
            })
          };
        }
        renderProps.element = renderElement;
      }
      return master.render(renderProps);
    }
    return <div />;
  }

  const toggleSignatureForm = () => {
    setSignatureFormOpen(!signatureFormOpen)
  }

  const alreadySigned = proposal?.signatureData?.clientSignature

  return (
    <PageContainer className="full-height">
      {signatureFormOpen ? (
        <SignatureForm
          isOpen={signatureFormOpen}
          toggle={toggleSignatureForm}
          onSubmitSignature={_submitSignature}
          proposalId={proposal?._id}
          accessToken={signatureToken}
        />
      ) : null}
      <ButtonContainer>
        <ButtonGroup>
          {proposal && !alreadySigned ? (
            <Button onClick={toggleSignatureForm} style={{ width: 150 }} color='primary'>
              Sign
            </Button>
          ) : null}
          {!isMobile ? (
            <Button onClick={_downloadFile} disabled={isDownloading}>
              {isDownloading ? (
                <>
                  <Spinner size="sm" className="mr-2" /> Generating PDF...
                </>
              ) : 'Download PDF'}
            </Button>
          ) : null}
        </ButtonGroup>
      </ButtonContainer>
      <ScrollableContainer>
        <ProposalContainer className='proposal-new'>
          {processedElements.map((item, index) =>
            _renderElement(item, index)
          )}
        </ProposalContainer>
      </ScrollableContainer>
    </PageContainer>
  )
}

export default ProposalReview
