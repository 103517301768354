import React from 'react'
import { Card, CardBody } from 'reactstrap'
export default () => (
  <Card>
    <CardBody>
      <p style={{ margin: 0 }}>
        This element creates a page break in the generated proposal PDF.
      </p>
      <p style={{ margin: '8px 0 0 0', fontStyle: 'italic' }}>
        No configuration options are available for page breaks.
      </p>
    </CardBody>
  </Card>
)