import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import styled from 'styled-components'

import Field from '../field'
import { PrimaryButton, Question, Title, Tooltip } from '../ui'
import { InputState } from '../../../../../../hooks'
import { browserWindow } from '../../../../../../utils'

export const AddonPart = styled.div`
  margin-top: 1rem;
  width: 100%;

  fieldset {
    text-align: left;

    input,
    label,
    select {
      display: inline-flex;
      font-size: 1.25rem;
    }

    input {
      margin-right: 0;
      width: 128px;
    }

    label {
      width: calc(100% - 144px);
    }
  }

  @media screen and (min-width: 32rem) {
    width: 80%;
  }
`

export const ResidentialAddonsStage = forwardRef(({
  current,
  fields,
  id,
  label,
  onClick
}, ref) => {
  useEffect(() => {
    browserWindow.scrollTo(0, 0)
  }, [])

  const addons = current || {}

  const onNext = event => {
    // Convert all addon values to numbers before submitting
    const numericAddons = {};
    
    // Convert each addon value to a proper number
    Object.keys(addons).forEach(key => {
      numericAddons[key] = typeof addons[key] === 'string' 
        ? parseFloat(addons[key]) 
        : addons[key];
    });
    
    onClick(event, numericAddons);
  }

  // Expose getValue method to parent through ref
  useImperativeHandle(ref, () => ({
    getValue: () => {
      // Also ensure we return numeric values here
      const numericAddons = {};
      Object.keys(addons).forEach(key => {
        numericAddons[key] = typeof addons[key] === 'string' 
          ? parseFloat(addons[key]) 
          : addons[key];
      });
      return numericAddons;
    }
  }))

  const inputs = fields.map(({ label, name, options, type }, index) => {
    const [value, setValue] = InputState(addons[name] || 0)
    
    // Store as number, not string
    addons[name] = typeof value === 'string' ? parseFloat(value) : value

    return (
      <AddonPart key={name}>
        <Field
          id={name}
          label={label}
          labelStyle={{}}
          name={`${id}-${name}`}
          onChange={setValue}
          options={options}
          style={{}}
          type={type}
          value={value}
        />
      </AddonPart>
    )
  })

  return (
    <Question>
      <Tooltip>
        Optional services that you can include as extra charges for your client.
        <br />
        Remember to count interior and exterior windows by the pane.
      </Tooltip>
      <Title style={{ marginTop: '3rem' }}>{label}</Title>
      {inputs}

      <PrimaryButton onClick={onNext} type={'button'}>
        Next
      </PrimaryButton>
    </Question>
  )
})

export default ResidentialAddonsStage
