import { useState, useEffect, memo, useRef, useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { ItemRow, ItemValueContainer, COLUMN_CONFIG, StyledDropdown, FieldLabel } from './ListStyles';
import { HintText, COLORS, SPACING } from '../ImportContentStyles';
import styled from 'styled-components';

const RequiredIndicator = styled.span`
  color: ${COLORS.danger};
  font-weight: 600;
  margin-left: ${SPACING.xs};
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: ${SPACING.sm} ${SPACING.md};
  padding-right: 30px;
  border: 1px solid ${props => props.hasError ? COLORS.danger : COLORS.borderDark};
  border-radius: 6px;
  font-size: 14px;
  background-color: ${COLORS.white};
  transition: all 0.2s ease;

  &:focus {
    border-color: ${props => props.hasError ? COLORS.danger : COLORS.primary};
    box-shadow: 0 0 0 3px ${props => props.hasError ? 'rgba(220, 53, 69, 0.1)' : 'rgba(68, 92, 233, 0.1)'};
    outline: none;
  }

  ${props => props.hasValue && !props.hasError && `
    background-color: ${COLORS.secondaryLight};
    border-color: ${COLORS.primary};
  `}

  &:disabled {
    background-color: ${COLORS.secondaryLight};
    cursor: not-allowed;
  }
`;

const ClientDropdown = styled.div`
  position: fixed;
  max-height: 200px;
  width: ${props => props.width || '250px'};
  overflow-y: auto;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.borderDark};
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  z-index: 9999;
`;

const ClientOption = styled.div`
  padding: ${SPACING.sm} ${SPACING.md};
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${COLORS.secondaryLight};
  }

  ${props => props.selected && `
    background-color: ${COLORS.secondaryLight};
    font-weight: 500;
  `}
`;

const ClearButton = styled.button`
  position: absolute;
  right: ${SPACING.sm};
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: ${COLORS.secondary};
  cursor: pointer;
  padding: ${SPACING.xs};
  font-size: 14px;
  
  &:hover {
    color: ${COLORS.secondaryDark};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const HighlightedText = styled.span`
  background-color: ${props => props.highlight ? COLORS.warningLight : 'transparent'};
  padding: ${props => props.highlight ? `0 ${SPACING.xs}` : '0'};
  border-radius: 2px;
`;

export const LocationImportItem = memo(({ item, index, updateItem, clients, isAlternate }) => {
  const [selectedClient, setSelectedClient] = useState(
    clients?.find(c => c._id === item.client) || null
  );
  const [localSearchQuery, setLocalSearchQuery] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);
  
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);
  const inputId = `client-search-${index}`;
  
  // Initialize client name when component mounts or client changes
  useEffect(() => {
    if (item.client) {
      const client = clients?.find(c => c._id === item.client);
      if (client) {
        setSelectedClient(client);
        setLocalSearchQuery(client.name);
      } else {
        setSelectedClient(null);
        setLocalSearchQuery('');
      }
    } else {
      setSelectedClient(null);
      setLocalSearchQuery('');
    }
  }, [item.client, clients, index]);
  
  // Handle clicking outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        wrapperRef.current && 
        !wrapperRef.current.contains(event.target) &&
        !document.querySelector('.client-dropdown-portal')?.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);
  
  // Simple memoized filter for clients based on search query
  const filteredClients = useMemo(() => {
    if (!clients || !Array.isArray(clients)) return [];
    
    let filtered = clients;
    if (localSearchQuery.trim()) {
      const query = localSearchQuery.toLowerCase().trim();
      filtered = clients.filter(client => 
        client.name.toLowerCase().includes(query)
      );
    }
    
    // Sort by match quality (exact matches first)
    return filtered.slice(0, 100).sort((a, b) => {
      const aStartsWith = a.name.toLowerCase().startsWith(localSearchQuery.toLowerCase());
      const bStartsWith = b.name.toLowerCase().startsWith(localSearchQuery.toLowerCase());
      
      if (aStartsWith && !bStartsWith) return -1;
      if (!aStartsWith && bStartsWith) return 1;
      return a.name.localeCompare(b.name);
    });
  }, [clients, localSearchQuery]);
  
  // Calculate dropdown position
  const updateDropdownPosition = useCallback(() => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY + 5,
        left: rect.left + window.scrollX,
        width: rect.width
      });
    }
  }, []);

  const handleSelectClient = (client) => {
    // Update local component state immediately
    setSelectedClient(client);
    setLocalSearchQuery(client.name);
    setShowDropdown(false);
    setIsUpdating(true);
    
    try {
      // Create a deep copy of the item to avoid reference issues
      const updatedItem = JSON.parse(JSON.stringify(item));
      
      // Explicitly set the client ID as a string
      updatedItem.client = String(client._id);
      
      // Explicitly preserve validation state to prevent items from moving back to rejected list
      // and mark the item as fixed
      updatedItem.invalidAddress = false;
      updatedItem.missingAddress = false;
      updatedItem.missingLocationName = false;
      updatedItem.missingLocationType = false;
      updatedItem.invalidLocationType = false;
      updatedItem.error = null;
      updatedItem.fixed = true; // Add a permanent marker that this item has been fixed
      
      // Set data attribute immediately for easier debugging
      if (inputRef.current) {
        inputRef.current.dataset.clientAssigned = 'true';
        inputRef.current.dataset.clientId = client._id;
        inputRef.current.dataset.fixed = 'true';
      }
      
      // Update the item in the parent component
      updateItem(updatedItem, index);
    } catch (err) {
      console.error(`Error updating client:`, err);
      // Reset local state if update fails
      setSelectedClient(null);
      setLocalSearchQuery('');
      
      // Reset data attribute
      if (inputRef.current) {
        inputRef.current.dataset.clientAssigned = 'false';
        delete inputRef.current.dataset.clientId;
        delete inputRef.current.dataset.fixed;
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const handleClear = (e) => {
    e.stopPropagation();
    setError(null);
    setSelectedClient(null);
    setLocalSearchQuery('');
    setIsUpdating(true);
    
    try {
      const updatedItem = { ...item, client: null };
      
      // Update data attribute immediately
      if (inputRef.current) {
        inputRef.current.dataset.clientAssigned = 'false';
        delete inputRef.current.dataset.clientId;
      }
      
      if (typeof updateItem === 'function') {
        updateItem(updatedItem, index);
      } else {
        setError('Cannot clear client. Please try again or reload the page.');
      }
    } catch (err) {
      setError('Error clearing client: ' + (err.message || 'Unknown error'));
    } finally {
      setIsUpdating(false);
    }
  };

  const handleFocus = () => {
    if (!isUpdating) {
      setShowDropdown(true);
      updateDropdownPosition();
    }
  };

  const handleInputChange = (e) => {
    setLocalSearchQuery(e.target.value);
    setShowDropdown(true);
    updateDropdownPosition();
  };
  
  const handleInputClick = () => {
    if (!isUpdating) {
      setShowDropdown(true);
      updateDropdownPosition();
    }
  };

  const highlightText = (text, query) => {
    if (!query) return text;
    try {
      const parts = text.split(new RegExp(`(${query})`, 'gi'));
      return parts.map((part, i) => 
        part.toLowerCase() === query.toLowerCase() ? 
          <HighlightedText key={i} highlight>{part}</HighlightedText> : 
          <span key={i}>{part}</span>
      );
    } catch (e) {
      return text;
    }
  };

  // Create dropdown portal content
  let dropdownContent = null;
  if (showDropdown && filteredClients.length > 0) {
    const dropdownElement = (
      <ClientDropdown 
        className="client-dropdown-portal"
        style={{
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
          width: `${dropdownPosition.width}px`
        }}
        role="listbox"
        aria-labelledby={inputId}
      >
        {filteredClients.length > 0 ? (
          filteredClients.map((client) => (
            <ClientOption
              key={client._id}
              selected={client._id === selectedClient?._id}
              onClick={() => handleSelectClient(client)}
              role="option"
              aria-selected={client._id === selectedClient?._id}
            >
              {highlightText(client.name, localSearchQuery)}
            </ClientOption>
          ))
        ) : (
          <ClientOption 
            style={{ cursor: 'default', color: COLORS.secondary }}
            role="option"
          >
            No clients found
          </ClientOption>
        )}
      </ClientDropdown>
    );
    dropdownContent = createPortal(dropdownElement, document.body);
  }

  return (
    <ItemRow isAlternate={isAlternate}>
      <ItemValueContainer width={COLUMN_CONFIG.location[0].width} style={{ textAlign: 'left' }}>
        {item.locationName}
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.location[1].width} style={{ textAlign: 'left' }}>
        {item.address?.formattedAddress || 
          <HintText color={COLORS.danger}>Invalid address</HintText>
        }
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.location[2].width} style={{ textAlign: 'left' }}>
        {item.locationType || 
          <HintText color={COLORS.danger}>Invalid type</HintText>
        }
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.location[3].width} style={{ textAlign: 'left' }}>
        {item.contact?.phone || '-'}
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.location[4].width} style={{ textAlign: 'left' }}>
        <SelectWrapper ref={wrapperRef}>
          <SearchInput
            id={inputId}
            ref={inputRef}
            type="text"
            placeholder={!item.client ? "Search for client... (Required *)" : "Search for client..."}
            value={localSearchQuery}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onClick={handleInputClick}
            hasValue={!!selectedClient}
            hasError={!item.client}
            disabled={isUpdating}
            autoComplete="off"
            aria-label={`Select client for location ${item.locationName}`}
            aria-expanded={showDropdown}
            aria-autocomplete="list"
            aria-controls={showDropdown ? "client-dropdown-list" : undefined}
            aria-required="true"
            aria-invalid={!item.client}
          />
          {selectedClient && (
            <ClearButton 
              onClick={handleClear}
              disabled={isUpdating}
              aria-label="Clear selected client"
            >
              ×
            </ClearButton>
          )}
          {dropdownContent}
        </SelectWrapper>
        {error && (
          <HintText color={COLORS.danger} block>
            {error}
          </HintText>
        )}
      </ItemValueContainer>
    </ItemRow>
  );
});