import React, { forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'

import { Button, Question, Title } from '../ui'

const FrequencyButton = styled(Button)`
  background-color: transparent;
  color: #0b64eb;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 1.5rem;
  padding: 0;
`

const Options = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
`

export const ResidentialRecurringStage = forwardRef(({ label, onClick, options, current }, ref) => {
  // Expose getValue method to parent through ref
  useImperativeHandle(ref, () => ({
    getValue: () => current || '' // Ensure we return an empty string if current is undefined
  }))

  return (
    <Question>
      <Title>{label}</Title>

      <Options>
        {options.map(option => (
          <FrequencyButton
            key={option}
            name={`frequency-${option
              .replace(/\s+/g, '-')
              .replace('/', '-')
              .toLowerCase()}`}
            onClick={event => onClick(event, option)}
            type={'button'}
          >
            {option}
          </FrequencyButton>
        ))}
      </Options>
    </Question>
  )
})

export default ResidentialRecurringStage
