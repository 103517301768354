import { useState, useMemo, memo, useCallback } from 'react';
import { Button } from 'reactstrap';
import { ItemRow, ItemValueContainer, ErrorMessageText, COLUMN_CONFIG } from './ListStyles';
import GooglePlaceInput from '../../../../../../../components/GooglePlaceInput';
import { LocationTypeArray } from '../../../../../../../data';
import { COLORS } from '../ImportContentStyles';
import styled from 'styled-components';

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FormField = styled.div`
  border: 1px solid ${props => props.error ? COLORS.danger : '#d9d9d9'};
  border-radius: 5px;
  padding: ${props => props.noPadding ? '0' : '8px 10px'};
  background-color: ${props => props.error ? 'rgba(220, 53, 69, 0.05)' : 'white'};
  transition: all 0.2s;
  height: 38px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  
  &:focus-within {
    border-color: ${props => props.error ? COLORS.danger : COLORS.primary};
    box-shadow: 0 0 0 3px ${props => props.error ? 'rgba(220, 53, 69, 0.1)' : 'rgba(68, 92, 233, 0.1)'};
  }
`;

const ErrorIcon = styled.span`
  color: ${COLORS.danger};
  margin-right: 5px;
`;

const FieldErrorText = styled(ErrorMessageText)`
  margin-top: 3px;
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 0 10px;
  border: none;
  background-color: transparent;
  font-size: 14px;
  height: 22px;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23444%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 10px;
  
  &:focus {
    outline: none;
  }
  
  &::-ms-expand {
    display: none;
  }
  
  /* These styles may help on some browsers */
  color: ${props => props.value === '' ? '#aaa' : 'inherit'};
`;

const StatusText = styled.div`
  font-size: 12px;
  color: ${props => props.error ? COLORS.danger : COLORS.secondary};
  margin-top: 4px;
`;

const ActionButton = styled(Button)`
  width: 100%;
  margin-top: ${props => props.hasError ? '4px' : '0'};
  background-color: ${props => props.saving ? COLORS.secondary : COLORS.primary};
  border-color: ${props => props.saving ? COLORS.secondary : COLORS.primary};
  transition: all 0.2s;
  
  &:hover:not(:disabled) {
    background-color: ${props => props.saving ? COLORS.secondary : COLORS.primaryDark};
    border-color: ${props => props.saving ? COLORS.secondary : COLORS.primaryDark};
  }
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
`;

const ActionContainer = styled(ItemValueContainer)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 15% !important;  
`;

// Add this styled component for consistent text padding
const TextContent = styled.div`
  padding: 0 10px;
  box-sizing: border-box;
`;

// Add SelectFormField to remove extra padding
const SelectFormField = styled(FormField)`
  padding: 0;
`;

export const ClientRejectedItem = memo(({ item, updateItem, index, isAlternate, recordPendingEdit }) => {
  const [itemAddress, setItemAddress] = useState(item.address);
  const [itemLocationType, setItemLocationType] = useState(item.locationType);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationError, setValidationError] = useState(item.error || '');
  
  const locationTypes = useMemo(() => LocationTypeArray, []);
  
  const disableSave = !itemAddress || !itemLocationType || isSubmitting;
  const randId = Math.random().toString(36).substring(2, 8);
  
  const handleSave = useCallback(async () => {
    if (disableSave) return;
    
    try {
      setIsSubmitting(true);
      setValidationError('');
      
      // Make sure we have all required fields
      if (!item.clientName) {
        setValidationError('Client name is required');
        setIsSubmitting(false);
        return;
      }
      
      if (!itemAddress) {
        setValidationError('Address is required');
        setIsSubmitting(false);
        return;
      }
      
      if (!itemLocationType) {
        setValidationError('Location type is required');
        setIsSubmitting(false);
        return;
      }
      
      const updatedItem = {
        ...item,
        address: itemAddress,
        locationType: itemLocationType,
        // Clear error flags
        error: null,
        invalidAddress: false,
        missingAddress: false,
        missingLocationType: false,
        invalidLocationType: false
      };
      
      if (typeof updateItem === 'function') {
        updateItem(updatedItem, index);
      } else {
        console.error('updateItem is not a function');
        setValidationError('Could not update item. Please try again.');
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error('Error saving client:', error);
      setValidationError(error.message || 'Error saving data');
      setIsSubmitting(false);
    }
  }, [item, itemAddress, itemLocationType, updateItem, index, disableSave]);

  const handleLocationTypeChange = (e) => {
    const newValue = e.target.value;
    setItemLocationType(newValue);
    setValidationError('');
    
    // Record this change for bulk save functionality
    if (recordPendingEdit) {
      recordPendingEdit(index, 'locationType', newValue);
    }
  };

  const handleAddressChange = (value) => {
    console.log('Address changed:', value);
    if (value && typeof value === 'object') {
      // Make sure we're getting a valid address object
      setItemAddress(value);
      setValidationError('');
      
      // Record this change for bulk save functionality
      if (recordPendingEdit) {
        recordPendingEdit(index, 'address', value);
      }
    } else {
      // If we get a string or other invalid value, log it but don't update
      console.warn('Received invalid address value:', value);
    }
  };

  const handleClientNameChange = (e) => {
    const newValue = e.target.value;
    setItemClientName(newValue);
    setValidationError('');
    
    // Record this change for bulk save functionality
    if (recordPendingEdit) {
      recordPendingEdit(index, 'clientName', newValue);
    }
  };

  const handleLocationNameChange = (e) => {
    const newValue = e.target.value;
    setItemLocationName(newValue);
    setValidationError('');
    
    // Record this change for bulk save functionality
    if (recordPendingEdit) {
      recordPendingEdit(index, 'locationName', newValue);
    }
  };

  // Determine error states for individual fields
  const hasAddressError = (!itemAddress || item.invalidAddress || item.missingAddress);
  const hasLocationTypeError = (!itemLocationType || item.invalidLocationType || item.missingLocationType);

  return (
    <ItemRow isAlternate={isAlternate}>
      <ItemValueContainer width={COLUMN_CONFIG.client[0].width} style={{ textAlign: 'left' }}>
        <TextContent>{item.clientName}</TextContent>
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.client[1].width} style={{ textAlign: 'left' }}>
        {item.address && !item.invalidAddress && !item.missingAddress ? (
          <TextContent>{item.address.formattedAddress}</TextContent>
        ) : (
          <InputWrapper>
            {/* Display the original address if the address was autocorrected */}
            {item.autocorrectedAddress && item.originalAddress && (
              <FieldErrorText>
                <ErrorIcon>⚠️</ErrorIcon>
                Original: {item.originalAddress}
              </FieldErrorText>
            )}
            <FormField error={hasAddressError} noPadding>
              <GooglePlaceInput
                value={item.displayAddress || ''}
                onChange={handleAddressChange}
                placeholder={item.invalidAddress ? "Address is invalid or not found" : "Enter address"}
                inputId={`address${randId}`}
                disabled={isSubmitting}
              />
            </FormField>
            {hasAddressError && !item.autocorrectedAddress && (
              <FieldErrorText>
                <ErrorIcon>⚠</ErrorIcon>
                {item.invalidAddress ? "Address is invalid" : "Address is required"}
              </FieldErrorText>
            )}
          </InputWrapper>
        )}
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.client[2].width} style={{ textAlign: 'left' }}>
        {item.locationType && !item.missingLocationType && !item.invalidLocationType ? (
          <TextContent>{item.locationType}</TextContent>
        ) : (
          <InputWrapper>
            <SelectFormField error={hasLocationTypeError}>
              <StyledSelect
                value={itemLocationType || ''}
                onChange={handleLocationTypeChange}
                disabled={isSubmitting}
              >
                <option value="" disabled>Select type</option>
                {locationTypes.map((type, idx) => (
                  <option key={idx} value={type}>
                    {type}
                  </option>
                ))}
              </StyledSelect>
            </SelectFormField>
            {hasLocationTypeError && (
              <FieldErrorText>
                <ErrorIcon>⚠</ErrorIcon>
                {item.invalidLocationType ? "Invalid location type" : "Location type is required"}
              </FieldErrorText>
            )}
          </InputWrapper>
        )}
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.client[3].width} style={{ textAlign: 'left' }}>
        <TextContent>{item.locationName || item.clientName}</TextContent>
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.client[4].width} style={{ textAlign: 'left' }}>
        <TextContent>{item.contact?.name || '-'}</TextContent>
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.client[5].width} style={{ textAlign: 'left' }}>
        <TextContent>{item.contact?.phone || '-'}</TextContent>
      </ItemValueContainer>

      <ItemValueContainer width={COLUMN_CONFIG.client[6].width} style={{ textAlign: 'left' }}>
        <TextContent>{item.contact?.email || '-'}</TextContent>
      </ItemValueContainer>

      <ActionContainer width="15%" style={{ textAlign: 'left' }}>
        {validationError && validationError !== item.error && (
          <StatusText error>{validationError}</StatusText>
        )}
        <ActionButton 
          onClick={handleSave} 
          disabled={disableSave}
          size="sm"
          saving={isSubmitting}
          hasError={validationError && validationError !== item.error}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </ActionButton>
      </ActionContainer>
    </ItemRow>
  );
});