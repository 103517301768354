import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { HeaderRow, ItemValueContainer, ListBox, COLUMN_CONFIG, FieldLabel } from './ListStyles';
import { UploadText, HintText, COLORS, SPACING } from '../ImportContentStyles';
import { ClientImportItem } from './ClientImportItem';
import { LocationImportItem } from './LocationImportItem';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const ReadyToImportContainer = styled.div`
  margin-top: ${SPACING.lg};
  padding: ${SPACING.sm};
  border: 1px solid ${COLORS.primary}20;
  border-radius: 8px;
  background-color: ${COLORS.primary}05;
`;

const ImportHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${SPACING.sm};
  padding: 0 ${SPACING.sm};
`;

const ImportTitle = styled.h3`
  color: ${COLORS.primary};
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  
  svg {
    margin-right: ${SPACING.xs};
  }
`;

const ImportDescription = styled.p`
  color: ${COLORS.secondary};
  font-size: 14px;
  margin: 0 0 ${SPACING.sm} 0;
  padding: 0 ${SPACING.sm};
`;

const BulkActionHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${SPACING.sm};
  padding: ${SPACING.md};
  background-color: ${COLORS.secondaryLight};
  border-radius: 6px;
  margin-bottom: ${SPACING.sm};
`;

const ClientSelectContainer = styled.div`
  position: relative;
  width: 350px;
  flex-grow: 1;
  max-width: 500px;
  z-index: 20; /* Higher z-index than individual rows */
`;

const SearchInput = styled.input`
  width: 100%;
  padding: ${SPACING.sm} ${SPACING.md};
  padding-right: 30px;
  border: 1px solid ${COLORS.borderDark};
  border-radius: 6px;
  font-size: 14px;
  background-color: ${COLORS.white};
  transition: all 0.2s ease;

  &:focus {
    border-color: ${COLORS.primary};
    box-shadow: 0 0 0 3px rgba(68, 92, 233, 0.1);
    outline: none;
  }

  ${props => props.hasValue && `
    background-color: ${COLORS.secondaryLight};
    border-color: ${COLORS.primary};
  `}

  &:disabled {
    background-color: ${COLORS.secondaryLight};
    cursor: not-allowed;
  }
`;

const ClientDropdown = styled.div`
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.borderDark};
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  z-index: 9999;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 2px;
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: ${COLORS.secondaryLight};
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${COLORS.borderDark};
    border-radius: 4px;
  }
`;

const ClientOption = styled.div`
  padding: ${SPACING.sm} ${SPACING.md};
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${COLORS.secondaryLight};
  }

  ${props => props.selected && `
    background-color: ${COLORS.secondaryLight};
    font-weight: 500;
  `}
`;

const ClearButton = styled.button`
  position: absolute;
  right: ${SPACING.sm};
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: ${COLORS.secondary};
  cursor: pointer;
  padding: ${SPACING.xs};
  font-size: 14px;
  
  &:hover {
    color: ${COLORS.secondaryDark};
  }
`;

const HighlightedText = styled.span`
  background-color: ${props => props.highlight ? COLORS.warningLight : 'transparent'};
  padding: ${props => props.highlight ? `0 ${SPACING.xs}` : '0'};
  border-radius: 2px;
`;

const WarningIndicator = styled.span`
  color: ${COLORS.danger};
  margin-left: ${SPACING.sm};
  font-weight: 500;
`;

export const ImportFinalList = ({ importType, data, updateItem, clients }) => {
  const [selectedBulkClient, setSelectedBulkClient] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);
  const columns = COLUMN_CONFIG[importType];
  
  const locationsWithoutClient = useMemo(() => {
    if (importType !== 'location') return 0;
    return data.filter(item => !item.client).length;
  }, [data, importType]);

  const filteredClients = useMemo(() => {
    if (!clients || !clients.length) return [];
    if (!searchQuery.trim()) return clients.slice(0, 100); // Limit to 100 clients if no search query
    
    return clients.filter(client => 
      client.name.toLowerCase().includes(searchQuery.toLowerCase())
    ).slice(0, 100); // Limit to 100 matching clients
  }, [clients, searchQuery]);

  // Handle outside clicks to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        wrapperRef.current && 
        !wrapperRef.current.contains(event.target) &&
        !event.target.closest('.client-dropdown')
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    setShowDropdown(true);
  };
  
  // Focus handler
  const handleFocus = () => {
    if (!isSubmitting) {
      setShowDropdown(true);
    }
  };

  const handleClearBulkSelection = () => {
    setSelectedBulkClient('');
    setSearchQuery('');
    setShowDropdown(false);
  };

  const highlightText = (text, query) => {
    if (!query) return text;
    
    try {
      const parts = text.split(new RegExp(`(${query})`, 'gi'));
      return parts.map((part, i) => 
        part.toLowerCase() === query.toLowerCase() ? 
          <HighlightedText key={i} highlight>{part}</HighlightedText> : 
          part
      );
    } catch (e) {
      return text;
    }
  };

  const handleBulkClientSelect = (clientId, clientName) => {
    // Update local state
    setSelectedBulkClient(clientId);
    setSearchQuery(clientName);
    setShowDropdown(false);
    
    setIsSubmitting(true);
    
    try {
      if (data && data.length > 0) {
        // Create a new array of updated items
        const updatedItems = data.map(item => {
          if (!item.error) {
            // Preserve any existing validation flags when updating
            return {
              ...item,
              client: clientId,
              // Explicitly preserve validation state to prevent items from moving back to rejected list
              invalidAddress: false,
              missingAddress: false,
              missingLocationName: false,
              missingLocationType: false,
              invalidLocationType: false,
              error: null,
              fixed: true // Mark as fixed to ensure persistence
            };
          }
          return item;
        });
        
        // Update all items at once using the updateItem callback
        // Use a small delay between updates to prevent race conditions
        updatedItems.forEach((item, idx) => {
          if (item.client === clientId) {
            // Use setTimeout to stagger updates and reduce UI freezing
            setTimeout(() => {
              updateItem(item, idx);
            }, idx * 10); // Increase delay to 10ms for more stability
          }
        });
        
        // Show success message
        toast.success(`Assigned ${clientName} to all locations`);
      }
    } catch (error) {
      console.error("Error assigning client to all locations:", error);
      toast.error("Failed to assign client to all locations");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <ReadyToImportContainer>
      <ImportHeader>
        <ImportTitle>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
          </svg>
          Ready to Import ({data.length})
        </ImportTitle>
      </ImportHeader>
      
      <ImportDescription>
        The following items are valid and ready to be imported.
        {importType === 'location' && ' Please assign each location to a client before importing.'}
      </ImportDescription>

      {importType === 'location' && clients && clients.length > 0 && (
        <BulkActionHeader>
          <FieldLabel>Assign a client to all locations:</FieldLabel>
          <ClientSelectContainer ref={wrapperRef}>
            <SearchInput
              ref={inputRef}
              value={searchQuery}
              onChange={handleInputChange}
              onFocus={handleFocus}
              placeholder="Search for a client..."
              disabled={isSubmitting}
              hasValue={!!searchQuery}
              aria-label="Search for a client to assign to all locations"
            />
            {searchQuery && (
              <ClearButton onClick={handleClearBulkSelection} aria-label="Clear selection">
                ✕
              </ClearButton>
            )}
            {showDropdown && (
              <ClientDropdown 
                className="client-dropdown"
              >
                {filteredClients.length === 0 ? (
                  <ClientOption>
                    No clients found. Type to search...
                  </ClientOption>
                ) : (
                  filteredClients.map(client => (
                    <ClientOption
                      key={client._id}
                      onClick={() => handleBulkClientSelect(client._id, client.name)}
                      selected={selectedBulkClient === client._id}
                    >
                      {highlightText(client.name, searchQuery)}
                    </ClientOption>
                  ))
                )}
              </ClientDropdown>
            )}
          </ClientSelectContainer>
          
          {locationsWithoutClient > 0 && (
            <UploadText style={{ margin: 0 }}>
              <WarningIndicator>
                {locationsWithoutClient} location{locationsWithoutClient !== 1 ? 's' : ''} without assigned client
              </WarningIndicator>
            </UploadText>
          )}
        </BulkActionHeader>
      )}

      <HeaderRow>
        {columns.map((col, index) => (
          !col.hideInFinal && (
            <ItemValueContainer key={index} width={col.width} style={{ textAlign: 'left' }}>
              {col.name}
            </ItemValueContainer>
          )
        ))}
      </HeaderRow>
      <ListBox allowScroll={data.length > 5}>
        {data.map((item, index) => (
          importType === 'location' ? (
            <LocationImportItem 
              key={item._id || `location-${index}`} 
              item={item} 
              updateItem={updateItem}
              clients={clients}
              index={index}
              isAlternate={index % 2 !== 0}
            />
          ) : (
            <ClientImportItem 
              key={item._id || `client-${index}`} 
              item={item} 
              index={index} 
              isAlternate={index % 2 !== 0}
            />
          )
        ))}
      </ListBox>
    </ReadyToImportContainer>
  );
};