import React, { forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'

import Field from '../field'
import { PrimaryButton, Question, Tooltip } from '../ui'
import { places } from '../../data'
import { InputState } from '../../../../../../hooks'

const Part = styled.div`
  margin-top: 1.5rem;
  width: 100%;

  input,
  label,
  select {
    display: inline-flex;
    font-size: 1.25rem;
    text-align: left;
  }

  input {
    margin-left: 0.5rem;
    width: 128px;
  }

  select {
    margin-left: 0.5rem;
    width: 192px;
  }

  @media screen and (min-width: 32rem) {
    input,
    select {
      width: auto;
    }
  }
`

const sizes = places.reduce(
  (all, { name, size }) => ({ ...all, [name]: size }),
  {}
)

export const CommercialLocationStage = forwardRef(({
  fields,
  location,
  onClick,
  onLocationChange
}, ref) => {
  let disabled = false
  let defaultSize = sizes[location.type]

  const onNext = event => onClick(event, location)

  // Expose getValue method to parent through ref
  useImperativeHandle(ref, () => ({
    getValue: () => location
  }))

  const inputs = fields.map(
    ({ id, label, name, placeholder, options, required, type }, index) => {
      let startingValue = location[name] || ``

      if (
        name === 'size' &&
        parseInt(startingValue, 0) === parseInt(defaultSize, 0)
      ) {
        startingValue = defaultSize
      }

      const [value, setValue] = InputState(startingValue)

      const isValid = value.trim().length > 0
      if (!isValid) {
        disabled = true
      }

      const isDefaultLocationSize = value =>
        Object.keys(sizes).some(
          size => parseInt(sizes[size], 0) === parseInt(sizes[value], 0)
        )

      const onChange = event => {
        setValue(event)

        const updates = {
          ...location,
          [name]: event.target.value
        }

        if (isDefaultLocationSize(event.target.value)) {
          updates.size = sizes[event.target.value]
        }

        onLocationChange(updates)
      }

      return (
        <Part key={name}>
          <Field
            id={id}
            label={label}
            labelStyle={{}}
            name={name}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            required={required}
            style={{}}
            type={type}
            value={value}
          />
        </Part>
      )
    }
  )

  return (
    <Question>
      <Tooltip>
        The location type can affect the standard of care required for cleaning
        which impacts cleaning times. The QuickBid calculator accounts for these
        details based on the location type you select. For increased accuracy,
        please enter the square footage of the location.
      </Tooltip>
      {inputs}

      <PrimaryButton disabled={disabled} onClick={onNext} type={'button'}>
        Next
      </PrimaryButton>
    </Question>
  )
})

export default CommercialLocationStage
