import React, { useState } from 'react'
import { 
  UploadTitle, 
  UploadText, 
  DropZoneStyle, 
  ImportSubmitButton, 
  SampleDownloadLink,
  InstructionItem,
  FormatNote,
  SubsectionTitle,
  HintText,
  ContentSection,
  COLORS,
  SPACING,
  FlexContainer,
  LocationTypeChip
} from './ImportContentStyles'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import ClientBulkSample from 'assets/files/ClientBulkSample.csv'
import LocationBulkSample from 'assets/files/LocationBulkSample.csv'
import { Alert, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { LocationTypes } from 'data'
import styled from 'styled-components'

// Extract just the location type names from the LocationTypes array
const locationTypeNames = LocationTypes.map(type => type.name).sort()

const IMPORT_CONFIG = {
  client: {
    title: 'Upload Clients',
    description: 'Upload your list of clients as a CSV file. Each client will have an initial location created.',
    requiredFields: ['Client Name', 'Address', 'Location Type'],
    optionalFields: ['Location Name', 'Contact Name', 'Contact Phone', 'Contact Email'],
    template: ClientBulkSample,
    templateName: 'Client Template (CSV)'
  },
  location: {
    title: 'Upload Locations',
    description: 'Upload your list of locations as a CSV file. You will be able to assign clients after upload.',
    requiredFields: ['Location Name', 'Address', 'Location Type'],
    optionalFields: ['Contact Phone'],
    template: LocationBulkSample,
    templateName: 'Location Template (CSV)'
  }
}

const LocationTypesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: ${SPACING.sm};
  margin-top: ${SPACING.md};
  margin-bottom: ${SPACING.md};
`;

const ImportFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${SPACING.md};
  margin-bottom: ${SPACING.lg};
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  padding-top: ${SPACING.md};
`;

const InstructionCard = styled.div`
  background-color: ${COLORS.primaryLight};
  border-radius: 8px;
  padding: ${SPACING.md};
  height: 100%;
`;

const StepNumber = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${COLORS.primary};
  color: white;
  font-weight: bold;
  font-size: 12px;
  margin-right: ${SPACING.xs};
`;

const InstructionStep = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${SPACING.sm};
  font-size: 14px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const StepContent = styled.div`
  flex: 1;
`;

const TypesLink = styled.button`
  background: none;
  border: none;
  color: ${COLORS.primary};
  font-weight: 500;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
  
  &:hover {
    color: ${COLORS.primaryDark};
  }
`;

export const ImportForm = ({ importType, onUpload }) => {
  const [file, setFile] = React.useState(null);
  const [uploadError, setUploadError] = React.useState(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [typesModalOpen, setTypesModalOpen] = useState(false);
  const config = IMPORT_CONFIG[importType];
  
  const _onUpload = async () => {
    if (!file) {
      return;
    }
    
    setUploadError(null);
    setIsUploading(true);
    
    try {
      await onUpload(file);
    } catch (error) {
      setUploadError(error.message || 'Error uploading file. Please try again.');
      setIsUploading(false);
    }
  }

  const handleChangeStatus = ({ file }, status) => {
    if (status === 'done') {
      if (file) {
        // Validate file size - max 2MB
        if (file.size > 2 * 1024 * 1024) {
          setUploadError('File size exceeds the 2MB limit');
          setFile(null);
          return;
        }
        
        // Validate file extension
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (fileExtension !== 'csv') {
          setUploadError('Only CSV files are accepted');
          setFile(null);
          return;
        }
        
        setFile(file);
        setUploadError(null);
      }
    } else if (status === 'rejected_file_type') {
      setUploadError('Only CSV files are accepted');
      setFile(null);
    } else if (status === 'error_file_size') {
      setUploadError('File size exceeds the 2MB limit');
      setFile(null);
    } else if (status === 'removed') {
      setFile(null);
      setUploadError(null);
    }
  }

  const toggleTypesModal = () => {
    setTypesModalOpen(!typesModalOpen);
  }

  return (
    <ImportFormContainer>
      <ContentSection style={{ marginBottom: SPACING.md }}>
        <UploadTitle>{config.title}</UploadTitle>
        <UploadText>{config.description}</UploadText>
      </ContentSection>

      <TwoColumnLayout>
        <div>
          <InstructionCard>
            <SubsectionTitle mt="0" style={{ marginBottom: SPACING.md }}>How to import {importType}s</SubsectionTitle>
            
            <InstructionStep>
              <StepNumber>1</StepNumber>
              <StepContent>
                <strong>Download the template</strong> 
                <div>
                  <SampleDownloadLink href={config.template} download style={{ marginTop: SPACING.xs, display: 'inline-block' }}>
                    Download {config.templateName}
                  </SampleDownloadLink>
                </div>
              </StepContent>
            </InstructionStep>
            
            <InstructionStep>
              <StepNumber>2</StepNumber>
              <StepContent>
                <strong>Fill in your data</strong>
                <div>
                  Required fields: {config.requiredFields.map((field, i) => 
                    field === 'Location Type' ? (
                      <React.Fragment key={field}>
                        {i > 0 && ', '}
                        <TypesLink type="button" onClick={toggleTypesModal}>
                          Location Type
                        </TypesLink>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={field}>
                        {i > 0 && ', '}
                        {field}
                      </React.Fragment>
                    )
                  )}
                </div>
                {config.optionalFields.length > 0 && (
                  <div>Optional fields: {config.optionalFields.join(', ')}</div>
                )}
              </StepContent>
            </InstructionStep>
            
            <InstructionStep>
              <StepNumber>3</StepNumber>
              <StepContent>
                <strong>Upload your CSV file</strong>
                <div>Maximum 200 items per upload (2MB max file size)</div>
              </StepContent>
            </InstructionStep>
            
            {importType === 'location' && (
              <InstructionStep>
                <StepNumber>4</StepNumber>
                <StepContent>
                  <strong>Assign Clients (next screen)</strong>
                  <div>After upload, you'll assign clients to each location</div>
                </StepContent>
              </InstructionStep>
            )}
          </InstructionCard>
        </div>
      
        <div>
          <FormatNote style={{ marginBottom: SPACING.md }}>
            <strong>Tips for preparing your CSV:</strong>
            <ul style={{ marginTop: SPACING.xs, paddingLeft: '20px' }}>
              <li>Use Excel or Google Sheets to edit the template</li>
              <li>Save or export as CSV format</li>
              <li>
                Ensure <TypesLink onClick={toggleTypesModal}>Location Type</TypesLink> matches exactly from the available options
              </li>
              <li>Include all required fields for each row</li>
            </ul>
          </FormatNote>

          <Dropzone
            styles={{
              ...DropZoneStyle,
              dropzone: {
                ...DropZoneStyle.dropzone,
                border: `2px dashed ${uploadError ? COLORS.danger : COLORS.primary}`,
                backgroundColor: uploadError ? 'rgba(210, 75, 75, 0.05)' : COLORS.secondaryLight,
                maxHeight: '160px',
              }
            }}
            onChangeStatus={handleChangeStatus}
            accept=".csv"
            maxSizeBytes={2 * 1024 * 1024} // 2MB max file size
            inputContent={(
              <div>
                <p style={{ marginBottom: SPACING.xs, fontWeight: 500 }}>Drop CSV file here or click to browse</p>
                <HintText color={COLORS.secondary}>Only .csv files are accepted (max 2MB)</HintText>
              </div>
            )}
            maxFiles={1}
            disabled={isUploading}
          />

          {uploadError && (
            <Alert color="danger" style={{ marginTop: SPACING.sm, borderRadius: '6px', fontSize: '14px' }}>
              <strong>Error:</strong> {uploadError}
            </Alert>
          )}
        </div>
      </TwoColumnLayout>
      
      <FooterContainer>
        <ImportSubmitButton 
          color="primary" 
          onClick={_onUpload} 
          disabled={!file || isUploading}
        >
          {isUploading ? 'Processing...' : 'Continue'}
        </ImportSubmitButton>
      </FooterContainer>

      <Modal
        isOpen={typesModalOpen}
        toggle={toggleTypesModal}
        className="location-types-modal"
        backdrop={true}
        centered
        zIndex={1050}
      >
        <ModalHeader toggle={toggleTypesModal}>
          Available Location Types
        </ModalHeader>
        <ModalBody>
          <HintText color={COLORS.danger} fw="500" block style={{ marginBottom: SPACING.sm }}>
            ⚠️ Location Type in your CSV must match exactly as shown (case-sensitive)
          </HintText>
          <LocationTypesGrid>
            {locationTypeNames.map(type => (
              <LocationTypeChip key={type}>{type}</LocationTypeChip>
            ))}
          </LocationTypesGrid>
        </ModalBody>
      </Modal>
    </ImportFormContainer>
  )
}