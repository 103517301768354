/**
 * Converts a Blob or File object to a base64 data URL
 * 
 * This function takes a Blob or File object and converts it to a base64-encoded
 * data URL using the FileReader API. It includes proper error handling, memory
 * management, and timeout checks for large files.
 * 
 * @param {Blob|File} inputBlob - The Blob or File to convert
 * @param {Object} [options] - Optional configuration parameters
 * @param {number} [options.timeout=30000] - Timeout in milliseconds before aborting (default: 30 seconds)
 * @param {number} [options.maxSize=25000000] - Maximum file size in bytes (default: 25MB)
 * @returns {Promise<string>} - Promise resolving to a base64 data URL
 * @throws {Error} If conversion fails, timeout occurs, or file is too large
 */
export const blobToBase64 = (inputBlob, options = {}) => {
  // Validate input
  if (!inputBlob || !(inputBlob instanceof Blob)) {
    return Promise.reject(new Error("Invalid input: must provide a valid Blob or File object"));
  }

  // Default options
  const {
    timeout = 30000, // 30 seconds
    maxSize = 25000000, // 25MB
  } = options;

  // Check file size to prevent massive memory usage
  if (inputBlob.size > maxSize) {
    return Promise.reject(
      new Error(`File size exceeds maximum allowed size (${Math.round(maxSize/1000000)}MB)`)
    );
  }

  // Create a new FileReader
  const fileReader = new FileReader();
  let timeoutId = null;

  return new Promise((resolve, reject) => {
    // Set up timeout to prevent hanging
    timeoutId = setTimeout(() => {
      fileReader.abort();
      reject(new Error(`Conversion timed out after ${timeout}ms`));
    }, timeout);

    // Error handler
    fileReader.onerror = () => {
      clearTimeout(timeoutId);
      fileReader.abort();
      reject(new Error("Failed to read file: " + (fileReader.error?.message || "Unknown error")));
    };

    // Abort handler
    fileReader.onabort = () => {
      clearTimeout(timeoutId);
      reject(new Error("File reading was aborted"));
    };

    // Success handler
    fileReader.onload = () => {
      clearTimeout(timeoutId);
      if (fileReader.result) {
        resolve(fileReader.result);
      } else {
        reject(new Error("File read succeeded but produced no data"));
      }
    };

    // Start reading the file
    try {
      fileReader.readAsDataURL(inputBlob);
    } catch (error) {
      clearTimeout(timeoutId);
      reject(new Error(`Error initiating file read: ${error.message || "Unknown error"}`));
    }
  });
};
