import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { GoogleApiWrapper } from '../../../../../utils/GoogleApiComponent'
import { ImportForm, FinalizeImport, ImportBusy } from './ImportModalContent'
import { usePapaParse } from 'react-papaparse';
import { useParseAndValidateImport } from './useParseAndValidateImport';
import { connectConfirmation } from 'components/confirm'
import { MdClose } from 'react-icons/md';
import styled, { createGlobalStyle } from 'styled-components';
import { 
  COLORS, 
  spinnerKeyframes, 
  ImportSubmitButton, 
  UploadText,
  SPACING
} from './ImportModalContent/ImportContentStyles';

// Add global style for spinner animation
const GlobalStyle = createGlobalStyle`
  ${spinnerKeyframes}
`;

// Standardized Modal Header styling
const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  border-bottom: 1px solid #e9ecef;
`;

const ModalTitle = styled.h4`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #445CE9;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #f0f2ff;
  }
`;

// Define a footer component similar to the header
const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #e9ecef;
  background-color: ${COLORS.secondaryLight};
  border-radius: 0 0 8px 8px;
`;

// Modify the ImportSubmitButton for footer use with no top margin
const FooterButton = styled(ImportSubmitButton)`
  margin-top: 0;
`;

const ModalBodyStyled = styled(ModalBody)`
  padding: 20px 24px;
  /* Use conditional overrides via props instead of static styles */
  overflow-y: ${props => props.disableScroll ? 'hidden' : 'auto'};
  max-height: calc(90vh - 140px); /* Adjusted for header and footer */
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  min-height: 200px; /* Ensure minimum height */
  flex: 1; /* Allow flex growth */
`;

const BulkImportModal = ({ importType, isOpen, toggle, google, confirm, onImportComplete }) => {
  const [importTypeState, setImportTypeState] = useState(importType)
  const { readString } = usePapaParse()
  const [currentScreen, setCurrentScreen] = useState('Upload')
  const [isLoading, setIsLoading] = useState(false)
  const { parsedData: originalParsedData, onUploadCSV } = useParseAndValidateImport(
    {
      importType: importTypeState, 
      setBusy: () => {
        setCurrentScreen('Busy')
        setIsLoading(true)
      },
      google
    }
  )
  
  // Create a mutable copy of parsedData that we can update when clients are assigned
  const [parsedData, setParsedData] = useState([])
  
  // Sync with original data when it changes
  useEffect(() => {
    if (originalParsedData.length > 0) {
      setParsedData(JSON.parse(JSON.stringify(originalParsedData)))
    }
  }, [originalParsedData])
  
  // Set screen to Finalize when parsed data is available
  useEffect(() => {
    if (parsedData.length > 0) {
      setCurrentScreen('Finalize')
      setIsLoading(false)
    }
  }, [parsedData])

  // Function to update client assignments directly in the parent component
  const updateClientAssignment = (index, clientId, isBulkUpdate = false, fixedItemData = null) => {
    setParsedData(prevData => {
      const newData = JSON.parse(JSON.stringify(prevData));
      
      if (isBulkUpdate) {
        // Update all items without errors
        newData.forEach(item => {
          if (!item.error) {
            item.client = clientId;
            
            // Important: Preserve previously fixed validation flags when doing bulk update
            // This prevents items from being moved back to "Items Requiring Attention"
            if (item.address && item.locationType && item.locationName) {
              // Keep these validation flags explicitly set to false
              item.invalidAddress = false;
              item.missingAddress = false;
              item.missingLocationName = false;
              item.missingLocationType = false;
              item.invalidLocationType = false;
              item.fixed = true; // Mark as fixed
            }
            
            // Apply any additional fixed data
            if (fixedItemData) {
              Object.assign(item, fixedItemData);
            }
          }
        });
      } else {
        // Update single item
        if (newData[index]) {
          // If we have fixed item data, merge all properties
          if (fixedItemData) {
            Object.assign(newData[index], fixedItemData);
            // Ensure client ID is still applied
            newData[index].client = clientId;
          } else {
            // Just update the client ID
            newData[index].client = clientId;
            
            // Important: Preserve validation state for individual updates too
            if (newData[index].address && newData[index].locationType && newData[index].locationName) {
              // Keep these validation flags explicitly set to false
              newData[index].invalidAddress = false;
              newData[index].missingAddress = false;
              newData[index].missingLocationName = false;
              newData[index].missingLocationType = false;
              newData[index].invalidLocationType = false;
              newData[index].fixed = true; // Mark as fixed
            }
          }
        }
      }
      
      const locationsWithoutClients = newData.filter(item => !item.error && !item.client).length;
      
      return newData;
    });
  };

  const _switchToLocationMode = () => {
    setImportTypeState('location')
    setCurrentScreen('Upload')
    setIsLoading(false)
  }

  const completeImport = (success = false) => {
    if (importTypeState === 'client') {
      confirm(
        'Upload Locations?',
        'Do any of your clients have multiple locations?',
        _switchToLocationMode,
        'btn-primary',
        () => {
          toggle()
          setCurrentScreen('Upload')
          setIsLoading(false)
          // Only call onImportComplete if import was successful
          if (success && typeof onImportComplete === 'function') {
            onImportComplete()
          }
        }
      )
    } else {
      toggle()
      setCurrentScreen('Upload')
      setIsLoading(false)
      // Only call onImportComplete if import was successful
      if (success && typeof onImportComplete === 'function') {
        onImportComplete()
      }
    }
  }

  // This ref will hold the _onSubmit function from FinalizeImport
  const importFunctionRef = React.useRef(null);

  // Function to set the import function from FinalizeImport
  const setImportFunction = (importFunction) => {
    importFunctionRef.current = importFunction;
  };

  const handleCloseModal = () => {
    // Only allow closing if not in the middle of processing
    if (!isLoading) {
      toggle()
    }
  }

  // Modify the renderFooter function to use the new button style
  const renderFooter = () => {
    if (currentScreen !== 'Finalize') return null;
    
    // Force recalculation of locations without clients
    const validLocations = parsedData.filter(item => !item.error);
    const locationsWithoutClients = importTypeState === 'location' 
      ? validLocations.filter(item => !item.client).length 
      : 0;
      
    const buttonDisabled = importTypeState === 'location' && locationsWithoutClients > 0;
    
    return (
      <ModalFooterContainer>
        {!isLoading && parsedData.length > 0 && (
          <>
            {validLocations.length === 0 ? (
              <UploadText>
                No valid {importTypeState}s found to import.
              </UploadText>
            ) : parsedData.some(item => item.error) ? (
              <UploadText color={COLORS.danger}>
                Please fix all items requiring attention before importing.
              </UploadText>
            ) : (
              <>
                <FooterButton 
                  onClick={() => {
                    const finalizedData = validLocations;
                    // Double check that all locations have clients
                    let readyToImport = true;
                    if (importTypeState === 'location') {
                      const missingClientCount = finalizedData.filter(item => !item.client).length;
                      readyToImport = missingClientCount === 0;
                    }
                    if (readyToImport) {
                      // Call the import function if available, otherwise fall back to completeImport
                      if (importFunctionRef.current) {
                        importFunctionRef.current();
                      } else {
                        completeImport();
                      }
                    }
                  }}
                  disabled={buttonDisabled}
                >
                  {importTypeState === 'client' 
                    ? `Import ${validLocations.length} Client${validLocations.length !== 1 ? 's' : ''}` 
                    : `Import ${validLocations.length} Location${validLocations.length !== 1 ? 's' : ''}`}
                </FooterButton>
              </>
            )}
          </>
        )}
      </ModalFooterContainer>
    );
  };

  // Modify the screens to not include footer logic
  const SCREENS = {
    'Upload': <ImportForm importType={importTypeState} onUpload={onUploadCSV} />,
    'Busy': <ImportBusy onError={() => setIsLoading(false)} />,
    'Finalize': <FinalizeImport 
      data={parsedData} 
      importType={importTypeState} 
      completeImport={completeImport} 
      updateClientAssignment={updateClientAssignment}
      setImportFunction={setImportFunction}
      hideFooter 
    />,
  }

  const getModalTitle = () => {
    if (importTypeState === 'client') {
      return currentScreen === 'Finalize' ? 'Review Client Import' : 'Import Clients';
    } else {
      return currentScreen === 'Finalize' ? 'Review Location Import' : 'Import Locations';
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      toggle={handleCloseModal} 
      backdrop="static" 
      autoFocus={false} 
      size="xl"
      style={{
        margin: '5vh auto',
        maxWidth: currentScreen === 'Upload' ? '1000px' : '90vw',
        width: 'auto',
        minWidth: currentScreen === 'Upload' ? '900px' : '1100px', /* Adjust min-width based on screen */
        maxHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px',
        boxShadow: '0 5px 20px rgba(0,0,0,0.15)',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <GlobalStyle />
      <ModalHeader>
        <ModalTitle>{getModalTitle()}</ModalTitle>
        <CloseButton onClick={handleCloseModal} aria-label="Close modal" disabled={isLoading}>
          <MdClose size={24} />
        </CloseButton>
      </ModalHeader>
      
      <ModalBodyStyled disableScroll={currentScreen === 'Finalize'}>
        {SCREENS[currentScreen]}
      </ModalBodyStyled>
      
      {renderFooter()}
    </Modal>
  )
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries: ['places']
})(connectConfirmation(
  BulkImportModal
))
