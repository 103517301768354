import React, { useEffect } from 'react'
import styled from 'styled-components'

import Field from '../field'
import { Button, Review } from '../ui'
import { calculateResidentialBid } from '../../utils'
import { InputState } from '../../../../../../hooks'
import { browserWindow } from '../../../../../../utils'

const capitalize = word =>
  typeof word === 'string' ? word.charAt(0).toUpperCase() + word.slice(1) : word

const EditButton = styled(Button)`
  background-color: transparent;
  color: #0b64eb;
  justify-content: flex-end;
  padding: 0;
  width: 50%;
`

const Card = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin: 1rem auto 0 auto;
  min-height: 6rem;
  justify-content: space-between;
  width: 100%;

  select {
    width: 100%;
  }
`

const CardHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  text-align: center;
  width: 50%;
`

// const ContactInfo = styled.div`
//   margin-top: 1.5rem;
//   width: 100%;
// `

const SubTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
  width: 100%;
`

const BidReviewContainer = styled.div`
  border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 24px;
  padding: 2rem;
  text-align: center;
  width: 100%;
`

const BidContainer = styled.div`
  text-align: center;
`

const BidOption = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  text-align: left;
`

const BidPricing = styled.ul`
  font-weight: 700;
  list-style: none;
  margin: 1rem 0;
`

const BidPrice = styled.span`
  color: #11be60;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
`

const BidText = styled.h1`
  display: block;
  font-size: 1.75rem;
  text-align: center;
`

const StageTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
`

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
  width: 100%;
`

const BackBtn = styled(Button)`
  background-color: transparent;
  color: #666;
  padding: 0.5rem 1rem;
  
  &:hover {
    color: #0066cc;
  }
`

export const ResidentialReviewStage = ({
  bid,
  fields,
  id,
  onResetService,
  onReviewUpdate,
  onStageChange,
  review,
  questions,
  currentIndex
}) => {
  useEffect(() => {
    browserWindow.scrollTo(0, 0)
  }, [])

  const margin = 1 + parseFloat(review.margin) / 100
  const total = calculateResidentialBid(bid, margin)
  const reviewableFields = fields.map(
    ({ label, max, min, name, step, type }) => {
      const [value, setValue] = InputState(review[name] || 15)

      const onChange = event => {
        setValue(event)
        onReviewUpdate({
          ...review,
          ...total,
          [event.target.name]: parseFloat(event.target.value)
        })
      }

      if (
        name === 'onetime' ||
        name === 'weekly' ||
        name === 'biweekly' ||
        name === 'monthly' ||
        name === 'deepclean' ||
        name === 'moveinout'
      ) {
        return false
      }

      return (
        <Field
          key={name}
          id={`${id}-${name}`}
          label={`${label}: ${value}%`}
          labelStyle={{
            fontSize: '1.25rem',
            fontWeight: 700,
            marginTop: '1rem'
          }}
          max={max}
          maxText={`High`}
          midText={`Standard`}
          min={min}
          minText={`Low`}
          name={name}
          onChange={onChange}
          step={step}
          style={{}}
          type={type}
          value={value}
        />
      )
    }
  )

  const formatNumber = number =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <Review>
      <BidContainer>
        <BidText>You should charge</BidText>
        <BidPricing>
          {Object.keys(total).map(option => (
            <BidOption key={option}>
              {total[option].label}:{' '}
              <BidPrice>${formatNumber(total[option].amount)}</BidPrice>
            </BidOption>
          ))}
        </BidPricing>
      </BidContainer>

      {reviewableFields}

      <BidReviewContainer>
        <BidText>Review your bid</BidText>

        {Object.keys(bid).map(item => {
          if (
            item === 'margin' ||
            item === 'onetime' ||
            item === 'weekly' ||
            item === 'biweekly' ||
            item === 'monthly' ||
            item === 'deepclean' ||
            item === 'moveinout' ||
            item === 'employees' ||
            item === 'frequency' ||
            item === 'billingFrequency' ||
            item === 'floors' ||
            item === 'commercial' ||
            item === 'residential' ||
            item === 'total' ||
            (item === 'location' && typeof bid[item] !== 'string') ||
            item === 'contact'
          ) {
            return false
          }

          if (item === 'rooms') {
            const { bedrooms, bathrooms, kitchens } = bid[item]

            return (
              <Card key={item}>
                <CardHeader>
                  <StageTitle>Rooms :</StageTitle>
                </CardHeader>
                <EditButton onClick={() => onStageChange(item)} type={'button'}>
                  Edit
                </EditButton>
                <ul>
                  <li>Bedrooms: {bedrooms}</li>
                  <li>Bathrooms: {bathrooms}</li>
                  <li>Kitchens: {kitchens}</li>
                </ul>
              </Card>
            )
          }

          if (item === 'addons') {
            const {
              interiorWindows,
              exteriorWindows,
              cabinets,
              fridges,
              ovens
            } = bid[item]

            return (
              <Card key={item}>
                <CardHeader>
                  <StageTitle>Addons :</StageTitle>
                </CardHeader>
                <EditButton onClick={() => onStageChange(item)} type={'button'}>
                  Edit
                </EditButton>
                <SubTitle>Windows</SubTitle>
                <ul>
                  <li>Interior: {interiorWindows}</li>
                  <li>Exterior: {exteriorWindows}</li>
                </ul>
                <SubTitle>Deep Cleaning</SubTitle>
                <ul>
                  <li>Cabinets: {cabinets}</li>
                  <li>Fridges: {fridges}</li>
                  <li>Ovens: {ovens}</li>
                </ul>
              </Card>
            )
          }

          return (
            <Card key={item}>
              <CardHeader>
                <StageTitle>{capitalize(item)}:</StageTitle>
              </CardHeader>
              <EditButton
                onClick={() =>
                  item === 'service' ? onResetService() : onStageChange(item)
                }
                type={'button'}
              >
                Edit
              </EditButton>
              <span>{capitalize(bid[item])}</span>
            </Card>
          )
        })}
      </BidReviewContainer>
    </Review>
  )
}

export default ResidentialReviewStage
