export const defaultLocation = {
  type: '',
  size: 0
}

export const defaultBid = {
  place: '',
  service: ''
}

export const defaultCommercialReview = {
  margin: 25,
  billingFrequency: 'monthly',
  total: ''
}

export const defaultResidentialReview = {
  margin: 15,
  onetime: 0,
  weekly: 0,
  biweekly: 0,
  monthly: 0,
  deepclean: 0,
  moveinout: 0
}
