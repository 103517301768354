import React from 'react'
import moment from 'moment'
import { UncontrolledTooltip } from 'reactstrap'

export default ({ id, date, placement, tooltipFormat, displayFormat }) => {
  // Format the date for display
  const formattedDate = date ? moment(date).fromNow() : '';
  
  // Use the same exact moment instance for both display and tooltip
  // to ensure timestamps match exactly
  const momentInstance = date ? moment(date) : null;
  
  if (!momentInstance) return null;
  
  return (
    <>
      <span id={`tooltip${id}`}>{formattedDate}</span>
      <UncontrolledTooltip
        placement={placement || 'top'}
        target={`tooltip${id}`}
      >
        {momentInstance.format(tooltipFormat || 'MMMM Do YYYY, h:mm:ss a')}
      </UncontrolledTooltip>
    </>
  )
}
