import styled from "styled-components";
import { Button } from "reactstrap";

// Color palette
export const COLORS = {
  primary: '#445CE9',
  primaryLight: '#f0f2ff',
  primaryDark: '#2a3b9e',
  secondary: '#6A6A6A',
  secondaryLight: '#f8f9fa',
  secondaryDark: '#495057',
  danger: '#d24b4b',
  dangerLight: '#fff3cd',
  success: '#28a745',
  successLight: '#d4edda',
  warning: '#ffc107',
  warningLight: '#fff3cd',
  border: '#e9ecef',
  borderDark: '#ced4da',
  white: '#ffffff',
  hover: '#f0f0f0'
};

// Typography
export const TYPOGRAPHY = {
  h1: {
    fontSize: '28px',
    fontWeight: '700',
    lineHeight: '1.2',
    color: COLORS.primary
  },
  h2: {
    fontSize: '23px',
    fontWeight: '700',
    lineHeight: '1.2',
    color: COLORS.primary
  },
  h3: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '1.3',
    color: COLORS.primary
  },
  h4: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '1.3',
    color: COLORS.primary
  },
  body: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.5',
    color: COLORS.secondary
  },
  small: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '1.4',
    color: COLORS.secondary
  },
  hint: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '1.4',
    color: COLORS.secondaryDark
  }
};

// Spacing
export const SPACING = {
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '24px',
  xl: '32px'
};

// Standardized heading components
export const UploadTitle = styled.h2`
  font-size: ${TYPOGRAPHY.h2.fontSize};
  font-weight: ${TYPOGRAPHY.h2.fontWeight};
  color: ${TYPOGRAPHY.h2.color};
  line-height: ${TYPOGRAPHY.h2.lineHeight};
  margin-bottom: ${SPACING.sm};
`;

export const SectionTitle = styled.h3`
  font-size: ${TYPOGRAPHY.h3.fontSize};
  font-weight: ${TYPOGRAPHY.h3.fontWeight};
  color: ${TYPOGRAPHY.h3.color};
  line-height: ${TYPOGRAPHY.h3.lineHeight};
  margin-bottom: ${SPACING.xs};
  margin-top: ${props => props.mt || SPACING.md};
`;

export const SubsectionTitle = styled.h4`
  font-size: ${TYPOGRAPHY.h4.fontSize};
  font-weight: ${TYPOGRAPHY.h4.fontWeight};
  color: ${TYPOGRAPHY.h4.color};
  line-height: ${TYPOGRAPHY.h4.lineHeight};
  margin-bottom: ${SPACING.sm};
  margin-top: ${props => props.mt || SPACING.md};
`;

// Text components
export const UploadText = styled.p`
  font-size: ${props => props.size === 'small' ? TYPOGRAPHY.small.fontSize : TYPOGRAPHY.body.fontSize};
  font-weight: ${({fw}) => fw || TYPOGRAPHY.body.fontWeight};
  color: ${({color}) => color || COLORS.secondary};
  margin-bottom: ${({mb}) => mb || '0'};
  line-height: ${TYPOGRAPHY.body.lineHeight};
`;

export const HintText = styled.span`
  font-size: ${props => props.size === 'small' ? '11px' : TYPOGRAPHY.hint.fontSize};
  font-weight: ${props => props.fw || TYPOGRAPHY.hint.fontWeight};
  color: ${props => props.color || COLORS.secondaryDark};
  display: ${props => props.block ? 'block' : 'inline'};
  margin-top: ${props => props.mt || '0'};
`;

// List items
export const InstructionItem = styled.li`
  margin-bottom: ${SPACING.sm};
  line-height: ${TYPOGRAPHY.body.lineHeight};
  font-size: ${TYPOGRAPHY.body.fontSize};
  padding-left: ${SPACING.xs};
`;

// Containers and cards
export const FormatNote = styled.div`
  margin-top: ${props => props.mt || '0'};
  padding: ${SPACING.md};
  background-color: ${COLORS.secondaryLight};
  border-left: 4px solid ${COLORS.primary};
  border-radius: 4px;
  font-size: ${TYPOGRAPHY.small.fontSize};
  color: ${COLORS.secondaryDark};
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
`;

export const ContentSection = styled.div`
  margin-bottom: ${props => props.mb || SPACING.md};
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: ${props => props.alignItems || 'center'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  gap: ${props => props.gap || SPACING.md};
  flex-direction: ${props => props.direction || 'row'};
  flex-wrap: ${props => props.wrap || 'nowrap'};
  margin-bottom: ${props => props.mb || '0'};
  margin-top: ${props => props.mt || '0'};
  width: ${props => props.width || 'auto'};
`;

// LocationTypes specific styling
export const LocationTypeChip = styled.div`
  background-color: ${COLORS.primaryLight};
  border-radius: 4px;
  padding: ${SPACING.xs} ${SPACING.sm};
  font-size: 11px;
  color: ${COLORS.primary};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:hover {
    background-color: ${COLORS.primary};
    color: white;
  }
`;

// Dropzone styling
export const DropZoneStyle = {
  dropzone: { 
    overflowX: 'hidden', 
    overflowY: 'auto', 
    maxHeight: '160px', 
    border: `2px dashed ${COLORS.primary}`, 
    borderRadius: '10px', 
    padding: SPACING.lg, 
    textAlign: 'center',
    backgroundColor: COLORS.secondaryLight,
    transition: 'all 0.3s ease',
    '&:hover': {
      borderColor: COLORS.primary,
      backgroundColor: COLORS.primaryLight
    }
  },
  submitButtonContainer: { 
    display: 'none' 
  },
  preview: { 
    border: 'none',
    padding: `${SPACING.sm} 0`,
    backgroundColor: COLORS.primaryLight,
    borderRadius: '5px',
    margin: `${SPACING.sm} 0`
  },
  inputLabel: { 
    color: COLORS.secondary, 
    fontSize: TYPOGRAPHY.body.fontSize, 
    fontWeight: '500',
    marginBottom: SPACING.xs
  },
  inputLabelWithFiles: { 
    display: 'none' 
  },
  remove: {
    backgroundColor: COLORS.danger,
    color: COLORS.white,
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
};

// Buttons
export const ImportSubmitButton = styled(Button)`
  min-width: 120px;
  align-self: flex-end;
  font-weight: 500;
  padding: ${SPACING.sm} ${SPACING.lg};
  border-radius: 6px;
  transition: all 0.2s ease;
  background-color: ${props => props.outline ? 'transparent' : COLORS.primary};
  border-color: ${COLORS.primary};
  color: ${props => props.outline ? COLORS.primary : COLORS.white};
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  &:not(:disabled):hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    background-color: ${props => props.outline ? COLORS.primaryLight : COLORS.primaryDark};
    border-color: ${props => props.outline ? COLORS.primary : COLORS.primaryDark};
    color: ${props => props.outline ? COLORS.primary : COLORS.white};
  }
`;

export const SecondaryButton = styled(Button)`
  font-weight: 500;
  padding: ${SPACING.sm} ${SPACING.lg};
  border-radius: 6px;
  transition: all 0.2s ease;
  background-color: ${COLORS.secondaryLight};
  border-color: ${COLORS.borderDark};
  color: ${COLORS.secondaryDark};
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  &:not(:disabled):hover {
    background-color: ${COLORS.hover};
    border-color: ${COLORS.secondary};
  }
`;

// Sample Download Link styling
export const SampleDownloadLink = styled.a`
  color: ${COLORS.primary};
  font-weight: 500;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  padding: ${SPACING.xs} ${SPACING.sm};
  border: 1px solid ${COLORS.primary};
  border-radius: 4px;
  font-size: ${TYPOGRAPHY.small.fontSize};
  background-color: ${COLORS.white};
  transition: all 0.2s ease;
  
  &:hover, &:focus {
    background-color: ${COLORS.primaryLight};
    text-decoration: none;
    color: ${COLORS.primary};
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  }
  
  &:before {
    content: "↓";
    margin-right: ${SPACING.xs};
    font-weight: bold;
  }
`;

// Fixed footer for modals
export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 20px;
  background-color: ${COLORS.secondaryLight};
  border-top: 1px solid ${COLORS.border};
  margin-top: ${SPACING.md};
`;

// Animation keyframes for the spinner
export const spinnerKeyframes = `
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;