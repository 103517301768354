import React, { forwardRef, useImperativeHandle } from 'react'

import Field from '../field'
import { PrimaryButton, Question, Tooltip } from '../ui'
import { InputState } from '../../../../../../hooks'

export const CommercialExpectationsStage = forwardRef(({
  current,
  id,
  label,
  max,
  min,
  onClick,
  step,
  type
}, ref) => {
  const [value, setValue] = InputState(current || 5)

  const onNext = () => {
    onClick(null, value)
  }

  // Expose getValue method to parent through ref
  useImperativeHandle(ref, () => ({
    getValue: () => value
  }))

  return (
    <Question>
      <Tooltip>
        If the prospective client has a very demanding scope of work this can
        impact the time it takes to clean the account and meet those
        expectations.
      </Tooltip>
      <Field
        id={id}
        label={label}
        labelStyle={{}}
        max={max}
        maxText={`Demanding`}
        midText={`Normal`}
        min={min}
        minText={`Low`}
        name={id}
        onChange={setValue}
        step={step}
        style={{ marginTop: '2rem' }}
        type={type}
        value={value}
      />

      <PrimaryButton onClick={() => onNext()} type={'button'}>
        Next
      </PrimaryButton>
    </Question>
  )
})

export default CommercialExpectationsStage
