import { UploadTitle } from './ImportContentStyles'
import MaterialSpinner from 'components/MaterialSpinner'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ProgressText = styled.div`
  color: #6A6A6A;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
`

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #f0f0f0;
  border-radius: 2px;
  margin-top: 10px;
  overflow: hidden;
`

const ProgressFill = styled.div`
  height: 100%;
  background-color: #6A6A6A;
  width: ${props => props.progress}%;
  transition: width 0.3s ease;
`

export const ImportBusy = ({ onError }) => {
  const [processingTime, setProcessingTime] = useState(0);
  const [lastNetworkActivity, setLastNetworkActivity] = useState(Date.now());
  const [currentAddress, setCurrentAddress] = useState('');
  const [progress, setProgress] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [currentRow, setCurrentRow] = useState(0);

  // Listen for console logs to update progress
  useEffect(() => {
    const originalLog = console.log;
    console.log = (...args) => {
      const logMessage = args.join(' ');
      
      // Track total rows
      if (logMessage.includes('Starting to parse and validate')) {
        const matches = logMessage.match(/validate (\d+) rows/);
        if (matches) {
          setTotalRows(parseInt(matches[1]));
        }
      }
      
      // Track current row
      if (logMessage.includes('Processing row')) {
        const matches = logMessage.match(/Processing row (\d+) of/);
        if (matches) {
          const row = parseInt(matches[1]);
          setCurrentRow(row);
          setProgress((row / totalRows) * 100);
        }
      }

      // Track current address
      if (logMessage.includes('Validating address:')) {
        const address = logMessage.replace('Validating address:', '').trim();
        setCurrentAddress(address);
      }

      originalLog.apply(console, args);
    };

    return () => {
      console.log = originalLog;
    };
  }, [totalRows]);

  useEffect(() => {
    const startTime = Date.now();
    console.log('Import processing started');
    
    // Monitor network activity
    const originalFetch = window.fetch;
    window.fetch = function(...args) {
      setLastNetworkActivity(Date.now());
      console.log('Network request detected:', args[0]);
      return originalFetch.apply(this, args);
    };

    // Monitor XHR activity
    const originalXHROpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function(...args) {
      setLastNetworkActivity(Date.now());
      console.log('XHR request detected:', args[1]);
      return originalXHROpen.apply(this, args);
    };
    
    const checkDuration = setInterval(() => {
      const duration = (Date.now() - startTime) / 1000;
      const timeSinceLastNetwork = (Date.now() - lastNetworkActivity) / 1000;
      setProcessingTime(duration);
      
      console.log(`Import processing duration: ${duration.toFixed(1)} seconds`);
      console.log(`Time since last network activity: ${timeSinceLastNetwork.toFixed(1)} seconds`);
      
      if (duration > 30) {
        console.warn('Import processing taking longer than expected (>30s)');
        // Log additional diagnostic information
        console.log('Memory usage:', performance.memory ? performance.memory : 'Not available');
        console.log('Network state:', navigator.onLine ? 'Online' : 'Offline');
        
        if (timeSinceLastNetwork > 30) {
          console.warn('No network activity detected for 30 seconds');
        }
      }

      if (duration > 60) {
        console.error('Import processing timeout after 60 seconds');
        if (onError) {
          onError(new Error(`Import processing timeout after 60 seconds. Last network activity: ${timeSinceLastNetwork.toFixed(1)} seconds ago`));
        }
        clearInterval(checkDuration);
      }
    }, 5000);

    return () => {
      // Restore original network monitoring functions
      window.fetch = originalFetch;
      XMLHttpRequest.prototype.open = originalXHROpen;
      
      clearInterval(checkDuration);
      console.log('Import processing component unmounted after', ((Date.now() - startTime)/1000).toFixed(1), 'seconds');
    };
  }, [onError, lastNetworkActivity]);

  return (
    <div style={{marginTop: 20}}>
      <UploadTitle>
        Formatting data and verifying addresses...
        {processingTime > 30 && ' (Taking longer than expected)'}
      </UploadTitle>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 30}}>
        <MaterialSpinner color='#6A6A6A' />
        <ProgressBar>
          <ProgressFill progress={progress} />
        </ProgressBar>
        <ProgressText>
          Processing row {currentRow} of {totalRows}
          {currentAddress && (
            <>
              <br />
              Validating: {currentAddress}
            </>
          )}
          <br />
          Time elapsed: {processingTime.toFixed(0)} seconds
        </ProgressText>
      </div>
    </div>
  )
}