import React, { Component, memo } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import moment from 'moment'
import { Switch, Redirect } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import Profitwell from 'profitwell-component'

import LeftNav from 'components/layout/LeftNav'
import Header from 'components/layout/Header'
import useScript from 'hooks/useScript'

import { PrivateRoute } from 'hoc'
import { ClientDetails, ClientList, ArchivedClients } from './Clients'
import {
  Employee,
  EmployeeList,
  InvitedEmployees,
  ArchivedEmployees,
  EmployeeDetails
} from './Employees'
import { Job } from './Jobs'

//import { JobList as ServicesList } from './Services'
import { JobList as Walkthroughlist } from './Walkthroughs'
import { JobList as Inspectionlist } from './Inspections'
import { Location, LocationDetails, LocationList } from './Locations'
import Map from './Map'
import EditCompany from './Companies/Edit'
//import MerchantSetup from './MerchantSetup'
import Estimator from './Estimates/Estimator'
import { CompanyEdit, CompanyProfile } from './Library/CompanyProfile'
import Billing, { SelectPlanPage } from './Billing'
import {
  ServiceTemplateList,
  ServiceTemplateDetails
} from './Library/ServiceTemplates'
import ChooseProposalEditor from './Proposals/ChooseEditor'
import Proposal from './Proposals/Proposal'
import ProposalList from './Proposals/List'
import ArchivedProposals from './Proposals/List/ArchivedProposals'
import EmploymentSelection from './EmploymentSelection'
import NewProposalSetup from './Proposals/New'
import Proposal2 from './Proposals/Proposal2'
import CompletedProposal from './Proposals/CompletedProposal'
import ArchivedInspections from './Inspections/JobList/ArchivedInspections'
//import ArchivedJobs from './Services/JobList/ArchivedJobs'
import ArchivedWalkthroughs from './Walkthroughs/JobList/ArchivedWalkthroughs'
import EstimatesList from './Estimates/List'
import ArchivedEstimates from './Estimates/List/ArchivedEstimates'
import ArchivedLocations from './Locations/LocationList/ArchivedLocations'
import NewEstimate from './Estimates/NewEstimate'
import SalesDashboard from './Sales'
import { axiosClient } from 'store'
import { default as QuickBid } from './Estimates/quickbid'
import Integrations from './Integrations'
import { Shop, HomeDepotPro, CultureStudio } from './Shop'
import { Swept } from './Swept'
import { AdminDashboard } from './AdminDashboard'
import DashboardAlertScreen from './components/DashboardAlertScreen'

import * as acl from '../../utils/acl.service'

import { useAnalytics } from 'use-analytics'
import { trackEvent } from 'api/clientHub'

const AltassianEmbed = {
  src: 'https://jsd-widget.atlassian.com/assets/embed.js',
  'data-base-url': 'https://jsd-widget.atlassian.com',
  'data-key': '5e5e8c32-68f8-407c-b1df-acceebeb09f1',
  'data-jsd-embedded': ''
}

const ContentArea = styled.div`
  position: fixed;
  left: 200px;
  right: 0;
  top: 66px;
  bottom: 0;
  overflow: auto;
`

const SupportEmbed = memo(
  props => {
    useScript(AltassianEmbed)
    return null
  },
  (prevProps, nextProps) => {
    if (prevProps.hasRendered === nextProps.hasRendered) {
      return true
    }
  }
)

class Dashboard extends Component {
  state = {}

  fetchBillingInfo = async () => {
    try {
      const { data } = await axiosClient.get('/api/companies/billing')
      console.log('fetched billing data')
      if (data) {
        this.setState({
          billing: data
        })
      }
    } catch (err) {
      this.setState({
        billingError: true
      })
    }
  }

  async componentDidMount() {
    console.log(this.props)
    const userpilot = window.userpilot
    const { profile, currentEmployment, token } = this.props
    if (!token) return

    if (currentEmployment && userpilot && profile && userpilot.identify) {
      userpilot.identify(currentEmployment._id, {
        email: profile.email,
        name: profile.name.full,
        first_name: profile.name.first,
        company: currentEmployment.company.name,
        company_id: currentEmployment.company._id,
        role: currentEmployment.role,
        created_at: moment(currentEmployment.createdAt).unix()
      })
    }

    this.fetchBillingInfo()
  }

  render() {
    const {
      isLoggedIn,
      currentEmployment,
      profile,
      employment,
      isFreshLogin,
      token
    } = this.props
    const { billing, billingError } = this.state


    trackEvent({ typeEvent: 'BID_USER_DASHBOARD_LOAD' })

    // console.log(profile)
    const isAdmin = profile?.app_metadata?.bid?.role === "admin" ? true : false


    // profile?.user_metadata?.isAdmin

    if (isMobileOnly) {
      return <Redirect to="/mobile" />
    }

    if (!token) {
      window.location.href = `${process.env.REACT_APP_LOGIN_DOMAIN}/auth/login`
    }

    let alertMessage
    let isDelinquent
    let companyArchived = currentEmployment?.company?.archived

    const stripe = billing?.stripe

    let errorType
    if (billingError && !stripe) {
      errorType = 'BILLING_ERROR'
    }

    const subscription = stripe?.subscription

    const plan = subscription?.plan?.nickname

    const customer = stripe?.customer

    if (customer?.delinquent) {
      if (plan === 'starter_monthly') {
        if (customer.balance >= 98) {
          isDelinquent = true
        }
      }
      if (plan === 'growth_monthly') {
        if (customer.balance >= 258) {
          isDelinquent = true
        }
      }
    }

    const subscriptionCancelled =
      (customer && !subscription) || subscription?.status === 'canceled'

    if (companyArchived || subscriptionCancelled) {
      errorType = 'SUBSCRIPTION_CANCELLED'
    } else if (isDelinquent) {
      errorType = 'IS_DELINQUENT'
    }

    const redirectLink = acl.isAllowed('dashboard.show')
      ? '/dashboard/sales/'
      : '/dashboard/walkthroughs'

    let dashboard = null

    // const isAdmin = userRole

    if (profile && currentEmployment) {
      if (currentEmployment?.role === 'member') {
        return <Redirect to="/members-only" />
      }
      if (employment?.length > 1 && isFreshLogin) {
        dashboard = (
          <EmploymentSelection
            employment={employment}
            currentEmployment={currentEmployment}
            fetchBillingInfo={this.fetchBillingInfo}
          />
        )
      } else {
        dashboard = (
          <>
            <Header hideSearch={!!alertMessage} />
            {!!errorType ? (
              <DashboardAlertScreen
                errorType={errorType}
                stripeCustomer={customer}
                stripeSubscription={subscription}
                employeeRole={currentEmployment?.role}
              />
            ) : (
              <>
                <LeftNav />
                <ContentArea className="with-left-nav" style={{ overflowX: 'hidden' }}>
                  <Switch>
                    <PrivateRoute
                      path="/dashboard/clients/"
                      exact
                      component={ClientList}
                      controlAccess="clients.list"
                    />
                    <PrivateRoute
                      path="/dashboard/clients/archived/"
                      component={ArchivedClients}
                      controlAccess="clients.archivedList"
                    />
                    <PrivateRoute
                      path="/dashboard/client-details/:clientId"
                      component={ClientDetails}
                      controlAccess="clients.read"
                    />
                    {/*
                      <PrivateRoute
                        path="/dashboard/services/"
                        exact
                        component={ServicesList}
                      />

                      <PrivateRoute
                        path="/dashboard/services/archived/"
                        component={ArchivedJobs}
                      />
                      <PrivateRoute
                        path="/dashboard/services/:serviceId"
                        component={ServicesList}
                      />
                      */}

                    <PrivateRoute
                      path="/dashboard/sales/"
                      exact
                      component={SalesDashboard}
                    />

                    <PrivateRoute
                      path="/dashboard/walkthroughs/"
                      exact
                      component={Walkthroughlist}
                    />
                    <PrivateRoute
                      path="/dashboard/walkthroughs/archived/"
                      component={ArchivedWalkthroughs}
                    />
                    <PrivateRoute
                      path="/dashboard/walkthroughs/:walkthroughId"
                      component={Walkthroughlist}
                    />
                    <PrivateRoute
                      path="/dashboard/proposals/"
                      exact
                      component={ProposalList}
                      controlAccess="proposals.list"
                    />
                    <PrivateRoute
                      path="/dashboard/proposals/archived/"
                      component={ArchivedProposals}
                    />
                    <PrivateRoute
                      path="/dashboard/proposal-setup"
                      exact
                      component={NewProposalSetup}
                      controlAccess="proposals.create"
                    />

                    <PrivateRoute
                      path="/dashboard/edit-new-proposal"
                      exact
                      component={Proposal2}
                      controlAccess="proposals.create"
                    />

                    <PrivateRoute
                      path="/dashboard/edit-legacy-proposal"
                      exact
                      component={Proposal}
                      controlAccess="proposals.create"
                    />

                    <PrivateRoute
                      path="/dashboard/edit-proposal/:proposalId"
                      exact
                      component={ChooseProposalEditor}
                      controlAccess="proposals.edit"
                    />

                    <PrivateRoute
                      path="/dashboard/edit-new-proposal/:proposalId"
                      exact
                      component={Proposal2}
                      controlAccess="proposals.edit"
                    />
                    <PrivateRoute
                      path="/dashboard/edit-legacy-proposal/:proposalId"
                      component={Proposal}
                      controlAccess="proposals.edit"
                    />
                    <PrivateRoute
                      path="/dashboard/completed-proposal/:proposalId"
                      exact
                      component={CompletedProposal}
                      controlAccess="proposals.edit"
                    />

                    <PrivateRoute
                      path="/dashboard/jobs/:jobId/proposal"
                      component={Proposal}
                      controlAccess="proposals.edit"
                    />

                    <PrivateRoute
                      path="/dashboard/jobs/:jobId"
                      component={Job}
                    />
                    <PrivateRoute
                      path="/dashboard/new-job/:jobType"
                      component={Job}
                      controlAccess="jobs.create"
                    />
                    <PrivateRoute
                      path="/dashboard/employees/"
                      exact
                      component={EmployeeList}
                      controlAccess="employees.list"
                    />
                    <PrivateRoute
                      path="/dashboard/employees/invited/"
                      component={InvitedEmployees}
                      controlAccess="employees.list"
                    />
                    <PrivateRoute
                      path="/dashboard/employees/archived/"
                      component={ArchivedEmployees}
                      controlAccess="employees.archivedList"
                    />
                    <PrivateRoute
                      path="/dashboard/employees/:employeeId"
                      exact
                      component={EmployeeDetails}
                      controlAccess="employees.read"
                    />
                    <PrivateRoute
                      path="/dashboard/employees/:employeeId/edit"
                      component={Employee}
                      controlAccess="employees.update"
                    />
                    <PrivateRoute
                      path="/dashboard/new-employee/"
                      component={Employee}
                      controlAccess="proposals.create"
                    />
                    <PrivateRoute
                      path="/dashboard/locations/:locationId"
                      component={Location}
                    />
                    <PrivateRoute
                      path="/dashboard/locations/"
                      exact
                      component={LocationList}
                      controlAccess="locations.list"
                    />
                    <PrivateRoute
                      path="/dashboard/ShowLocationsArchived/"
                      component={ArchivedLocations}
                    />
                    <PrivateRoute
                      path="/dashboard/location-details/:locationId"
                      component={LocationDetails}
                    />
                    <PrivateRoute
                      path="/dashboard/:clientId/new-location/"
                      component={Location}
                    />
                    <PrivateRoute
                      path="/dashboard/companies/edit/:companyId"
                      component={EditCompany}
                    />

                    <PrivateRoute path="/dashboard/map/" component={Map} />
                    <PrivateRoute
                      path="/dashboard/library/services"
                      exact
                      component={ServiceTemplateList}
                      controlAccess="services.list"
                    />
                    <PrivateRoute
                      path="/dashboard/library/services/:serviceId"
                      component={ServiceTemplateDetails}
                      controlAccess="services.read"
                    />
                    <PrivateRoute
                      path="/dashboard/library/billing/"
                      component={Billing}
                      controlAccess="clients.update"
                    />
                    {/* <PrivateRoute
                      path="/dashboard/library/swept/"
                      component={Swept}
                      controlAccess="clients.update"
                    /> */}
                    <PrivateRoute
                      path="/dashboard/select-plan/"
                      component={SelectPlanPage}
                    />
                    <PrivateRoute
                      path="/dashboard/library/"
                      exact
                      component={CompanyProfile}
                      controlAccess="merchant.read"
                    />
                    <PrivateRoute
                      path="/dashboard/library/company"
                      component={CompanyEdit}
                      controlAccess="merchant.update"
                    />
                    <PrivateRoute
                      path="/dashboard/estimates"
                      exact
                      component={EstimatesList}
                    />
                    <PrivateRoute
                      path="/dashboard/estimates/archived/"
                      component={ArchivedEstimates}
                    />
                    <PrivateRoute
                      path="/dashboard/new-estimate"
                      component={NewEstimate}
                    />
                    <PrivateRoute
                      path="/dashboard/edit-estimate/:estimateId"
                      component={Estimator}
                    />
                    <PrivateRoute
                      path="/dashboard/edit-estimate/"
                      component={Estimator}
                    />
                    <PrivateRoute
                      path="/dashboard/quickbid/:estimateId"
                      component={QuickBid}
                    />
                    <PrivateRoute
                      path="/dashboard/quickbid/"
                      component={QuickBid}
                    />
                    <PrivateRoute
                      path="/dashboard/inspections"
                      exact
                      component={Inspectionlist}
                    />
                    <PrivateRoute
                      path="/dashboard/inspections/archived/"
                      component={ArchivedInspections}
                    />
                    <PrivateRoute
                      path="/dashboard/inspections/:inspectionId"
                      exact
                      component={Inspectionlist}
                    />
                    <PrivateRoute
                      path="/dashboard/integrations"
                      exact
                      component={Integrations}
                    />
                    <PrivateRoute
                      path="/dashboard/shop/culture-studio"
                      component={CultureStudio}
                    />
                    <PrivateRoute
                      path="/dashboard/shop/hdpro"
                      component={HomeDepotPro}
                    />
                    <PrivateRoute
                      path="/dashboard/shop"
                      exact
                      component={Shop}
                    />
                    {/* <PrivateRoute
                        path="/dashboard/admin-dashboard"
                        exact
                        component={AdminDashboard}
                    /> */}
                    {isAdmin ? (
                      <PrivateRoute
                        path="/dashboard/admin-dashboard"
                        exact
                        component={AdminDashboard}
                      />
                    ) : null}
                    <Redirect from="/dashboard/" to={redirectLink} />
                  </Switch>
                </ContentArea>
              </>
            )}
          </>
        )
      }
    }

    return (
      <div className="full-height">
        {dashboard}
        <SupportEmbed hasRendered={true} />
        <Profitwell
          authToken={process.env.REACT_APP_PROFITWELL_API_KEY}
          email={profile && profile.email}
          shouldNotLoad={process.env.NODE_ENV === 'development'}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.user.token,
  profile: state.user.profile,
  currentEmployment: state.user.currentEmployment,
  employment: state.user.employment,
  isFreshLogin: state.user.isFreshLogin
})

export default connect(mapStateToProps, null)(Dashboard)
