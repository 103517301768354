export const proposalData = [
  {
    name: 'Sent',
    color: '#07e1ed'
  },
  {
    name: 'Viewed',
    color: '#39dd68'
  }
]

export const employeeRoles = [
  {
    name: 'member'
  },
  {
    name: 'manager'
  },
  {
    name: 'administrator'
  },
  {
    name: 'owner'
  }
]

export const estimateTypes = [
  {
    name: 'Estimate',
  },
  {
    name: 'QuickBid'
  }
]