import AreasRenderer from '../Proposal2/components/renderers/Areas'
import ChargesRenderer from '../Proposal2/components/renderers/Charges'
import DateRangeRenderer from '../Proposal2/components/renderers/DateRange'
import DividerRenderer from '../Proposal2/components/renderers/Divider'
import HeadingRenderer from '../Proposal2/components/renderers/Heading'
import ImageRenderer from '../Proposal2/components/renderers/Image'
import LetterheadRenderer from '../Proposal2/components/renderers/Letterhead'
import RichTextRenderer from '../Proposal2/components/renderers/RichText'
import ScopeOfWorkRenderer from '../Proposal2/components/renderers/ScopeOfWork'
import ServiceFrequencyRenderer from '../Proposal2/components/renderers/ServiceFrequency'
import SignaturesRenderer from '../Proposal2/components/renderers/Signatures'
import Elements from '../Proposal2/Elements'



export const RENDER_ELEMENT = {

    "rich_text": (element, proposal) => <RichTextRenderer element={element} />,
    "heading": (element, proposal) => <HeadingRenderer element={element} />,
    "divider": (element, proposal) => <DividerRenderer element={element} />,
    "spacer": (element, proposal) => Elements.find(e => e.type === 'spacer').render({ element, renderForPdf: false }),
    "image": (element, proposal) => <ImageRenderer element={element} />,
    "areas": (element, proposal) => <AreasRenderer element={element} proposal={proposal} renderForPdf={true} />,
    "signatures": (element, proposal) => <SignaturesRenderer element={element} proposal={proposal} />,
    "letterhead": (element, proposal) => <LetterheadRenderer element={element} proposal={proposal} />,
    "scope_of_work": (element, proposal) => {
      let modified = { ...element };
      if (modified.data && Array.isArray(modified.data.taskSections)) {
        modified.data = {
          ...modified.data,
          taskSections: modified.data.taskSections.map(section => {
            const { spacer, pageBreak, ...rest } = section;
            return rest;
          })
        };
      }
      return <ScopeOfWorkRenderer element={modified} proposal={proposal} renderForPdf={false} />;
    },
    "page_break": (element, proposal) => <div style={{ pageBreakAfter: 'always' }}></div>,
    "charges": (element, proposal) => <ChargesRenderer element={element} proposal={proposal} />,
    "service_frequency": (element, proposal) => <ServiceFrequencyRenderer element={element} proposal={proposal} />,
    "service_dates": (element, proposal) => <DateRangeRenderer element={element} proposal={proposal} />

}