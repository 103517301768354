import styled from 'styled-components'

export const ProgressBar = styled.ol`
  align-items: center;
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin-top: 2rem;
  padding: 3rem 1.5rem;
  position: relative;
  width: 100%;
`

export const ProgressItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0.5rem;
    left: calc(50% + 0.5rem);
    width: calc(100% - 1rem);
    height: 2px;
    background-color: ${({ complete, current }) => {
      if (complete && !current) return '#0066cc'
      return '#e0e0e0'
    }};
  }
`

export const ProgressItem = styled.li`
  background-color: ${({ complete }) => (complete ? '#0066cc' : '#e0e0e0')};
  border-radius: 50%;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  height: 1rem;
  width: 1rem;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: ${({ clickable }) => (clickable ? 'scale(1.1)' : 'none')};
  }
`

export const ProgressLabel = styled.span`
  font-size: 0.75rem;
  color: ${({ complete }) => (complete ? '#0066cc' : '#666')};
  text-align: center;
  max-width: 80px;
  word-wrap: break-word;
  line-height: 1.2;
`
