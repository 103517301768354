import { addons, frequencyConversionsByType, places, rooms, US_STATES } from '../data'

export const calculateCommercialBid = (bid, billingFrequency, margin, isHourlyBid, isRecurring) => {
  // Input validation
  if (!bid || !bid.location || !bid.floors || !bid.place) {
    throw new Error('Invalid bid data provided')
  }

  const standardIntensity = 5
  const getPlace = place => Object.keys(US_STATES).find(state => US_STATES[state].name === place)
  const place = getPlace(bid.place)
  
  if (!place) {
    throw new Error('Invalid place selected')
  }

  const expectationsCost = (standardIntensity - (bid.expectations || standardIntensity)) * -200
  const placeType = places.find(({ name }) => name === bid.location.type)
  
  if (!placeType) {
    throw new Error('Invalid location type')
  }
  
  const productionRate = placeType.rate
  const productionRateWithExpectations = productionRate - expectationsCost

  // Validate floor percentages
  const totalFloorPercentage = (bid.floors.percentHardFloors || 0) + (bid.floors.percentSoftFloors || 0)
  if (totalFloorPercentage !== 100) {
    throw new Error('Floor type percentages must sum to 100%')
  }

  const adjustedProductionRate =
    productionRateWithExpectations * (bid.floors.percentHardFloors / 100) * 0.75 +
    productionRateWithExpectations * (bid.floors.percentSoftFloors / 100)

  // Prevent division by zero
  if (adjustedProductionRate <= 0) {
    throw new Error('Invalid production rate calculated')
  }

  const frequencyRate = isRecurring ? bid.frequency.rate : 1

  const frequencyConversion = isRecurring ? frequencyConversionsByType[bid.frequency.unit] : frequencyRate

  const hoursPerJob = bid.location.size / adjustedProductionRate
  
  // Use the manually entered rate for hourly bids
  const hourlyRate = isHourlyBid 
    ? parseFloat(bid.employees.rate) || 0
    : US_STATES[place].hourly

  if (hourlyRate <= 0) {
    throw new Error('Invalid hourly rate')
  }

  const hourlyCostPerJob = hourlyRate * 1.5
  const costPerJob = hourlyCostPerJob * hoursPerJob
  const bidByHourlyRateWithFrequency =
    costPerJob *
    frequencyRate *
    (typeof frequencyConversion[billingFrequency] !== 'undefined'
      ? frequencyConversion[billingFrequency]
      : frequencyRate) *
    margin *
    1.03

  const total = bidByHourlyRateWithFrequency.toFixed(2)

  return {total, costPerJob, hourlyRate, hoursPerJob, hourlyCostPerJob, adjustedProductionRate}
}

export const calculateResidentialBid = (bid, margin) => {
  // Input validation
  if (!bid) {
    throw new Error('Invalid bid data provided')
  }

  if (typeof margin !== 'number' || margin < 0) {
    throw new Error('Invalid margin value')
  }

  const base = 50
  let roomCosts = 0
  let addonCosts = 0

  if (bid.rooms) {
    Object.keys(bid.rooms).forEach(room => {
      const roomType = rooms.find(r => r.name === room)
      if (!roomType) {
        throw new Error(`Invalid room type: ${room}`)
      }
      if (typeof bid.rooms[room] !== 'number' || bid.rooms[room] < 0) {
        throw new Error(`Invalid quantity for room: ${room}`)
      }
      roomCosts += roomType.rate * bid.rooms[room]
    })
  }

  if (bid.addons) {
    Object.keys(bid.addons).forEach(addon => {
      const addonType = addons.find(a => a.name === addon)
      if (!addonType) {
        throw new Error(`Invalid addon type: ${addon}`)
      }
      if (typeof bid.addons[addon] !== 'number' || bid.addons[addon] < 0) {
        throw new Error(`Invalid quantity for addon: ${addon}`)
      }
      addonCosts += addonType.rate * bid.addons[addon]
    })
  }

  let subtotal = margin * (base + roomCosts + addonCosts)

  const total = {
    onetime: {
      amount: subtotal.toFixed(2),
      label: 'One Time'
    },
    weekly: {
      amount: (subtotal * 0.8).toFixed(2),
      label: 'Weekly'
    },
    biweekly: {
      amount: (subtotal * 0.85).toFixed(2),
      label: 'Biweekly'
    },
    monthly: {
      amount: (subtotal * 0.9).toFixed(2),
      label: 'Monthly'
    },
    deepclean: {
      amount: (subtotal * 1.33).toFixed(2),
      label: 'Deep Clean'
    },
    moveinout: {
      amount: (subtotal * 1.5).toFixed(2),
      label: 'Move In/Out'
    }
  }

  return total
}
