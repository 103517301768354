import React, { forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'

import { ButtonGroup, Icon, Question, Title } from '../ui'
import { InputState, ToggleState } from '../../../../../../hooks'

const LocationButton = styled.label`
  align-items: center;
  background-color: #0a5ad1;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.25rem;
  font-weight: 500;
  flex-flow: column nowrap;
  height: 144px;
  justify-content: center;
  line-height: 1.5;
  outline: none;
  padding: 0.5rem 1rem;
  position: relative;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  width: 144px;

  :first-of-type {
    margin-right: 2rem;
  }

  :active,
  :focus,
  :hover {
    opacity: 0.9;
    transform: translateY(-4px);
  }

  input {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    border: none !important;
    display: hidden;
    height: 0 !important;
    outline: none !important;
    width: 0 !important;
  }

  @media screen and (min-width: 32rem) {
    font-size: 1.5rem;
    height: 192px;
    width: 192px;
  }
`

const ApartmentIcon = ({
  alt,
  activeColor = '#FFFFFF',
  // inactiveColor = '#0B64EB',
  // isActive = false,
  location,
  name,
  onBlur,
  onClick,
  onFocus,
  onMouseOver,
  onMouseOut,
  onTouchCancel,
  onTouchEnd,
  onTouchStart,
  title = 'Apartment'
}) => {
  const [value, setValue] = InputState(title === location)

  const onSelect = event => {
    setValue(event)
    onClick(event)
  }

  return (
    <LocationButton
      htmlFor={name}
      onBlur={onBlur}
      onClick={onSelect}
      onFocus={onFocus}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onTouchCancel={onTouchCancel}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
    >
      <input name={name} onChange={onSelect} type="checkbox" value={value} />
      <Icon>
        <svg
          alt={alt}
          width="77"
          height="87"
          viewBox="0 0 77 87"
          fill="none"
          title={title}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M75.3617 13.1064C76.266 13.1064 77 12.3724 77 11.4681V1.6383C77 0.733957 76.266 0 75.3617 0H1.6383C0.733957 0 0 0.733957 0 1.6383V11.4681C0 12.3724 0.733957 13.1064 1.6383 13.1064H8.19149V73.7234H6.56138C2.94238 73.7234 0 76.6625 0 80.2766V85.1915C0 86.0958 0.733957 86.8298 1.6383 86.8298H75.3617C76.266 86.8298 77 86.0958 77 85.1915V80.2766C77 76.6625 74.0576 73.7234 70.4386 73.7234H68.8085V13.1064H75.3617ZM3.2766 3.2766H73.7234V9.82979H67.1702H9.82979H3.2766V3.2766ZM73.7234 80.2766V83.5532H3.2766V80.2766C3.2766 78.4696 4.74943 77 6.56138 77H9.82979H31.1277H45.8723H67.1702H70.4386C72.2506 77 73.7234 78.4696 73.7234 80.2766ZM44.234 73.7234H32.766V54.0638H44.234V73.7234ZM65.5319 73.7234H47.5106V52.4255C47.5106 51.5212 46.7767 50.7872 45.8723 50.7872H31.1277C30.2233 50.7872 29.4894 51.5212 29.4894 52.4255V73.7234H11.4681V13.1064H65.5319V73.7234Z"
            fill={activeColor}
          />
          <path
            d="M16.3829 31.1276H27.851C28.7554 31.1276 29.4893 30.3937 29.4893 29.4893V18.0212C29.4893 17.1169 28.7554 16.3829 27.851 16.3829H16.3829C15.4786 16.3829 14.7446 17.1169 14.7446 18.0212V29.4893C14.7446 30.3937 15.4786 31.1276 16.3829 31.1276ZM18.0212 19.6595H26.2127V27.851H18.0212V19.6595Z"
            fill={activeColor}
          />
          <path
            d="M49.1489 31.1276H60.617C61.5213 31.1276 62.2553 30.3937 62.2553 29.4893V18.0212C62.2553 17.1169 61.5213 16.3829 60.617 16.3829H49.1489C48.2446 16.3829 47.5106 17.1169 47.5106 18.0212V29.4893C47.5106 30.3937 48.2446 31.1276 49.1489 31.1276ZM50.7872 19.6595H58.9787V27.851H50.7872V19.6595Z"
            fill={activeColor}
          />
          <path
            d="M16.3829 49.149H27.851C28.7554 49.149 29.4893 48.415 29.4893 47.5107V36.0426C29.4893 35.1383 28.7554 34.4043 27.851 34.4043H16.3829C15.4786 34.4043 14.7446 35.1383 14.7446 36.0426V47.5107C14.7446 48.415 15.4786 49.149 16.3829 49.149ZM18.0212 37.6809H26.2127V45.8724H18.0212V37.6809Z"
            fill={activeColor}
          />
          <path
            d="M49.1489 49.149H60.617C61.5213 49.149 62.2553 48.415 62.2553 47.5107V36.0426C62.2553 35.1383 61.5213 34.4043 60.617 34.4043H49.1489C48.2446 34.4043 47.5106 35.1383 47.5106 36.0426V47.5107C47.5106 48.415 48.2446 49.149 49.1489 49.149ZM50.7872 37.6809H58.9787V45.8724H50.7872V37.6809Z"
            fill={activeColor}
          />
        </svg>
      </Icon>

      {title}
    </LocationButton>
  )
}

const HomeIcon = ({
  alt,
  activeColor = '#FFFFFF',
  // inactiveColor = '#0B64EB',
  // isActive = false,
  location,
  name,
  onBlur,
  onClick,
  onFocus,
  onMouseOver,
  onMouseOut,
  onTouchCancel,
  onTouchEnd,
  onTouchStart,
  title = 'Home'
}) => {
  const [value, setValue] = InputState(title === location)

  const onSelect = event => {
    setValue(event)
    onClick(event)
  }

  return (
    <LocationButton
      htmlFor={name}
      onBlur={onBlur}
      onClick={onSelect}
      onFocus={onFocus}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onTouchCancel={onTouchCancel}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
    >
      <input name={name} onChange={onSelect} type="checkbox" value={value} />
      <Icon>
        <svg
          alt={alt}
          width="77"
          height="77"
          viewBox="0 0 77 77"
          fill="none"
          title={title}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M75.7068 34.422L69.2019 28.495V8.84147C69.2019 7.59097 68.1881 6.57704 66.9375 6.57704H51.2796C50.0291 6.57704 49.0151 7.59082 49.0151 8.84147V10.1008L41.1593 2.9427C39.6429 1.56076 37.3563 1.56091 35.8405 2.94255L1.29304 34.4222C0.078481 35.529 -0.322912 37.2345 0.27053 38.7668C0.864122 40.2991 2.30938 41.2891 3.95269 41.2891H9.47038V72.8292C9.47038 74.0797 10.4842 75.0936 11.7348 75.0936H30.6709C31.9214 75.0936 32.9354 74.0799 32.9354 72.8292V53.6791H44.0644V72.8294C44.0644 74.0799 45.0782 75.0938 46.3289 75.0938H65.2642C66.5147 75.0938 67.5287 74.08 67.5285 72.8294V41.2893H73.0474C74.6906 41.2893 76.136 40.2991 76.7296 38.7669C77.3229 37.2345 76.9216 35.529 75.7068 34.422ZM65.2642 36.7604C64.0137 36.7604 62.9998 37.7742 62.9998 39.0249V70.5649H48.5934V51.4146C48.5934 50.1641 47.5795 49.1502 46.329 49.1502H30.6709C29.4204 49.1502 28.4065 50.164 28.4065 51.4146V70.5649H13.9991V39.0249C13.9991 37.7744 12.9853 36.7604 11.7347 36.7604H5.45089L38.5001 6.64622L49.7548 16.9015C50.4177 17.5055 51.3746 17.6611 52.1947 17.2991C53.0149 16.9367 53.5442 16.1243 53.5442 15.2277V11.1059H64.6734V29.4951C64.6734 30.1323 64.9417 30.7397 65.4127 31.1689L71.5491 36.7604H65.2642Z"
              fill={activeColor}
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="77" height="77" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Icon>

      {title}
    </LocationButton>
  )
}

export const ResidentialLocationStage = forwardRef(({ location, onClick, options }, ref) => {
  // Expose getValue method to parent through ref
  useImperativeHandle(ref, () => ({
    getValue: () => location || '' // Ensure we return an empty string if location is undefined
  }))

  const locations = options.map(option => {
    const [apartmentToggled, setApartmentToggled] = ToggleState(false)
    const [homeToggled, setHomeToggled] = ToggleState(false)
    const alt = `option to select ${option} cleaning`

    if (option === 'Apartment') {
      return (
        <ApartmentIcon
          key={option}
          alt={alt}
          isActive={apartmentToggled}
          location={location}
          name={`Apartment`}
          onBlur={event => setApartmentToggled(false)}
          onFocus={event => setApartmentToggled(true)}
          onClick={event => onClick(event, option)}
          onMouseOver={event => setApartmentToggled(true)}
          onMouseOut={event => setApartmentToggled(false)}
          onTouchCancel={event => setApartmentToggled(false)}
          onTouchStart={event => setApartmentToggled(true)}
          onTouchEnd={event => setApartmentToggled(false)}
          title={option}
        />
      )
    }

    if (option === 'Home') {
      return (
        <HomeIcon
          key={option}
          alt={alt}
          isActive={homeToggled}
          location={location}
          name={`Home`}
          onBlur={event => setHomeToggled(false)}
          onFocus={event => setHomeToggled(true)}
          onClick={event => onClick(event, option)}
          onMouseOver={event => setHomeToggled(true)}
          onMouseOut={event => setHomeToggled(false)}
          onTouchCancel={event => setHomeToggled(false)}
          onTouchStart={event => setHomeToggled(true)}
          onTouchEnd={event => setHomeToggled(false)}
          title={option}
        />
      )
    }

    return false
  })

  return (
    <Question>
      <Title>Apartment or Home?</Title>

      <ButtonGroup style={{ marginTop: '2rem' }}>{locations}</ButtonGroup>
    </Question>
  )
})

export default ResidentialLocationStage
