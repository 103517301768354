/**
 * cleanPdfContent
 *
 * This utility function addresses an issue where a backslash appears before a comma
 * in the generated PDF output (e.g., "1. \,"). This is a temporary solution due to an
 * incompatibility between the markdown editor and the PDF renderer. Once the underlying
 * issue is resolved in either component, this fix may no longer be necessary.
 *
 * Usage:
 *   const cleanedContent = cleanPdfContent(originalContent);
 */

export const cleanPdfContent = content => {
    // Remove any backslashes immediately preceding a comma.
    return content.replace(/\\,/g, ',');
};