import legal from 'data/legal.js'

export default [
  {
    label: 'Blank',
    elements: []
  },
  {
    label: 'Standard Proposal',
    elements: [
      {
        type: 'letterhead'
      },
      {
        type: 'charges'
      },
      {
        type: 'service_frequency'
      },
      {
        type: 'scope_of_work'
      },
      {
        type: 'areas'
      },
      {
        type: 'rich_text',
        create: (proposal) => {
          let data = {}

          let markdown = 
            `The Undersigned customer, **${proposal.client?.name || ''}** (hereafter referred to as "CUSTOMER") hereby accepts the proposal of **${proposal.merchant?.name || ''}** (hereafter referred to as "CONTRACTOR") for CUSTOMER's premises located at ***${proposal.location?.address?.fullAddress || ''}***.\n\n` +
            'Upon the following terms:\n\n' +
            legal +
            '\n\n'

          data.markdown = markdown
          data.headingText = 'Service Agreement'
          data.showHeading = true
          return data
        }
      },
      {
        type: 'signatures'
      }
    ]
  },
  {
    label: 'Scope of Work Doc',
    elements: [
      {
        type: 'letterhead'
      },
      {
        type: 'service_frequency'
      },
      {
        type: 'scope_of_work'
      },
      {
        type: 'areas'
      }
    ]
  }
]
