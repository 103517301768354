import React from 'react'
import {
  FiEdit,
  FiImage,
  FiLayout,
  FiMinus,
  FiType,
  FiPenTool,
  FiTrello,
  FiList
} from 'react-icons/fi'
import { FaFileInvoiceDollar, FaRegCalendarTimes, FaCalendarAlt, FaGripLines } from 'react-icons/fa'
import { FaExpand } from 'react-icons/fa'
import { capitalize } from 'utils'
import legal from 'data/legal.js'
import { applyPlaceholders, transformForStorage } from 'utils/autofill'
import { marked } from 'marked'

import AreasRenderer from './components/renderers/Areas'
import ChargesRenderer from './components/renderers/Charges'
import DateRangeRenderer from './components/renderers/DateRange'
import DividerRenderer from './components/renderers/Divider'
import HeadingRenderer from './components/renderers/Heading'
import ImageRenderer from './components/renderers/Image'
import LetterheadRenderer from './components/renderers/Letterhead'
import RichTextRenderer from './components/renderers/RichText'
import ScopeOfWorkRenderer from './components/renderers/ScopeOfWork'
import ServiceFrequencyRenderer from './components/renderers/ServiceFrequency'
import SignaturesRenderer from './components/renderers/Signatures'

export default [
  {
    type: 'rich_text',
    label: 'Rich Text',
    tooltip: 'A multi purpose text editor that supports titles, paragraphs, bullet points, tables and dynamic placeholders.',
    allowCustomElements: true,
    icon: FiEdit,
    initialData: {
      markdown: `Add your content here.`
    },
    render: ({ element, proposal }) => {
      let markdown = element.data.markdown;
      if (proposal) {
        markdown = applyPlaceholders(markdown, proposal);
      }

      return <RichTextRenderer element={{...element, data: {...element.data, markdown}}} />
    },
    print: (element, proposal) => {
      let markdown = element.data.markdown;
      if (proposal) {
        markdown = applyPlaceholders(markdown, proposal);
      }
      
      try {
        // Convert markdown to HTML with proper sanitization
        return marked(markdown, {
          sanitize: true,
          breaks: true,
          gfm: true,
          tables: true,
          smartLists: true
        });
      } catch (error) {
        // Fallback to basic conversion if marked fails
        return markdown
          .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
          .replace(/\*(.*?)\*/g, '<em>$1</em>')
          .replace(/\_\_(.*?)\_\_/g, '<strong>$1</strong>')
          .replace(/\_(.*?)\_/g, '<em>$1</em>')
          .replace(/\n/g, '<br />');
      }
    },
    create: (proposal, options = {}) => {
      let data = {}
      
      let markdown = `Add your content here.`

      data.markdown = markdown;
      return data;
    }
  },
  {
    type: 'heading',
    label: 'Heading',
    tooltip:
      'Adds larger title text with customizable size, color and alignment.',
    allowCustomElements: true,
    icon: FiType,
    initialData: {
      text: 'Heading',
      type: {
        label: 'Heading 2',
        size: 'h2'
      }
    },
    render: ({ element }) => <HeadingRenderer element={element} />
  },
  {
    type: 'divider',
    label: 'Divider',
    allowCustomElements: true,
    tooltip: 'Add a thin line between elements with customizable thickness.',
    icon: FiMinus,
    initialData: {
      color: 'black',
      height: 1
    },
    render: ({ element }) => <DividerRenderer element={element} />
  },
  {
    type: 'spacer',
    label: 'Spacer',
    tooltip: 'Add a space between elements with customizable height.',
    allowCustomElements: true,
    icon: FaExpand,
    initialData: {
      height: 100
    },
    render: ({ element, renderForPdf }) => {
      if (renderForPdf === false) {
        return null;
      }
      return (
        <div
          style={{
            height: element.data.height || 20,
            backgroundColor: element.data.color || 'white'
          }}
        />
      )
    }
  },
  {
    type: 'page_break',
    label: 'Page Break',
    tooltip: 'Insert a page break in the PDF.',
    allowCustomElements: false,
    icon: FaGripLines,
    initialData: {},
    render: ({ element, renderForPdf }) => (
      <>
        {!renderForPdf && (
          <div style={{
            backgroundColor: "#2e2f31",
            margin: "40px -15px",
            padding: "20px",
          }}>
            <div
              className="custom-page-break"
              style={{
                width: "100%",
                height: "30px",
                padding: "40px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                fontSize: "20px",
                color: "white",
                backgroundColor: "#2e2f31",
                borderTop: "0.1em dashed #ccc",
                borderBottom: "0.1em dashed #ccc",
              }}
            >
              Page Break
              <div style={{ fontSize: "14px", fontStyle: "italic" }}>
                Review the Preview to confirm that your page breaks appear exactly as you intend.
              </div>
            </div>
          </div>
        )}
        <div style={{ pageBreakAfter: 'always' }}></div>
      </>
    )
  },
  {
    type: 'image',
    label: 'Image',
    allowCustomElements: true,
    tooltip:
      'Use any image in your proposal. Customizable cropping, width and alignment.',

    icon: FiImage,
    initialData: {
      image: {
        url: 'https://via.placeholder.com/800x400?text=Choose+Image'
      },
      size: 100
    },
    render: ({ element }) => <ImageRenderer element={element} />
  },
  {
    type: 'areas',
    label: 'Areas',
    tooltip: 'Pull in area information from your walkthrough or location data.',
    allowCustomElements: false,
    icon: FiLayout,
    create: (proposal, options = {}) => {
      let areas = []
      let source
      if (proposal.job && proposal.job.areas && proposal.job.areas.length) {
        areas = [...proposal.job.areas.map(area => ({ ...area, pageBreak: false }))]
        source = 'Walkthrough'
      } else if (
        proposal.location &&
        proposal.location.areas &&
        proposal.location.areas.length
      ) {
        areas = [...proposal.location.areas.map(area => ({ ...area, pageBreak: false }))]
        source = 'Location'
      }
      return {
        areas,
        source,
        showHeading: true,
        includeSquareFootage: true,
        includeFixtures: true,
        includeImages: true,
        includeNotes: true,
        showFixtureAggregate: true,
        showSquareFootageTotals: true
      }
    },
    render: ({ element, proposal, renderForPdf }) => (
      <AreasRenderer 
        element={element} 
        proposal={proposal} 
        renderForPdf={renderForPdf} 
      />
    )
  },

  {
    type: 'signatures',
    label: 'Signatures',
    tooltip: 'Adds preset lines to capture signatures in a paper proposal.',
    allowCustomElements: true,
    icon: FiPenTool,
    initialData: {},
    create: (proposal, options = {}) => {
      let serviceProvider = proposal.merchant.name
      let client = proposal.client.name
      return { serviceProvider, client, showHeading: true }
    },
    render: ({ element, proposal }) => (
      <SignaturesRenderer element={element} proposal={proposal} />
    )
  },

  {
    type: 'letterhead',
    label: 'Letterhead',
    tooltip:
      'Creates a customizable cover page with your company and client info.',
    allowCustomElements: true,
    icon: FiTrello,
    initialData: {
      title: 'Service Proposal'
    },
    create: (proposal, options = {}) => {
      if (proposal) {
        let title = 'Service Proposal'
        let code = (proposal && proposal.code) || ''
        let merchantName =
          (proposal && proposal.merchant && proposal.merchant.name) || ''
        let merchantAddress =
          (proposal &&
            proposal.merchant &&
            proposal.merchant.address &&
            proposal.merchant.address.fullAddress) ||
          ''
        let merchantLogo =
          proposal && proposal.merchant && proposal.merchant.logo
        let clientName =
          (proposal && proposal.client && proposal.client.name) || ''
        let clientAddress =
          // (proposal &&
          //   proposal.client &&
          //   proposal.client.address &&
          //   proposal.client.address.fullAddress) ||
          (proposal &&
            proposal.location &&
            proposal.location.address &&
            proposal.location.address.fullAddress) ||
          ''
        let clientLogo = proposal && proposal.client && proposal.client.logo
        let proposalDate = proposal && proposal.createdAt
        return {
          title,
          code,
          merchantName,
          merchantAddress,
          merchantLogo,
          clientName,
          clientAddress,
          clientLogo,
          showClientLogo: true,
          showMerchantLogo: true,
          showProposalCode: true,
          proposalDate,
          showProposalDate: true,
          letterheadHeight: 50
        }
      }
      return {}
    },
    render: ({ element, proposal }) => (
      <LetterheadRenderer element={element} proposal={proposal} />
    )
  },

  {
    type: 'scope_of_work',
    label: 'Scope of Work',
    tooltip:
      'Completely editable scope of work (section or area tasks and frequencies) with built in templates based on your services.',
    allowCustomElements: true,
    icon: FiList,
    initialData: {
      taskSections: [
        {
          name: 'Task Section',
          tasks: [
            {
              name: 'Add tasks',
              frequency: 'Daily'
            }
          ]
        }
      ],
      showHeading: true,
      textSize: 12
    },

    render: ({ element, proposal, renderForPdf }) => (
      <ScopeOfWorkRenderer element={element} proposal={proposal} renderForPdf={renderForPdf} />
    )
  },
  {
    type: 'charges',
    label: 'Charges',
    tooltip: 'Completely editable service charges and line items.',
    allowCustomElements: false,
    icon: FaFileInvoiceDollar,
    create: (proposal, options = {}) => {
      const estimateServices =
        proposal && proposal.estimate && proposal.estimate.services
      let chargeSections = []

      if (estimateServices && estimateServices.length) {
        estimateServices.forEach((service, index) => {
          if (service.result) {
            chargeSections.push({
              name: capitalize(service.service.name),
              id: `service${index}`,
              interval: service.result.jobCount.interval,
              charges: [
                {
                  name: 'Base Rate',
                  price: service.result.charges.baseRate
                },
                {
                  name: 'Supplies and Materials',
                  price: service.result.charges.suppliesAndMaterials
                }
              ]
            })
          }
        })
      }

      return { title: 'Service Charges', showHeading: true, chargeSections, textSize: 12 }
    },
    render: ({ element, proposal }) => (
      <ChargesRenderer element={element} proposal={proposal} />
    )
  },
  {
    type: 'service_frequency',
    label: 'Service Frequency',
    tooltip: 'A simple component to render a weekly or custom job schedule.',
    icon: FaRegCalendarTimes,
    initialData: {
      showHeading: true,
      serviceFrequency: 'Daily',
      selectedDays: {}
    },
    render: ({ element, proposal }) => (
      <ServiceFrequencyRenderer element={element} proposal={proposal} />
    )
  },
  {
    type: 'service_dates',
    label: 'Service Dates',
    tooltip: 'Select start and end dates for the proposed services.',
    icon: FaCalendarAlt,
    initialData: {
      showHeading: true
    },
    render: ({ element, proposal }) => (
      <DateRangeRenderer element={element} proposal={proposal} />
    )
  },
  {
    type: 'rich_text',
    label: 'Service Agreement',
    tooltip: 'A standard service agreement.',
    allowCustomElements: true,
    icon: FiEdit,
    render: ({ element }) => <RichTextRenderer element={element} />,
    print: element => {
      const markdown = element.data.markdown;
      
      try {
        // Convert markdown to HTML with proper sanitization
        return marked(markdown, {
          sanitize: true,
          breaks: true,
          gfm: true,
          tables: true,
          smartLists: true
        });
      } catch (error) {
        // Fallback to basic conversion if marked fails
        return markdown
          .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
          .replace(/\*(.*?)\*/g, '<em>$1</em>')
          .replace(/\_\_(.*?)\_\_/g, '<strong>$1</strong>')
          .replace(/\_(.*?)\_/g, '<em>$1</em>')
          .replace(/\n/g, '<br />');
      }
    },
    create: (proposal, options = {}) => {
      let data = {};
      let markdown = `The Undersigned customer, **${proposal.client?.name || ''}** (hereafter referred to as "CUSTOMER") hereby accepts the proposal of **${proposal.merchant?.name || ''}** (hereafter referred to as "CONTRACTOR") for CUSTOMER's premises located at ***${proposal.location?.address?.fullAddress || ''}***.\n\n` +
        `Upon the following terms:\n\n` +
        `CUSTOMER acknowledges that CONTRACTOR will delegate all Services to be performed as an Independent Contractor.\n\n` +
        `Included in the Service Charge will be service, supplies, and any equipment which will be furnished by CONTRACTOR. The Service Charge does not include any use tax, tax on sales, services or supplies, or other such tax, which taxes shall be paid by CUSTOMER. CUSTOMER agrees to reimburse CONTRACTOR the amount of any such taxes if paid by CONTRACTOR, on CUSTOMER's behalf.\n\n` +
        `All Services specified in the Task Schedule section of this Proposal will be provided to CUSTOMER in a satisfactory manner. CUSTOMER acknowledges that only those Services and/or Additional Services specifically identified in the Scope of Work will be provided under this Service Agreement.\n\n` +
        `CONTRACTOR maintains and carries the required General Liability and Umbrella Insurance.\n\n` +
        `The Service Charge will remain in effect for one year unless there are changes in the original specifications for the premises. In the event of such changes, CUSTOMER will advise CONTRACTOR accordingly, and an adjustment in the Service Charge, as agreed to by the parties, will be made.\n\n` +
        `Services shall be performed as stated in the Task Schedule section of this Proposal except for the following six (6) legal holidays: New Year's Day, Memorial Day, Independence Day, Labor Day, Thanksgiving Day and Christmas Day. No Service Charge credits will be issued for these holidays. However, service can be provided on these holidays at an additional cost if required. Services shall be scheduled during the hours approved or directed by manager/owner.\n\n` +
        `The undersigned warrant and represent that they have full authority to enter into this Service Agreement, and that it will be binding upon the parties and their respective successors and assigns.\n\n` +
        `Any information, suggestions, or ideas transmitted by CUSTOMER to CONTRACTOR in connection with products ordered by CUSTOMER are not to be regarded as secret or submitted in confidence except as may be otherwise provided in writing signed by an officer of the Company.\n\n` +
        `All Parties contributed to the drafting of the Agreement and no presumption for or against any party to this Agreement shall arise as a result of the drafting. Nothing contained in this Agreement shall be construed to require the commission of any act contrary to law, and wherever there is any conflict between any provision of this Agreement and any present or future statute, law, governmental regulation or ordinance contrary to which the Parties have no legal right to contract, the latter shall prevail, but in such event, the affected provisions of this Agreement shall be curtailed and restricted only to the extent necessary to bring them within legal requirements.\n\n` +
        `This Service Agreement constitutes the complete agreement of the parties concerning the provision of services to the CUSTOMER and supersedes all other prior or contemporaneous agreements between the parties, whether written or oral, on the same subject. No waiver or modification of this Service Agreement shall be valid unless\n\n` +
        `This Agreement may be executed in counterparts and via facsimile or PDF, and may be distributed via email, none of which shall affect the validity or enforceability of this agreement.\n\n` +
        `CUSTOMER to be billed Bi-weekly and CUSTOMER agrees to pay CONTRACTOR the amount that is due and owing under the terms of this Service Agreement within 15 days of billing date. Late payments may accrue finance charges as referenced herein. CONTRACTOR can receive payment in the form of a check, ACH or credit card on file.\n\n` +
        `Term of the Service Agreement is for one year. The one-year period shall begin on the date services are scheduled to begin. CUSTOMER can cancel with a 30-day written notice if they are unsatisfied with their services. CUSTOMER must agree to give CONTRACTOR ample time to attempt to rectify any complaints or concerns with service.\n\n` +
        `Service Contract will automatically renew if CUSTOMER does not notify CONTRACTOR of any revisions. In return if anything changes with agreement, the CONTRACTOR will notify the CUSTOMER\n\n` +
        `CONTRACTOR reserves the right to charge a monthly service charge equal to the greatest amount allowed by applicable law, or as agreed upon by CONTRACTOR and CUSTOMER, payable on all overdue balances.\n\n` +
        `Labor rate is based upon current minimum wage rates and are subject to change state by state. If minimum wage increases, then there will be an equal percentage increase to contract service agreement upon renewal date.\n\n`;
      data.markdown = markdown;
      data.headingText = 'Service Agreement';
      data.showHeading = true;
      return data;
    }
  }
]
