import React, { PureComponent } from 'react'
import { GoogleApiWrapper } from '../utils/GoogleApiComponent'

import { placeSchema } from 'utils'
import { formatText } from 'utils/formatters'

class GooglePlaceInput extends PureComponent {
  constructor(props) {
    super(props)
    this.autocomplete = null
    this.state = { 
      error: '',
      typedValue: '',
      isAutocompleteVisible: false,
      selectedAddress: null
    }
    this.renderAutocomplete = this.renderAutocomplete.bind(this)
    this.formatLocation = this.formatLocation.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    const { google } = this.props
    if (!google) {
      return
    }
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteRef,
      { types: ['geocode'] }
    )
    this.autocomplete.addListener('place_changed', this.renderAutocomplete)
  }

  async renderAutocomplete() {
    const place = await this.autocomplete.getPlace()
    let countryCode
    let number

    if (!place.geometry) {
      return
    }

    if (place.hasOwnProperty('formatted_phone_number')) {
      countryCode = '+1'
      number = place.formatted_phone_number.replace('+1', '')
    } else {
      countryCode = ''
      number = ''
    }

    const latitude = place.geometry.location.lat()
    const longitude = place.geometry.location.lng()

    let location = this.formatLocation(
      place.address_components.reduce(
        (loc, component) => ({
          ...loc,
          id: place.id,
          formattedAddress: place.formatted_address,
          latitude,
          longitude,
          countryCode,
          number,
          googleMapLink: place.url,
          [component.types[0]]: component.short_name
        }),
        {}
      )
    )

    this.setState({ 
      typedValue: '', 
      isAutocompleteVisible: false,
      selectedAddress: location
    })
    
    await this.props.onChange(location)
  }

  formatLocation = location =>
    Object.keys(location).reduce(
      (formatted, key) => ({
        ...formatted,
        ...{ [placeSchema[key] || key]: location[key] }
      }),
      {}
    )

  onChange = (e) => {
    this.setState({ typedValue: e.target.value })
    
    if (this.props.error) {
      this.setState({ error: `${this.props.meta.error}` })
    } else {
      this.setState({ error: '' })
    }
  }

  toggleAutocomplete = event => {
    event.target.autocomplete = 'hidden';
  }

  toggleField = event => {
    event.preventDefault();
    this.setState({ isAutocompleteVisible: true }, () => {
      if (this.autocompleteRef) {
        this.autocompleteRef.focus();
      }
    });
  }

  handleBlur = () => {
    setTimeout(() => {
      this.setState({ isAutocompleteVisible: false });
    }, 200);
  }

  render() {
    const { name, value, inputId, className, style } = this.props
    const { typedValue, isAutocompleteVisible, selectedAddress } = this.state
    const placeholder = this.props.placeholder || 'Enter address'

    const displayAddress = selectedAddress?.formattedAddress || 
                          value?.formattedAddress || 
                          '';
    
    const commonInputStyle = {
      width: '100%', 
      background: 'transparent',
      border: 'none',
      padding: '8px 10px',
      fontSize: '14px',
      height: '38px',
      boxSizing: 'border-box',
      outline: 'none',
      color: '#333',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    };

    return (
      <div style={{ 
        position: 'relative', 
        width: '100%', 
        minHeight: '38px',
        ...style
      }}>
        <input
          id={inputId || 'formattedAddress'}
          autoComplete={'hidden'}
          onFocus={this.toggleField}
          placeholder={placeholder}
          readOnly
          className={className}
          style={{ 
            ...commonInputStyle,
            display: isAutocompleteVisible ? 'none' : 'block',
            cursor: 'pointer'
          }}
          type={this.props.type}
          value={displayAddress}
          onKeyPress={e => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        />
        <input
          {...this.props}
          id={`${inputId || ''}autocomplete`}
          autoComplete={'hidden'}
          name={name}
          loaded={this.props.loaded.toString()}
          onChange={this.onChange}
          placeholder={placeholder}
          className={className}
          ref={ref => (this.autocompleteRef = ref)}
          type={this.props.type}
          error={this.state.error}
          onFocus={this.toggleAutocomplete}
          onBlur={this.handleBlur}
          style={{ 
            ...commonInputStyle,
            display: isAutocompleteVisible ? 'block' : 'none'
          }}
          value={typedValue}
          onKeyPress={e => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        />
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries: ['places']
})(GooglePlaceInput)
