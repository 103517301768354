import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { axiosClient } from 'store'
import { toast } from 'react-toastify'
import { calculateProposalTotals, scopeOfWorkToString } from 'screens/Dashboard/Proposals/Proposal2/util'
import Elements from 'screens/Dashboard/Proposals/Proposal2/Elements'
import { floorTypeSubtotals, getTimezone } from 'utils'
import { ToastCloseButton } from 'components/lib/Button'
import { updateCompany, connectPulseAccount, connectSweptAccount } from 'api'

const details = ['No Account', 'No Sync', 'Free Plan']

export const useSyncLocation = (proposalData = null) => {
  console.log({proposalData})
  const [ proposal, setProposal] = useState(proposalData)
  const [ pulseAccountId, setPulseAccountId ] = useState(proposal?.merchant?.pulseAccountId || null)
  const [ pulseConnectBusy, setPulseConnectBusy ] = useState(false)
  const [ pulseLocationExists, setPulseLocationExists ] = useState(proposal?.location?.pulseLocationId ?? false)
  const [ sweptLocationExists, setSweptLocationExists ] = useState(proposal?.location?.sweptLocationId ?? false)
  const [ showVerifyModal, setShowVerifyModal ] = useState(false)
  const [ showPulseConnect, setShowPulseConnect ] = useState(false)
  const [ detailAcountPulse, setDetailAcountPulse ] = useState('')
  const [ showSweptConnect, setShowSweptConnect ] = useState(false)
  const [ sweptConnectBusy, setSweptConnectBusy ] = useState(false)

  const { currentCompany: company, currentEmployment } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  let elements = []

  if ( proposal && proposal?.data?.elements ) {
    elements = proposal?.data?.elements.filter(e => Elements.find(o => o.type === e.type))
  }

  const sendPulseLocation = async () => {
    console.log({company})
    const verifyAcountPulse = await axiosClient.get(
      `/api/locations/verifyPulseAcount/${company._id}`
    )
    const { detail } = verifyAcountPulse.data

    if (details.includes(detail)) {
      detailAcountPulse(detail)
      setShowVerifyModal(true)

      return
    }
    if (!proposal) {
      toast('The proposal is not ready for this.')
      return
    }
    if (!proposal.merchant.pulseAccountId) {
      setShowPulseConnect(true)
      return
    }
    setPulseConnectBusy(true)
    let { revenueData = null } = proposal
    
    if (!revenueData) {
      revenueData = await calculateProposalTotals(elements)
      let areas = []
      const elementArea = elements.find( item => item.type === 'areas' )
      areas = [
        ...( elementArea ? elementArea.data.areas : [] )
      ]
      
      if (!areas?.length) {
        areas = proposal?.walkthrough?.areas
      }
      if (!areas?.length) {
        areas = proposal?.location?.areas
      }
      if (areas?.length) {
        const floorSubtotals = await floorTypeSubtotals(areas)
        revenueData.totalSqFt = floorSubtotals.total
      }
    }
    const scopeOfWorkString = scopeOfWorkToString(elements)

    const { location } = proposal

    const { address, pulseLocationId } = location

    let pulseData = {
      LocationAddress: {
        address: address.formattedAddress,
        lat: address.latitude,
        lng: address.longitude
      },
      LocationName: location.name,
      LocationPhoneNumber: location.contact?.phone,
      LocationEmail: location.contact?.email,
      addressCity: address.city,
      addressCountry: address.country,
      addressState: address.state,
      contractValue: revenueData.yearlyTotal,
      Area: revenueData.totalSqFt
    }

    if (scopeOfWorkString) {
      pulseData['CleaningInstructions'] = scopeOfWorkString
    }

    if (pulseLocationId) {
      return updatePulseLocation(location.id, pulseData)
    } else {
      return createPulseLocation(location.id, pulseData)
    }

  }
  
  const createPulseLocation = async (locationId, data) => {
    try {
      const response = await axiosClient.post(
        `/api/locations/${locationId}/createPulseLocation`,
        data
      )
      if (response && response.data) {
        toast('Your location was successfully created in Pulse!', {
          className: 'toast-success',
          closeButton: ToastCloseButton
        })
        console.log('location response: ', response.data)
        setPulseConnectBusy(false)
        setProposal({
          ...proposal,
          location: response.data
        })
        return {
          ...proposal,
          location: response.data
        }
      }
    } catch (err) {
      const { message, error } = err.response.data
      console.error('Pulse error: ', error)
      toast(message, {
        className: 'toast-error',
        closeButton: ToastCloseButton
      })
    }
  }

  const updatePulseLocation = async (locationId, data) => {
    try {
      let res = await axiosClient.patch(
        `/api/locations/${locationId}/updatePulseLocation`,
        data
      )
      console.log({res})
      if (res && res.data) {
        if (res.data.error != null) {
          // this.setState({})
          createPulseLocation(locationId, data)
          return
        }
        setPulseConnectBusy(false)
        setProposal({
          ...proposal,
          location
        })
        return {
          ...proposal
        }
      }
    } catch (err) {
      const { message, error, location } = err.response.data
      if (message === 'Pulse location not found') {
      }
      console.error('Pulse error: ', error)
      toast(message, {
        className: 'toast-error',
        closeButton: ToastCloseButton
      })
      return
    }
    toast('Your Pulse location was successfully updated!', {
      className: 'toast-success',
      closeButton: ToastCloseButton
    })
  }

  const onPulseConnect = async (values) => {
    
    try {
      setPulseConnectBusy(true)
      return dispatch( connectPulseAccount() )
      .then( async (response) => {
        if (response && response.payload && response.payload.data) {
          const { pulseAccountId } = response.payload.data
          setPulseAccountId( pulseAccountId)
          await sendPulseLocation()
          setPulseConnectBusy(false)
          setShowPulseConnect(false)
          toast('Successfully connected to your Pulse account', {
            className: 'toast-success',
            closeButton: ToastCloseButton
          })

          return {
            ...proposal,
            merchant: {
              pulseAccountId
            }
          }

        } else if (res && res.error && res.error) {
          throw res.error
        }
      } )
    } catch (err) {
      console.error('Pulse error: ', err)
      setPulseConnectBusy(false)
      setShowPulseConnect(false)

      toast('There was a problem connecting to Pulse.', {
        className: 'toast-error',
        closeButton: ToastCloseButton
      })
    }

  }

  const sendSweptLocation = async () => {
    if (!proposal) {
      toast('The proposal is not ready for this.')
      return
    }
    const scopeOfWorkString = scopeOfWorkToString(elements)
    const { client, location } = proposal

    if (!currentEmployment) {
      return
    }

    if (!currentEmployment.sweptToken) {
      setShowSweptConnect(true)
      return
    }
    if (!client || !location) {
      toast('Proposal needs a location and client to send data to Swept.')
    }
    const { address, sweptLocationId } = location

    let timezone
    if (!address.timezone) {
      timezone = await getTimezone(address.latitude, address.longitude)
    } else {
      timezone = address.timezone
    }

    let sweptData = {
      token: `Bearer ${currentEmployment.sweptToken}`,
      city: address.city,
      cleaning_instructions_language: 'en',
      country: address.country,
      is_verified: true, //all our addresses should be verified in Google
      name: client.name,
      postal_code: address.zipcode,
      state: address.state,
      street_address: address.streetNumber + address.route,
      timezone: timezone && timezone.timeZoneId
    }

    if (scopeOfWorkString) {
      sweptData['cleaning_instructions'] = scopeOfWorkString
    }
    if (sweptLocationId) {
      updateSweptLocation(location.id, sweptData)
    } else {
      createSweptLocation(location.id, sweptData)
    }
  }
  const createSweptLocation = async (locationId, data) => {
    try {
      const response = await axiosClient.post(
        `/api/locations/${locationId}/createSweptLocation`,
        data
      )
      if (response && response.data) {
        toast('Your location was successfully created in Swept!', {
          className: 'toast-success',
          closeButton: ToastCloseButton
        })
        return {
          ...proposal,
          location: response.data
        }
      }
    } catch (err) {
      const { message, error } = err.response.data
      console.error('Swept error: ', error)
      toast(message, {
        className: 'toast-error',
        closeButton: ToastCloseButton
      })
    }
  }

  
  const updateSweptLocation = async (locationId, data) => {
    try {
      await axiosClient.patch(
        `/api/locations/${locationId}/updateSweptLocation`,
        data
      )
      toast('Your Swept location was successfully updated!', {
        className: 'toast-success',
        closeButton: ToastCloseButton
      })
    } catch (err) {
      const { message, error, location } = err.response.data
      if (message === 'Swept location not found') {
        return {
          ...proposal,
          location
        }
      }
      console.error('Swept error: ', error)
      toast(message, {
        className: 'toast-error',
        closeButton: ToastCloseButton
      })
    }
  }

  const onSweptConnect = async (values) => {
    const { username, password } = values
    try {
      setSweptConnectBusy(true)
      
      return dispatch( connectSweptAccount(currentEmployment._id, {
        username,
        password
      }) )
      .then( async (response) => {
        if (response && response.payload && response.payload.data) {
            
          toast('Successfully connected to your Swept account', {
            className: 'toast-success',
            closeButton: ToastCloseButton
          })
          setSweptConnectBusy(false)
          setShowSweptConnect(false)
          
        } else if (response && response.error && response.error) {
          throw response.error
        }
      } )
    } catch (err) {
      console.error('Swept error: ', err)
      const { message } = err && err.response && err.response.data
      setSweptConnectBusy(false)
      
      toast(message, {
        className: 'toast-error',
        closeButton: ToastCloseButton
      })
    }
  }

  return {
    pulseAccountId,
    setPulseAccountId,
    pulseConnectBusy,
    setPulseConnectBusy,
    pulseLocationExists,
    setPulseLocationExists,
    sweptLocationExists,
    setSweptLocationExists,
    showVerifyModal,
    setShowVerifyModal,
    showPulseConnect,
    setShowPulseConnect,
    detailAcountPulse,
    setDetailAcountPulse,
    
    showSweptConnect,
    setShowSweptConnect,
    sweptConnectBusy,
    setSweptConnectBusy,

    sendPulseLocation,
    sendSweptLocation,
    onPulseConnect,
    onSweptConnect,
  }
}