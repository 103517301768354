import React, { Component } from 'react'
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css'

class MarkdownEditor extends Component {

  constructor(props) {
    super(props)

    this.state = {
      handlePasteImg: props.handlerPaste
    }
  }

  componentDidMount() {
    let editor = new Editor({
      el: document.querySelector(
        this.props.editorId ? `#${this.props.editorId}` : '#editSection'
      ),
      initialEditType: 'wysiwyg',
      previewStyle: 'vertical',
      height: this.props.height ? this.props.height : '300px',
      events: {
        change: this.onChange,
      },
      toolbarItems: [
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'indent', 'outdent'],
        ['table', 'link']
      ],
      hideModeSwitch: true,
      initialValue: this.props.value,
      hooks: {
        addImageBlobHook: this.addImageBlobHook
      }
    })

    this.setState({ editor }, () => {
      // Pass editor instance to parent through callback
      if (this.props.onEditorCreated) {
        this.props.onEditorCreated(editor);
      }
    })
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.value !== prevProps.value) {
      const editorValue = this.state.editor.getMarkdown()
      if (editorValue !== this.props.value) {
        this.state.editor.setMarkdown(this.props.value)
      }
    }
  }

  onChange = change => {
    // console.log('editor change: ', change)
    // console.log('editor html: ', this.state.editor.getHtml())
    // console.log('editor value: ', this.state.editor.getValue())
    if (this.props.onChange) {
      this.props.onChange(this.state.editor.getMarkdown())
    }
  }

  addImageBlobHook = event => {
    const { handlePasteImg } = this.state
    handlePasteImg(true)
  }

  render() {
    const editorId = this.props.editorId ? this.props.editorId : 'editSection'
    return <div id={editorId} />
  }
}

export default MarkdownEditor
