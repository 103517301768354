export const US_STATES = {
  alabama: {
    name: 'Alabama',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  alaska: {
    name: 'Alaska',
    hourly: 10.19,
    commercial: {
      office: 0.2,
      restaurant: 0.2,
      other: 0.2,
      healthcare: 0.2
    }
  },
  arizona: {
    name: 'Arizona',
    hourly: 12.0,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  arkansas: {
    name: 'Arkansas',
    hourly: 10.0,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  california: {
    name: 'California',
    hourly: 13.0,
    commercial: {
      office: 0.21,
      restaurant: 0.21,
      other: 0.21,
      healthcare: 0.21
    }
  },
  colorada: {
    name: 'Colorado',
    hourly: 12.0,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  connecticut: {
    name: 'Connecticut',
    hourly: 11.0,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  delaware: {
    name: 'Delaware',
    hourly: 9.25,
    commercial: {
      office: 0.16,
      restaurant: 0.16,
      other: 0.16,
      healthcare: 0.16
    }
  },
  district_of_columbia: {
    name: 'District of Columbia',
    hourly: 14.0,
    commercial: {
      office: 0.21,
      restaurant: 0.21,
      other: 0.21,
      healthcare: 0.21
    }
  },
  florida: {
    name: 'Florida',
    hourly: 8.56,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  georgia: {
    name: 'Georgia',
    hourly: 7.25,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  hawaii: {
    name: 'Hawaii',
    hourly: 10.1,
    commercial: {
      office: 0.25,
      restaurant: 0.25,
      other: 0.25,
      healthcare: 0.25
    }
  },
  idaho: {
    name: 'Idaho',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  illinois: {
    name: 'Illinois',
    hourly: 9.25,
    commercial: {
      office: 0.16,
      restaurant: 0.16,
      other: 0.16,
      healthcare: 0.16
    }
  },
  indiana: {
    name: 'Indiana',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  iowa: {
    name: 'Iowa',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  kansas: {
    name: 'Kansas',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  kentucky: {
    name: 'Kentucky',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  louisiana: {
    name: 'Louisiana',
    hourly: 7.25,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  maine: {
    name: 'Maine',
    hourly: 12.0,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  maryland: {
    name: 'Maryland',
    hourly: 11.0,
    commercial: {
      office: 0.28,
      restaurant: 0.28,
      other: 0.28,
      healthcare: 0.28
    }
  },
  massachusetts: {
    name: 'Massachusetts',
    hourly: 12.75,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  michigan: {
    name: 'Michigan',
    hourly: 9.65,
    commercial: {
      office: 0.19,
      restaurant: 0.19,
      other: 0.19,
      healthcare: 0.19
    }
  },
  minnesota: {
    name: 'Minnesota',
    hourly: 10.0,
    commercial: {
      office: 0.16,
      restaurant: 0.16,
      other: 0.16,
      healthcare: 0.16
    }
  },
  mississippi: {
    name: 'Mississippi',
    hourly: 7.25,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  missouri: {
    name: 'Missouri',
    hourly: 9.45,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  montana: {
    name: 'Montana',
    hourly: 8.65,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  nebraska: {
    name: 'Nebraska',
    hourly: 9.0,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  nevada: {
    name: 'Nevada',
    hourly: 8.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  new_hampshire: {
    name: 'New Hampshire',
    hourly: 7.25,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  new_jersey: {
    name: 'New Jersey',
    hourly: 11.0,
    commercial: {
      office: 0.13,
      restaurant: 0.13,
      other: 0.13,
      healthcare: 0.13
    }
  },
  new_mexico: {
    name: 'New Mexico',
    hourly: 9.0,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  new_york: {
    name: 'New York',
    hourly: 15.0,
    commercial: {
      office: 0.22,
      restaurant: 0.22,
      other: 0.22,
      healthcare: 0.22
    }
  },
  north_carolina: {
    name: 'North Carolina',
    hourly: 7.25,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  north_dakota: {
    name: 'North Dakota',
    hourly: 7.25,
    commercial: {
      office: 0.22,
      restaurant: 0.22,
      other: 0.22,
      healthcare: 0.22
    }
  },
  ohio: {
    name: 'Ohio',
    hourly: 8.7,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  oklahoma: {
    name: 'Oklahoma',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  oregon: {
    name: 'Oregon',
    hourly: 11.25,
    commercial: {
      office: 0.17,
      restaurant: 0.17,
      other: 0.17,
      healthcare: 0.17
    }
  },
  pennsylvania: {
    name: 'Pennsylvania',
    hourly: 7.25,
    commercial: {
      office: 0.19,
      restaurant: 0.19,
      other: 0.19,
      healthcare: 0.19
    }
  },
  rhode_island: {
    name: 'Rhode Island',
    hourly: 10.5,
    commercial: {
      office: 0.19,
      restaurant: 0.19,
      other: 0.19,
      healthcare: 0.19
    }
  },
  south_carolina: {
    name: 'South Carolina',
    hourly: 7.25,
    commercial: {
      office: 0.17,
      restaurant: 0.17,
      other: 0.17,
      healthcare: 0.17
    }
  },
  south_dakota: {
    name: 'South Dakota',
    hourly: 9.3,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  tennessee: {
    name: 'Tennessee',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  texas: {
    name: 'Texas',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  utah: {
    name: 'Utah',
    hourly: 7.25,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  vermont: {
    name: 'Vermont',
    hourly: 10.96,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  virginia: {
    name: 'Virginia',
    hourly: 7.25,
    commercial: {
      office: 0.18,
      restaurant: 0.18,
      other: 0.18,
      healthcare: 0.18
    }
  },
  washington: {
    name: 'Washington',
    hourly: 13.5,
    commercial: {
      office: 0.17,
      restaurant: 0.17,
      other: 0.17,
      healthcare: 0.17
    }
  },
  west_virginia: {
    name: 'West Virginia',
    hourly: 8.75,
    commercial: {
      office: 0.14,
      restaurant: 0.14,
      other: 0.14,
      healthcare: 0.14
    }
  },
  wisconsin: {
    name: 'Wisconsin',
    hourly: 7.25,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  },
  wyoming: {
    name: 'Wyoming',
    hourly: 5.15,
    commercial: {
      office: 0.15,
      restaurant: 0.15,
      other: 0.15,
      healthcare: 0.15
    }
  }
}
