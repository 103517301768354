import React, { forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'

import Field from '../field'
import { Button, PrimaryButton, Question, Tooltip } from '../ui'
import { InputState } from '../../../../../../hooks'
import { US_STATES } from '../../data'

const HelpButton = styled(Button)`
  background-color: transparent;
  color: #0a5ad1;
  font-weight: 500;
  margin-top: 1.5rem;

  :active,
  :focus,
  :hover {
    opacity: 0.9;
  }
`

export const CommercialEmployeesStage = forwardRef(({ current, fields, id, onClick, place }, ref) => {
  let disabled = false
  const employees = current || {}

  const onNext = event => onClick(event, employees)

  // Expose getValue method to parent through ref
  useImperativeHandle(ref, () => ({
    getValue: () => employees
  }))

  const onUseStateAverage = event => {
    // Check if place exists
    if (!place) {
      return;
    }
    
    // Find the state's hourly rate
    const getPlace = placeName => Object.keys(US_STATES).find(state => US_STATES[state].name === placeName)
    const stateKey = getPlace(place);
    
    if (!stateKey || !US_STATES[stateKey] || !US_STATES[stateKey].hourly) {
      return;
    }

    const stateRate = US_STATES[stateKey].hourly;

    // Find the rate field's setValue function from the inputs array
    const rateInput = inputs.find(input => input && input.props && input.props.name === 'employee-rate');
    if (rateInput && rateInput.props.onChange) {
      // Create a synthetic event object that matches what the Field component expects
      const syntheticEvent = {
        target: {
          value: stateRate.toString(),
          type: 'number',
          name: 'employee-rate'
        }
      };
      rateInput.props.onChange(syntheticEvent);
      employees.rate = stateRate.toString();
    }
  }

  const inputs = fields.map(({ label, name, options, type }, index) => {
    const [value, setValue] = InputState(employees[name] || '')
    
    // Ensure the value is immediately saved to employees object
    if (value !== employees[name]) {
      employees[name] = value;
    }

    let isValid = (typeof value === 'number') || (value.trim().length > 0)
    if (name === 'rate' && employees.employer === 'no') {
      isValid = true
    }

    if (!isValid) {
      disabled = true
    }

    if (name === 'rate') {
      return (
        employees.employer === 'yes' && (
          <Field
            key={name}
            id={name}
            label={label}
            labelStyle={{ fontSize: '1.25rem', marginTop: '1.5rem' }}
            name={`employee-${name}`}
            onChange={setValue}
            options={options}
            style={{ marginTop: '2rem', width: '180px' }}
            type={type}
            value={value}
          />
        )
      )
    }

    return (
      <Field
        key={name}
        id={name}
        label={label}
        labelStyle={{}}
        name={name}
        onChange={setValue}
        options={options}
        style={{}}
        type={type}
        value={value}
      />
    )
  })

  return (
    <Question>
      <Tooltip>
        If you have employees, enter their hourly rate or use your state's
        average janitorial hourly rate.
        <br />
        If you are self-employed, enter your hourly rate.
        <br />
        If you select "no", the average price per square foot in your state will
        be applied.
        <br />
        Overhead costs will be automatically applied.
      </Tooltip>
      {inputs}

      {employees.employer === 'yes' && (
        <HelpButton onClick={onUseStateAverage} type={'button'}>
          Help Me Out With This
        </HelpButton>
      )}

      <PrimaryButton disabled={disabled} onClick={onNext} type={'button'}>
        Finish
      </PrimaryButton>
    </Question>
  )
})

export default CommercialEmployeesStage
