import { useState, useEffect, useCallback } from 'react'
import { usePapaParse } from 'react-papaparse';
import { LocationTypes } from '../../../../../data'
import { googlePlaceToAddress } from 'utils';

const clientTemplate = [
  'clientName',
  'address',
  'locationType',
  'locationName',
  'contactName',
  'contactPhone',
  'contactEmail',
]

const locationTemplate = [
  'locationName',
  'address',
  'locationType',
  'contactPhone',
]

const isRowEmpty = (row) => {
  // Check if all values in the row are empty strings, null, or undefined
  return row.every(value => !value || value.trim() === '')
}

// Add a function to validate required fields
const validateRequiredFields = (parsedObject, importType) => {
  const errors = [];
  
  if (importType === 'client') {
    if (!parsedObject.clientName || parsedObject.clientName.trim() === '') {
      errors.push('Client name is required');
    }
  } else if (importType === 'location') {
    if (!parsedObject.locationName || parsedObject.locationName.trim() === '') {
      errors.push('Location name is required');
    }
  }
  
  // For both client and location imports, address is required
  if (!parsedObject.address || parsedObject.address.trim() === '') {
    errors.push('Address is required');
  }
  
  // For both client and location imports, location type is required
  if (!parsedObject.locationType || parsedObject.locationType.trim() === '') {
    errors.push('Location type is required');
  }
  
  return errors.length > 0 ? errors : null;
}

// Add a function to clean phone numbers
const cleanPhoneNumber = (phone) => {
  if (!phone) return '';
  // Remove all non-numeric characters
  const cleaned = phone.replace(/\D/g, '');
  if (cleaned.length === 0) return '';
  
  // Check if the number has a reasonable length (assuming US numbers)
  if (cleaned.length < 7 || cleaned.length > 15) {
    return null; // Invalid phone number
  }
  
  return cleaned;
}

const parseClient = (row, index) => {
  let parsedObject = {
    contact: {}
  }
  clientTemplate.forEach((key, i) => {
    const value = row[i]?.trim() // Trim whitespace from values
    if (key === 'contactName' || key === 'contactEmail') {
      parsedObject.contact[key.replace('contact', '').toLowerCase()] = value
    } else if (key === 'contactPhone') {
      const cleanedPhone = cleanPhoneNumber(value);
      if (cleanedPhone === null && value) {
        // Phone number was provided but is invalid
        parsedObject.invalidPhone = true;
      }
      parsedObject.contact.phone = cleanedPhone || '';
    } else {
      parsedObject[key] = value
    }
  })

  return parsedObject
}

const parseLocation = (row, index) => {
  let parsedObject = {
    contact: {}
  }
  locationTemplate.forEach((key, i) => {
    const value = row[i]?.trim() // Trim whitespace from values
    if (key === 'contactPhone') {
      const cleanedPhone = cleanPhoneNumber(value);
      if (cleanedPhone === null && value) {
        // Phone number was provided but is invalid
        parsedObject.invalidPhone = true;
      }
      parsedObject.contact.phone = cleanedPhone || '';
    } else {
      parsedObject[key] = value;
    }
  })

  return parsedObject
}

const validateAddress = (address, google) => {
  return new Promise(async (resolve, reject) => {
    if (!address) {
      resolve({ validatedAddress: null, isValid: false, originalAddress: address });
      return;
    }

    // Add a timeout to prevent the Promise from hanging indefinitely
    const timeoutId = setTimeout(() => {
      console.warn('Address validation timed out for:', address);
      resolve({ validatedAddress: null, isValid: false, originalAddress: address, error: 'Validation timeout' });
    }, 10000); // 10-second timeout

    const serviceQuery = {
      input: address,
      fields: ['place_id']
    }
    
    const placesService = new google.maps.places.PlacesService(document.createElement('div'))
    const autocompleteService = new google.maps.places.AutocompleteService()
    
    try {
      autocompleteService.getQueryPredictions(serviceQuery, (results, status) => {
        if (status !== 'OK') {
          console.warn('Address prediction failed with status:', status);
          clearTimeout(timeoutId);
          resolve({ 
            validatedAddress: null, 
            isValid: false, 
            originalAddress: address, 
            error: `Invalid address: ${status}` 
          });
          return;
        }

        if (!results || results.length === 0) {
          clearTimeout(timeoutId);
          resolve({ 
            validatedAddress: null, 
            isValid: false, 
            originalAddress: address, 
            error: 'Address not found or unrecognized' 
          });
          return;
        }

        const placeId = results[0].place_id;

        placesService.getDetails({placeId, fields: ['ALL']}, (place, detailStatus) => {
          clearTimeout(timeoutId);
          
          if (detailStatus !== 'OK') {
            console.warn('Place details failed with status:', detailStatus);
            resolve({ 
              validatedAddress: null, 
              isValid: false, 
              originalAddress: address, 
              error: `Failed to get place details: ${detailStatus}` 
            });
            return;
          }

          try {
            const finalPlace = googlePlaceToAddress(place);
            
            // Check if the address was significantly changed during autocorrection
            const originalAddressLower = address.toLowerCase().trim();
            const correctedAddressLower = place.formatted_address.toLowerCase().trim();
            
            // Check if street number might be missing in the corrected address
            const hasSignificantChanges = 
              (originalAddressLower.match(/^\d+\s/) && !correctedAddressLower.match(/^\d+\s/)) || // Street number at beginning disappeared
              (originalAddressLower.length - correctedAddressLower.length > 5) || // Significant length difference
              (!correctedAddressLower.includes(originalAddressLower.split(',')[0].trim())); // First part of address significantly changed
            
            if (hasSignificantChanges) {
              // Address was autocorrected in a significant way - flag for review
              resolve({ 
                validatedAddress: finalPlace, 
                isValid: false, // Mark as invalid to trigger review
                originalAddress: address,
                autocorrected: true, // Flag as autocorrected
                error: 'Address was autocorrected and needs review'
              });
            } else {
              // Address validated normally
              resolve({ 
                validatedAddress: finalPlace, 
                isValid: true, 
                originalAddress: address 
              });
            }
          } catch (parseError) {
            console.error('Error parsing place data:', parseError);
            resolve({ 
              validatedAddress: null, 
              isValid: false, 
              originalAddress: address, 
              error: 'Error processing address data' 
            });
          }
        });
      });
    } catch (error) {
      clearTimeout(timeoutId);
      console.error('Address validation error:', error);
      resolve({ 
        validatedAddress: null, 
        isValid: false, 
        originalAddress: address, 
        error: 'Address validation system error' 
      });
    }
  });
}

const parseAndValidateData = async ({data, importType, google}) => {
  const locationTypeArray = LocationTypes.map(type => type.name)
  
  // Filter out empty rows
  const nonEmptyRows = data.filter(row => !isRowEmpty(row))
  
  // Skip the header row if it exists
  // We determine if the first row is a header by checking if it contains column titles
  // that match our expected fields (either for client or location)
  const template = importType === 'client' ? clientTemplate : locationTemplate
  const firstRowLooksLikeHeader = nonEmptyRows.length > 0 && 
    template.some((field, idx) => {
      const cellValue = nonEmptyRows[0][idx]?.toLowerCase?.()
      // Check if the cell contains the field name (case insensitive)
      return cellValue && (
        cellValue.includes(field.toLowerCase()) || 
        // Special case for clientName which might be "Client Name" in the header
        (field === 'clientName' && cellValue.includes('client')) ||
        // Special case for locationName which might be "Location Name" in the header
        (field === 'locationName' && cellValue.includes('location'))
      )
    })
  
  // Remove header row if detected
  const dataToProcess = firstRowLooksLikeHeader ? nonEmptyRows.slice(1) : nonEmptyRows
  
  const newData = await Promise.all(dataToProcess.map(async (row, index) => {
    let parsedObject = {}

    if (importType === 'client') {
      parsedObject = parseClient(row, index)
    } else if (importType === 'location') {
      parsedObject = parseLocation(row, index)
    }

    // Validate required fields
    const validationErrors = validateRequiredFields(parsedObject, importType);
    if (validationErrors) {
      parsedObject.error = validationErrors.join(', ');
    }

    // Check for invalid phone numbers
    if (parsedObject.invalidPhone) {
      parsedObject.error = (parsedObject.error ? parsedObject.error + ', ' : '') + 'Invalid phone number format';
      delete parsedObject.invalidPhone;
    }

    // For location imports, mark missing fields but don't immediately reject
    if (importType === 'location') {
      if (!parsedObject.locationName || parsedObject.locationName.trim() === '') {
        parsedObject.missingLocationName = true;
      }
      
      if (!parsedObject.locationType || parsedObject.locationType.trim() === '') {
        parsedObject.missingLocationType = true;
      }
    }

    if (parsedObject.locationType && !locationTypeArray.includes(parsedObject.locationType)) {
      parsedObject.invalidLocationType = true;
      parsedObject.error = (parsedObject.error ? parsedObject.error + ', ' : '') + 
                         `Invalid location type: ${parsedObject.locationType}`;
      parsedObject.locationType = null;
    }

    // Only validate address if it's provided
    if (parsedObject.address && parsedObject.address.trim() !== '') {
      const addressResult = await validateAddress(parsedObject.address, google);
      
      if (!addressResult.isValid) {
        // Store both the original address and the error
        parsedObject.originalAddress = addressResult.originalAddress;
        parsedObject.invalidAddress = true;
        
        // If address was autocorrected, save both versions but flag for review
        if (addressResult.autocorrected && addressResult.validatedAddress) {
          parsedObject.address = addressResult.validatedAddress;
          parsedObject.autocorrectedAddress = true;
          parsedObject.error = (parsedObject.error ? parsedObject.error + ', ' : '') + 
                             'Address was autocorrected and needs review';
        } else {
          parsedObject.address = null;
          parsedObject.error = (parsedObject.error ? parsedObject.error + ', ' : '') + 
                             (addressResult.error || 'Address cannot be validated');
        }
      } else {
        parsedObject.address = addressResult.validatedAddress;
      }
    } else {
      parsedObject.missingAddress = true;
    }
    
    return parsedObject;
  }))

  // Filter out null entries (rows that were skipped)
  const validData = newData.filter(item => item !== null)
  return validData
}

export const useParseAndValidateImport = ({ importType, setBusy, google }) => {
  const { readString } = usePapaParse()
  const [importData, setImportData] = useState([])
  const [fileString, setFileString] = useState('')
  const [parsedData, setParsedData] = useState([])

  const parseFile = useCallback((result) => {
    if (result) {
      setFileString(result)
    }
  }, []);

  useEffect(() => {
    if (fileString) {
      try {
        const parsedString = readString(fileString)
        setImportData(parsedString.data)
      } catch (error) {
        console.error('Error parsing CSV:', error)
        // Reset the data to allow retrying
        setImportData([])
        setParsedData([])
      }
    }
  }, [fileString, readString])

  useEffect(() => {
    const _parseData = async (data) => {
      try {
        const parsedDataRes = await parseAndValidateData({data, importType, google})
        setParsedData(parsedDataRes)
      } catch (error) {
        console.error('Error validating import data:', error)
        setParsedData([])
      }
    }
    
    if (importData.length > 0 && google) {
      _parseData(importData)
    }
  }, [importData, importType, google])

  const onUploadCSV = (file) => {
    setBusy()
    const reader = new FileReader()
    
    reader.onload = (event) => {
      parseFile(event.target.result)
    }
    
    reader.onerror = (error) => {
      console.error('Error reading file:', error)
      setParsedData([])
    }

    if (file) {
      reader.readAsText(file)
    }
  }

  return {
    parsedData,
    onUploadCSV,
  }
}
