import React from 'react'
import ReactDOMServer from 'react-dom/server'
import FileDownload from '../screens/Dashboard/Proposals/components/FileDownload'
import { applyPlaceholders } from './autofill'
import { cleanPdfContent } from './pdfUtils'
import { axiosClient } from '../store'
import Elements from '../screens/Dashboard/Proposals/Proposal2/Elements'
import { marked } from 'marked'
import { toast } from 'react-toastify'

// Error type constants for consistent error handling
export const PDF_ERROR_TYPES = {
  INVALID_PROPOSAL: 'INVALID_PROPOSAL',
  MISSING_ELEMENTS: 'MISSING_ELEMENTS',
  RENDER_ERROR: 'RENDER_ERROR',
  API_ERROR: 'API_ERROR',
  TIMEOUT: 'TIMEOUT',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  CONTENT_TOO_LARGE: 'CONTENT_TOO_LARGE',
  BROWSER_MEMORY_LIMIT: 'BROWSER_MEMORY_LIMIT',
  DOWNLOAD_ERROR: 'DOWNLOAD_ERROR'
}

// PDF size limits in bytes
const PDF_SIZE_WARNING = 5000000 // 5MB
const PDF_SIZE_CRITICAL = 10000000 // 10MB

// Timeout values in milliseconds
const TIMEOUT_DEFAULT = 120000 // 2 minutes
const TIMEOUT_LARGE_PDF = 240000 // 4 minutes for large PDFs

/**
 * Generates and downloads or previews a PDF from proposal data
 * @param {Object} proposal - The proposal object containing data
 * @param {Object} options - Configuration options
 * @param {string} [options.type] - Client type ('Client' or other)
 * @param {string} [options.clientAccessToken] - Optional access token for client views
 * @param {boolean} [options.applyAutoFill=true] - Whether to apply placeholders
 * @param {boolean} [options.cleanContent=true] - Whether to clean content
 * @param {boolean} [options.previewOnly=false] - When true, returns blob data instead of downloading
 * @param {number} [options.timeout=120000] - Request timeout in milliseconds
 * @param {Function} [options.onStart] - Optional callback called when process starts
 * @param {Function} [options.onComplete] - Optional callback called when process completes
 * @param {Function} [options.onError] - Optional callback called on error
 * @returns {Promise<Object>} - Result object with success status and optional blob data or error
 */
export const downloadProposalPdf = async (proposal, options = {}) => {
  // Call the onStart callback if provided
  if (typeof options.onStart === 'function') options.onStart()

  // Input validation
  if (!proposal) {
    const error = new Error('Proposal is undefined or null')
    if (typeof options.onError === 'function') options.onError(error)
    return { 
      success: false, 
      error,
      errorType: PDF_ERROR_TYPES.INVALID_PROPOSAL
    }
  }

  if (!proposal?.data?.elements || !Array.isArray(proposal.data.elements)) {
    const error = new Error('Invalid proposal data: missing or invalid elements')
    if (typeof options.onError === 'function') options.onError(error)
    return { 
      success: false, 
      error,
      errorType: PDF_ERROR_TYPES.MISSING_ELEMENTS
    }
  }

  try {
    const {
      type = '',
      clientAccessToken = '',
      applyAutoFill = true,
      cleanContent = true,
      previewOnly = false
    } = options

    // Determine appropriate timeout based on content size
    let requestTimeout = options.timeout || TIMEOUT_DEFAULT;

    // Process elements to apply placeholders consistently
    const processedElements = proposal?.data?.elements
      .filter(Boolean) // Filter out any nullish elements for safety
      .map(e => {
        if (e && e.type === 'rich_text' && e.data?.markdown && applyAutoFill) {
          return {
            ...e,
            data: { 
              ...e.data, 
              markdown: applyPlaceholders(e.data.markdown, proposal) 
            }
          }
        }
        return e
      })

    // 1. Generate HTML string from proposal elements
    let htmlContent = '';
    try {
      // Add error boundary to catch React rendering errors
      const ErrorBoundary = ({ children }) => {
        try {
          return children;
        } catch (error) {
          return (
            <div className="error-boundary">
              <p>Error rendering content: {error.message}</p>
            </div>
          );
        }
      };
      
      htmlContent = ReactDOMServer.renderToString(
        <div className="proposal-elements-container">
          {processedElements.map((e, i) => {
            // Skip null or undefined elements
            if (!e) return null;
            
            try {
              const master = Elements.find(m => m.type === e.type);
              
              // Skip unknown element types
              if (!master) return null;
              
              if (master.print) {
                // Ensure rich text is properly rendered as HTML
                const content = master.print(e, proposal);
                
                return (
                  <ErrorBoundary key={`element-${i}`}>
                    <div className={`element-${e.type}`}>
                      {/* Directly use the HTML content for rich_text */}
                      <div dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                  </ErrorBoundary>
                );
              } else {
                return (
                  <ErrorBoundary key={`element-${i}`}>
                    {renderElementContent(e, proposal, i)}
                  </ErrorBoundary>
                );
              }
            } catch (elementError) {
              return (
                <div key={`error-element-${i}`} className="element-error">
                  <p>Error rendering element: {elementError.message || 'Unknown error'}</p>
                </div>
              );
            }
          })}
        </div>
      );
    } catch (renderError) {
      // Catch memory-related errors specifically
      const isMemoryError = renderError.message && (
        renderError.message.includes('memory') || 
        renderError.message.includes('allocation') ||
        renderError.message.includes('heap')
      );

      const errorType = isMemoryError ? 
        PDF_ERROR_TYPES.BROWSER_MEMORY_LIMIT : 
        PDF_ERROR_TYPES.RENDER_ERROR;

      if (typeof options.onError === 'function') options.onError(renderError);
      
      return { 
        success: false, 
        error: renderError,
        errorType
      };
    }

    // 2. Apply transformations if needed
    if (applyAutoFill) {
      htmlContent = applyPlaceholders(htmlContent, proposal)
    }

    if (cleanContent) {
      htmlContent = cleanPdfContent(htmlContent)
    }
    
    // Check if content is too large
    if (htmlContent && htmlContent.length > PDF_SIZE_WARNING) {
      const sizeMB = (htmlContent.length / 1000000).toFixed(2)
      
      if (htmlContent.length > PDF_SIZE_CRITICAL) {
        const error = new Error(`Proposal content exceeds maximum size limit (${sizeMB}MB)`)
        if (typeof options.onError === 'function') options.onError(error)
        return {
          success: false,
          error,
          errorType: PDF_ERROR_TYPES.CONTENT_TOO_LARGE
        }
      }
      
      // For large but not critical content, use extended timeout if not already specified
      if (!options.timeout) {
        requestTimeout = TIMEOUT_LARGE_PDF;
      }
    }

    // 3. Determine the appropriate API endpoint
    const prefixUrl = `api/proposals`
    const url = (type === 'Client') ? `${prefixUrl}/pdfClient` : `${prefixUrl}/pdf`

    // 4. Make API request to generate PDF
    const response = await axiosClient({
      url,
      method: 'post',
      data: {
        proposal: htmlContent,
        showPages: proposal?.data?.options?.showPageNumbers,
        clientAccessToken
      },
      responseType: 'blob',
      timeout: requestTimeout
    }).catch(error => {
      // Handle axios errors uniformly
      return { error };
    });

    // Check for errors in the response
    if (response.error) {
      if (typeof options.onError === 'function') {
        options.onError(response.error)
        return { 
          success: false, 
          error: response.error,
          errorType: PDF_ERROR_TYPES.API_ERROR,
          handled: true
        }
      }
      return { 
        success: false, 
        error: response.error,
        errorType: PDF_ERROR_TYPES.API_ERROR
      }
    }

    // Validate that we got a proper PDF blob back
    if (!(response.data instanceof Blob) || response.data.size === 0) {
      const error = new Error('Invalid or empty PDF data received from server');
      if (typeof options.onError === 'function') {
        options.onError(error);
        return {
          success: false,
          error,
          errorType: PDF_ERROR_TYPES.API_ERROR,
          handled: true
        };
      }
      return {
        success: false,
        error,
        errorType: PDF_ERROR_TYPES.API_ERROR
      };
    }

    // For preview mode, just return the blob data
    if (previewOnly) {
      if (typeof options.onComplete === 'function') options.onComplete(response.data)
      return { 
        success: true,
        data: response.data
      }
    }

    // 5. Generate sanitized filename
    let merchantName = proposal?.merchant?.name || ''
    let clientName = proposal?.client?.name || ''
    
    // Remove potentially problematic characters
    merchantName = merchantName.replace(/[\/\\:*?"<>|]/g, '')
    clientName = clientName.replace(/[\/\\:*?"<>|]/g, '')
    
    const fileName = `Proposal-${merchantName}-${clientName}.pdf`.replace(/\s+/g, '_')

    // 6. Trigger download - FileDownload returns true if the browser's download process was INITIATED
    // Note: This does not mean the download completed, only that the browser started the download
    const downloadInitiated = FileDownload(response.data, fileName, 'application/pdf')
    
    // Only consider it a failure if FileDownload explicitly returns false
    // In all other cases, assume the download was successful
    if (downloadInitiated === false) {  // Explicit check for false
      const error = new Error('Failed to initiate file download');
      if (typeof options.onError === 'function') {
        options.onError(error);
        return {
          success: false,
          error,
          errorType: PDF_ERROR_TYPES.DOWNLOAD_ERROR,
          handled: true
        };
      }
      return {
        success: false,
        error,
        errorType: PDF_ERROR_TYPES.DOWNLOAD_ERROR
      };
    }
    
    // If we reach this point, consider the download successful
    // Even if downloadInitiated is undefined or null, we'll treat it as success
    
    // Call the onComplete callback if provided - this signals to UI components that they should 
    // show success indicators and stop loading states
    if (typeof options.onComplete === 'function') options.onComplete(response.data)
    
    // Return success result with data - ALWAYS ASSUME SUCCESS unless explicit failure
    return { 
      success: true,
      data: response.data
    }
  } catch (error) {
    // Handle timeout errors specifically
    if (error.code === 'ECONNABORTED') {
      if (typeof options.onError === 'function') {
        options.onError(error)
        return { 
          success: false, 
          error,
          errorType: PDF_ERROR_TYPES.TIMEOUT,
          handled: true
        }
      }
      return { 
        success: false, 
        error,
        errorType: PDF_ERROR_TYPES.TIMEOUT
      }
    }
    
    if (typeof options.onError === 'function') {
      options.onError(error)
      return { 
        success: false, 
        error,
        errorType: PDF_ERROR_TYPES.UNKNOWN_ERROR,
        handled: true
      }
    }
    return { 
      success: false, 
      error,
      errorType: PDF_ERROR_TYPES.UNKNOWN_ERROR 
    }
  }
}

/**
 * Helper function to render element content
 * @param {Object} element - The element to render
 * @param {Object} proposal - The proposal object
 * @param {number} key - Unique key for React rendering
 * @returns {React.ReactElement} - Rendered element component
 */
const renderElementContent = (element, proposal, key) => {
  // Guard against null or undefined elements
  if (!element) {
    return <div key={`empty-element-${key}`} />;
  }
  
  try {
    const master = Elements.find(e => e.type === element.type);
    
    // Handle case where element type is not found in Elements
    if (!master) {
      return <div key={`unknown-element-${key}`} />;
    }
    
    if (master.render) {
      const renderProps = { element, proposal, renderForPdf: true };
      
      // Special handling for rich_text elements to ensure markdown is properly rendered
      if (element.type === 'rich_text' && element.data?.markdown) {
        let renderedHtml;
        try {
          // Safely convert markdown to HTML with sanitization
          renderedHtml = marked(element.data.markdown, {
            sanitize: true,
            breaks: true,
            gfm: true,
            tables: true,
            smartLists: true
          });
        } catch (markdownError) {
          // Fallback to basic markdown conversion
          renderedHtml = element.data.markdown
            .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
            .replace(/\*(.*?)\*/g, '<em>$1</em>')
            .replace(/\_\_(.*?)\_\_/g, '<strong>$1</strong>')
            .replace(/\_(.*?)\_/g, '<em>$1</em>')
            .replace(/\n/g, '<br />');
        }
        
        return (
          <div key={`content-element-${key}`} className={`content-element-${element.type}`}>
            <div dangerouslySetInnerHTML={{ __html: renderedHtml }} />
          </div>
        );
      }
      
      // For non-rich-text elements, use standard rendering
      return (
        <div key={`content-element-${key}`} className={`content-element-${element.type}`}>
          {master.render(renderProps)}
        </div>
      );
    }
    
    return <div key={`empty-element-${key}`} />;
  } catch (error) {
    // Provide a more descriptive error display
    return (
      <div key={`error-element-${key}`} style={{ color: '#cc0000', padding: '10px', border: '1px solid #cc0000' }}>
        Error rendering element: {error.message || 'Unknown error'}
      </div>
    );
  }
}

/**
 * Handles consistent toast notifications and loading states for PDF downloads
 * @param {Object} proposal - The proposal object containing data
 * @param {Object} controllerState - State controller with loading state and toast management
 * @param {Function} controllerState.setIsLoading - Function to update loading state
 * @param {Function} [controllerState.onStart] - Called when download starts
 * @param {Function} [controllerState.onSuccess] - Called on successful download
 * @param {Function} [controllerState.onError] - Called on download error
 * @param {Object} [options] - Additional download options to pass to downloadProposalPdf
 * @returns {Promise<Object>} - Result object with success status
 */
export const handleProposalDownload = async (proposal, controllerState, options = {}) => {
  // Validate parameters to prevent runtime errors
  if (!proposal) {
    console.error('handleProposalDownload: Missing proposal parameter');
    toast.error('Cannot download: Invalid proposal data');
    return { success: false, error: new Error('Missing proposal parameter'), errorType: PDF_ERROR_TYPES.INVALID_PROPOSAL };
  }
  
  // Extract controller state with default empty functions for safety
  const {
    setIsLoading = () => {},
    onStart = () => {},
    onSuccess = () => {},
    onError = () => {},
  } = controllerState || {};
  
  // Set loading state
  setIsLoading(true);
  
  // Create a toast for tracking download progress - we create only one toast
  let progressToastId;
  try {
    progressToastId = toast.info("Generating PDF. Please wait...", { 
      autoClose: false, 
      closeOnClick: false,
      closeButton: false,
      hideProgressBar: false
    });
  } catch (toastError) {
    console.warn('Toast creation failed, continuing without toast notification:', toastError);
  }
  
  // Helper function to clean up UI state and dismiss toast
  const cleanup = () => {
    setIsLoading(false);
    
    if (progressToastId) {
      try {
        toast.dismiss(progressToastId);
      } catch (toastError) {
        console.warn('Error dismissing toast:', toastError);
      }
    }
  };
  
  // Track if callbacks were executed
  let callbackExecuted = false;
  
  try {
    // Call onStart callback with the toast ID
    try {
      onStart(progressToastId);
    } catch (startError) {
      console.warn('Error in onStart callback:', startError);
    }
    
    // Call the download function with callbacks
    const result = await downloadProposalPdf(proposal, {
      ...options,
      onComplete: (data) => {
        callbackExecuted = true;
        cleanup();
        
        // Show success toast
        try {
          toast.success('PDF downloaded successfully');
        } catch (toastError) {
          console.warn('Error showing success toast:', toastError);
        }
        
        // Call the provided success callback
        try {
          onSuccess(data);
        } catch (successError) {
          console.warn('Error in onSuccess callback:', successError);
        }
        
        // Call the original onComplete if provided
        if (typeof options.onComplete === 'function') {
          try {
            options.onComplete(data);
          } catch (completeError) {
            console.warn('Error in original onComplete callback:', completeError);
          }
        }
      },
      onError: (error) => {
        callbackExecuted = true;
        cleanup();
        
        // Show error toast
        try {
          toast.error('Error downloading PDF. Please try again.');
        } catch (toastError) {
          console.warn('Error showing error toast:', toastError);
        }
        
        // Call the provided error callback
        try {
          onError(error);
        } catch (errorCallbackError) {
          console.warn('Error in onError callback:', errorCallbackError);
        }
        
        // Call the original onError if provided
        if (typeof options.onError === 'function') {
          try {
            options.onError(error);
          } catch (errorCallbackError) {
            console.warn('Error in original onError callback:', errorCallbackError);
          }
        }
      }
    });
    
    // Safety check if callbacks weren't executed
    if (!callbackExecuted) {
      cleanup();
      
      // Show toast based on result status
      if (result?.success) {
        try {
          toast.success('PDF downloaded successfully');
          
          try {
            onSuccess(result.data);
          } catch (successError) {
            console.warn('Error in onSuccess callback:', successError);
          }
        } catch (toastError) {
          console.warn('Error showing success toast:', toastError);
        }
      } else if (!result?.handled) {
        try {
          toast.error('Error downloading PDF. Please try again.');
          
          try {
            onError(result?.error || new Error('Unknown error'));
          } catch (errorCallbackError) {
            console.warn('Error in onError callback:', errorCallbackError);
          }
        } catch (toastError) {
          console.warn('Error showing error toast:', toastError);
        }
      }
    }
    
    return result || { success: false, error: new Error('No result from download function') };
  } catch (error) {
    // Handle any unexpected errors
    console.error('PDF download unexpected error:', error);
    cleanup();
    
    // Show error toast
    try {
      toast.error('Unexpected error. Please try again.');
    } catch (toastError) {
      console.warn('Error showing error toast:', toastError);
    }
    
    // Call the provided error callback
    try {
      onError(error);
    } catch (errorCallbackError) {
      console.warn('Error in onError callback:', errorCallbackError);
    }
    
    return { 
      success: false, 
      error,
      errorType: PDF_ERROR_TYPES.UNKNOWN_ERROR 
    };
  }
}; 